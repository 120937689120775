import React from 'react'
import { Radio } from 'semantic-ui-react'

const CustomRadio = ({ label, name, value, checked, onChange }) => (
  <Radio
    className='custom-radio__wrapper'
    label={label}
    name={name}
    value={value}
    checked={checked}
    onChange={onChange}
  />
);

export default CustomRadio
