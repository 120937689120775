import '../../../../styles/index.scss';
import React, { useState } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../shared/TextInput/TextInput';
import mapDispatchToProps from '../../../../actions/index';
import Button from '../../../shared/Button/Button';
import CustomRadio from '../../../ui/CustomRadio/CustomRadio';
import { findTranslation } from '../../../../utils/translationsUtils';

const SINGLE_SELECT = 'single';
const MULTI_SELECT = 'multi';
// const TEXT_FIELD = 'text';

function AdminTagGroupForm({
  groupTag, edit = false, onClose, languages, onUpdate, onCreate
}) {

  const getDefaultTranslationState = () => (
    languages.map((elem) => ({
      value: groupTag ? findTranslation(groupTag.translations, elem.locale) : '',
      name: elem.name,
      locale: elem.locale,
      key: `name_${elem.locale}`,
    }))
  );

  const { t } = useTranslation();
  const [groupType, setGroupType] = useState(groupTag?.group_type || SINGLE_SELECT);
  const [errors, setErrors] = useState({});
  const [tagTranslation, setTagTranslation] = useState(getDefaultTranslationState());

  const handleTagTranslation = (value, index) => {
    const updatedTranslation = [...tagTranslation];
    updatedTranslation[index].value = value;
    setTagTranslation(updatedTranslation);
  };

  const getFilledTranslations = () => {
    const translationsHash = {};

    tagTranslation.map(elem => {
      translationsHash[elem.locale] = elem.value;
    });

    return translationsHash;
  };

  const prepareFormData = () => ({
    name: getFilledTranslations(),
    type: groupType,
  });

  const onSaveGroup = async () => {
    const groupData = prepareFormData();

    const result = (groupTag && edit)
      ? await onUpdate(groupTag.id, groupData)
      : await onCreate(groupData);

    if (result.success) {
      setErrors({});
      onClose();
    } else {
      setErrors(result.errors);
    }
  };

  return (
    <div className="admin_tagsgroups-form__wrapper">
      <div className="admin_tagsgroups-form__inputfields">
        {tagTranslation.map((elem, index) => (
          <TextInput
            title={`${t('name')} - ${elem.name}`}
            placeholder={`${t('enterNameIn')} - ${elem.name}`}
            value={elem.value}
            hasError={!!errors[elem.locale]}
            errorDescription={errors[elem.locale]}
            handleChange={(event) => handleTagTranslation(event.target.value, index)}
            key={edit ? `${groupTag.id}-${elem.locale}` : `newelem-${elem.locale}`}
          />
        ))}
      </div>
      {!edit && (
        <div>
          <label className="form-label">{t('Type')}</label>
          <div className="admin_tagsgroups-form__radiogroup">
            <CustomRadio label={t('singleSelect')} value={SINGLE_SELECT} name="group_type" onChange={(e, { value }) => setGroupType(value)} checked={groupType === SINGLE_SELECT} />
            <CustomRadio label={t('multiSelect')} value={MULTI_SELECT} name="group_type" onChange={(e, { value }) => setGroupType(value)} checked={groupType === MULTI_SELECT} />
            {/* <CustomRadio label={t('textField')} value={TEXT_FIELD} name="group_type" onChange={(e, { value }) => setGroupType(value)} checked={groupType === TEXT_FIELD} /> */}
          </div>
        </div>
      )}
      {
        <div className="admin_tagsgroups-form__buttons">
          <Button text={t('cancel')} secondary medium handler={onClose} />
          <Button text={t('save')} primary medium handler={onSaveGroup} />
        </div>
      }
    </div>
  );
}

const mapStateToProps = ({ common }) => ({
  languages: common.languages,
  defaultLanguage: common.defaultLanguage,
});

AdminTagGroupForm.propTypes = {
  groupTag: t.object,
  edit: t.bool,
  onClose: t.func.isRequired,
  languages: t.array.isRequired,
};

AdminTagGroupForm.defaultProps = {
  edit: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminTagGroupForm);
