import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NotFoundIcon } from '../../assets/images/nofound.svg';
import dashboardHoc from '../dashboard/DashboardHoc/DashboardHoc';
import Button from '../shared/Button/Button';

const NotFound = (props) => {
  const { t } = useTranslation();

  const NotFoundBody = () => (
    <div className='notfound-body'>
      <div className='notfound-content'>
        <NotFoundIcon className='notfound-icon'/>
        <div className='notfound-title'>
          Whoops, this page is missing
        </div>
        <div className='notfound-subtitle'>
          The page you have requested could not be found.
        </div>
        <Button
          large
          primary
          handler={() => props.history.replace('/dashboard')}
          text='Take me back'
          />
      </div>
    </div>
  );
  return (
    NotFoundBody()
  );
};

const PrivateNotFound = dashboardHoc(NotFound);
const PublicNotFound = NotFound;

export { PrivateNotFound, PublicNotFound };
