import React from 'react';
import '../../../styles/index.scss';

function Container({children}) {
  return (
    <div className="container__wrapper defaultscrollbar">
      <div className="container__logo" />
      <div className="container__body">
        {children}
      </div>
      <div />
    </div>
  );
}

export default Container;
