/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import t from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared/Button/Button';

function StoryCoverImage({ addFile, image }) {
  const { t } = useTranslation();

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      return;
    }

    addFile(acceptedFiles);
  }, [image]);

  const {
    getRootProps,
    getInputProps,
    open,
  } = useDropzone({ accept: 'image/*', onDrop });

  const openDialog = () => {
    open();
  };

  return (
    <>
      <div
        {...getRootProps()}
        onClick={() => {}}
        onKeyDown={() => {}}
        role="button"
        tabIndex={-9999}
      >
        <div
          className="story-create__body-cover-image"
          style={{
            backgroundImage: `url(${image})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
          }}
        >
          <div className="story-create__body-cover-image-editbutton">
            <input {...getInputProps()} />
            <Button secondary text={image ? t('edit') : 'Set'} medium handler={openDialog} />
          </div>
        </div>
      </div>
    </>
  );
}

StoryCoverImage.propTypes = {
  addFile: t.func.isRequired,
  image: t.string.isRequired,
};

export default StoryCoverImage;
