import '../../../styles/index.scss';
import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import t from 'prop-types';
import ReactMapboxGl, { ZoomControl, Marker } from 'react-mapbox-gl';
import { useTranslation } from 'react-i18next';
import { addMinutes, format } from 'date-fns';
import Button from '../../shared/Button/Button';
import TextInput from '../../shared/TextInput/TextInput';
import FullHeader from '../../ui/FullHeader/FullHeader';
import dashboardHoc from '../DashboardHoc/DashboardHoc';
import mapDispatchToProps from '../../../actions/index';
import SelectBox from '../../ui/SelectBox/SelectBox';
import CustomDatePicker from '../../shared/CustomDatePicker/CustomDatePicker';
import 'mapbox-gl/dist/mapbox-gl.css';
import { getDegreesMinutesAndSeconds, parseDMS } from '../../../utils/index';
import MediaUpload from '../../shared/MediaUpload/MediaUpload';
import DynamicTagSelect from '../../shared/DynamicTagSelect/DynamicTagSelect';
import CustomTextArea from '../../shared/CustomTextArea/CustomTextArea';
import { SAVE_THE_DATE, MAPBOX_SECRET_KEY } from '../../../shared/consts';
import AdminModal from '../AdminModal/AdminModal';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import { findTranslation } from '../../../utils/translationsUtils';

const Map = ReactMapboxGl({
  attributionControl: false,
  accessToken:
  MAPBOX_SECRET_KEY,
});

const initialLatitude = -0.2416815;
const initialLongitude = 51;
function CreateReport(props) {
  const { t } = useTranslation();
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [latitudeDegrees, setLatitudeDegrees] = useState(undefined);
  const [latitudeMinutes, setLatitudeMinutes] = useState(undefined);
  const [latitudeSeconds, setLatitudeSeconds] = useState(undefined);
  const [latitudeCardinal, setLatitudeCardinal] = useState(undefined);
  const [longitudeDegrees, setLongitudeDegrees] = useState(undefined);
  const [longitudeMinutes, setLongitudeMinutes] = useState(undefined);
  const [longitudeSeconds, setLongitudeSeconds] = useState(undefined);
  const [longitudeCardinal, setLongitudeCardinal] = useState(undefined);
  const [title, setTitle] = useState('');
  const [organization, setOrganization] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState(undefined);
  const [hour, setHour] = useState(undefined);
  const [minute, setMinute] = useState(undefined);
  const [seconds, setSeconds] = useState(undefined);
  const [dynamicTags, setDynamicTags] = useState({});
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [unsavedChangesVisibleModal, setUnsavedChangesVisibleModal] = useState(false);
  const [lastLocation, setLastLocation] = useState(undefined);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [createdReport, setCreatedReport] = useState(false);
  const [organizationsEnabled, setOrganizationsEnabled] = useState(Number(props?.options?.organizations_shown?.value) || false);

  const hourOptions = Array.from({ length: 24 }, (_, i) => i).map((elem) => (
    { key: elem, value: elem, text: elem }
  ));
  const minuteOptions = Array.from(Array(60).keys()).map((elem) => (
    { key: elem, value: elem, text: elem }
  ));
  const secondsOptions = Array.from(Array(60).keys()).map((elem) => (
    { key: elem, value: elem, text: elem }
  ));
  const organizationOptions = props?.userInfo?.organizations?.map(
    (elem) => ({
      key: elem.id,
      value: elem.id,
      text: elem.name
    })
  );

  useEffect(() => {
    document.title = 'Timby - Create Report';
    props.getTagGroups();
    props.getTags();
  }, []);

  useEffect(() => {
    setOrganization(props?.userInfo?.organizations[0].id);
  }, [props?.userInfo?.organizations]);

  useEffect(() => {
    setOrganizationsEnabled(Number(props?.options?.organizations_shown?.value));
  }, [props?.options?.organizations_shown?.value]);

  const onClickMap = (map, e) => {
    setLatitude(parseFloat(e.lngLat.lat).toFixed(6).toString());
    setLongitude(parseFloat(e.lngLat.lng).toFixed(6).toString());
    const newErrors = errors;
    delete newErrors.latitude;
    delete newErrors.longitude;
    setErrors(newErrors);
    const DMSLatitude = getDegreesMinutesAndSeconds(e.lngLat.lat, 'lat');
    setLatitudeDegrees(DMSLatitude.degrees);
    setLatitudeMinutes(DMSLatitude.minutes);
    setLatitudeSeconds(DMSLatitude.seconds);
    setLatitudeCardinal(DMSLatitude.cardinal);
    const DMSLongitude = getDegreesMinutesAndSeconds(e.lngLat.lng, 'long');
    setLongitudeDegrees(DMSLongitude.degrees);
    setLongitudeMinutes(DMSLongitude.minutes);
    setLongitudeSeconds(DMSLongitude.seconds);
    setLongitudeCardinal(DMSLongitude.cardinal);
  };

  const addFile = (acceptedFiles) => {
    const savedFiles = [...files];
    const updatedFiles = savedFiles.concat(acceptedFiles.map((file) => Object.assign(file, {
      preview: URL.createObjectURL(file),
    })));
    setFiles(updatedFiles);
  };

  const removeFile = (file) => {
    const savedFiles = [...files];
    const index = savedFiles.indexOf(file);
    if (index !== -1) {
      savedFiles.splice(index, 1);
      setFiles(savedFiles);
    }
  };

  const updateTitle = (value) => {
    const newErrors = errors;
    if (value.length > 70) {
      newErrors.title = t('validations.reportTitleExceeded');
      setErrors(newErrors);
    } else {
      delete newErrors.title;
      setErrors(newErrors);
    }
    setTitle(value);
  };

  const updateOrganization = (value) => {
    setOrganization(value);
  };

  const updateDescription = (value) => {
    const newErrors = errors;
    if (value.length > 10000) {
      newErrors.description = t('validations.reportDescriptionExceeded');
      setErrors(newErrors);
    } else {
      delete newErrors.description;
      setErrors(newErrors);
    }
    setDescription(value);
  };

  const updateDate = (value) => {
    setDate(value);
    const newErrors = errors;
    delete newErrors.date;
    setErrors(newErrors);
  };

  const addDynamicTag = (groupTagId, selectedTagChilds) => {
    dynamicTags[groupTagId] = selectedTagChilds;
    if (!selectedTagChilds) {
      delete dynamicTags[groupTagId];
    }
    setDynamicTags({ ...dynamicTags });
  };

  const createReport = async () => {
    let eventDate = undefined;
    if (date !== undefined) {
      eventDate = new Date(date);
      eventDate.setHours(hour);
      eventDate.setMinutes(minute);
      eventDate.setSeconds(seconds);
      eventDate = addMinutes(eventDate, eventDate.getTimezoneOffset()); // To UTC
    }

    const taxonomiesValues = dynamicTags;

    const reportData = {
      title,
      content: description,
      event_date: eventDate ? format(eventDate, SAVE_THE_DATE) : eventDate,
      latitude,
      longitude,
      organization,
      taxonomies: taxonomiesValues,
      files,
    };
    setCreatedReport(true);
    const result = await props.createNewReport(reportData);

    if (!result.success) {
      setErrors(result.errors);
      setCreatedReport(false);
    }
  };

  const setDegrees = (value, type = 'lat') => {
    const newErrors = errors;
    if (type === 'lat') {
      const parsedValue = parseFloat(value);
      if (parsedValue < 0 || parsedValue > 90) {
        setLatitudeDegrees('');
        setLatitude('');
        newErrors.latitude = t('validations.latitudeInvalidNumber');
        return undefined;
      }
      setLatitudeDegrees(value);
      if (value !== '' && latitudeMinutes !== undefined
        && latitudeSeconds !== undefined && latitudeCardinal !== undefined
      ) {
        if (value === '90') {
          setLatitude(parseDMS(value, 0, 0, latitudeCardinal));
          setLatitudeMinutes(0);
          setLatitudeSeconds(0);
        } else {
          setLatitude(parseFloat(
            parseDMS(value, latitudeMinutes, latitudeSeconds, latitudeCardinal),
          ).toFixed(6));
        }
        delete newErrors.latitude;
      } else {
        newErrors.latitude = t('validations.required');
        setLatitude('');
      }
    }

    if (type === 'long') {
      const parsedValue = parseFloat(value);
      if (parsedValue < 0 || parsedValue > 180) {
        setLongitudeDegrees('');
        setLongitude('');
        newErrors.longitude = t('validations.longitudeInvalidNumber');
        return undefined;
      }
      setLongitudeDegrees(value);
      if (value !== '' && longitudeMinutes !== undefined && longitudeSeconds !== undefined && longitudeCardinal !== undefined) {
        if (value === '180') {
          setLongitude(parseDMS(value, 0, 0, longitudeCardinal));
          setLongitudeMinutes(0);
          setLongitudeSeconds(0);
        } else {
          setLongitude(parseFloat(
            parseDMS(value, longitudeMinutes, longitudeSeconds, longitudeCardinal),
          ).toFixed(6));
        }
        delete newErrors.longitude;
      } else {
        newErrors.longitude = t('validations.required');
        setLongitude('');
      }
    }
    setErrors(newErrors);
    return undefined;
  };

  const setCoordMins = (value, type = 'lat') => {
    const newErrors = errors;
    if (type === 'lat') {
      if (latitudeDegrees === '90') {
        setLatitudeMinutes(0);
        setLatitude(parseDMS(latitudeDegrees, 0, 0, latitudeCardinal));
        return;
      }
      setLatitudeMinutes(value);
      if (latitudeDegrees !== '' && value !== undefined && latitudeSeconds !== undefined && latitudeCardinal !== undefined) {
        setLatitude(parseFloat(
          parseDMS(latitudeDegrees, value, latitudeSeconds, latitudeCardinal),
        ).toFixed(6));
        delete newErrors.latitude;
      } else {
        setLatitude('');
      }
    }

    if (type === 'long') {
      if (longitudeDegrees === '180') {
        setLongitudeMinutes(0);
        setLongitude(parseDMS(longitudeDegrees, 0, 0, longitudeCardinal));
        return;
      }
      setLongitudeMinutes(value);
      if (longitudeDegrees !== undefined && value !== undefined
        && longitudeSeconds !== undefined && longitudeCardinal !== undefined
      ) {
        setLongitude(parseFloat(
          parseDMS(longitudeDegrees, value, longitudeSeconds, longitudeCardinal),
        ).toFixed(6));
        delete newErrors.longitude;
      } else {
        setLongitude('');
      }
    }
    setErrors(newErrors);
  };

  const setCoordSecs = (value, type = 'lat') => {
    const newErrors = errors;
    if (type === 'lat') {
      if (latitudeDegrees === '90') {
        setLatitudeSeconds(0);
        setLatitude(parseDMS(latitudeDegrees, 0, 0, latitudeCardinal));
        return;
      }
      setLatitudeSeconds(value);
      if (latitudeDegrees !== undefined && latitudeMinutes !== undefined
        && value !== undefined && latitudeCardinal !== undefined
      ) {
        setLatitude(parseFloat(
          parseDMS(latitudeDegrees, latitudeMinutes, value, latitudeCardinal),
        ).toFixed(6));
        delete newErrors.latitude;
      } else {
        setLatitude('');
      }
    }

    if (type === 'long') {
      if (longitudeDegrees === '180') {
        setLongitudeSeconds(0);
        setLongitude(parseDMS(longitudeDegrees, 0, 0, longitudeCardinal));
        return;
      }
      setLongitudeSeconds(value);
      if (longitudeDegrees !== undefined && longitudeMinutes !== undefined
        && value !== undefined && longitudeCardinal !== undefined
      ) {
        setLongitude(parseFloat(
          parseDMS(longitudeDegrees, latitudeMinutes, value, longitudeCardinal),
        ).toFixed(6));
        delete newErrors.longitude;
      } else {
        setLongitude('');
      }
    }
    setErrors(newErrors);
  };

  const setCardinal = (value, type = 'lat') => {
    const newErrors = errors;
    if (type === 'lat') {
      setLatitudeCardinal(value);
      if (latitudeDegrees !== undefined && latitudeMinutes !== undefined
        && latitudeSeconds !== undefined && value !== undefined
      ) {
        setLatitude(parseFloat(
          parseDMS(latitudeDegrees, latitudeMinutes, latitudeSeconds, value),
        ).toFixed(6));
        delete newErrors.latitude;
      } else {
        setLatitude('');
      }
    }

    if (type === 'long') {
      setLongitudeCardinal(value);
      if (longitudeDegrees !== undefined && longitudeMinutes !== undefined
        && longitudeSeconds !== undefined && value !== undefined
      ) {
        setLongitude(parseFloat(
          parseDMS(longitudeDegrees, latitudeMinutes, longitudeSeconds, value),
        ).toFixed(6));
        delete newErrors.longitude;
      } else {
        setLongitude('');
      }
    }
    setErrors(newErrors);
  };

  const setNewLatitude = (value) => {
    const parsedValue = parseFloat(value);
    const newErrors = errors;
    if (parsedValue < -90 || parsedValue > 90) {
      newErrors.latitude = t('validations.latitudeInvalidNumber');
      setErrors(newErrors);
      setLatitude('');
      setLatitudeDegrees('');
      setLatitudeMinutes(undefined);
      setLatitudeSeconds(undefined);
      setLatitudeCardinal(undefined);
    } else {
      setLatitude(value);
      if (value === '') {
        newErrors.latitude = t('validations.required');
      } else {
        delete newErrors.latitude;
      }
      setErrors(newErrors);
      const DMSLatitude = getDegreesMinutesAndSeconds(value, 'lat');
      setLatitudeDegrees(DMSLatitude.degrees);
      setLatitudeMinutes(DMSLatitude.minutes);
      setLatitudeSeconds(DMSLatitude.seconds);
      setLatitudeCardinal(DMSLatitude.cardinal);
    }
  };

  const setNewLongitude = (value) => {
    const parsedValue = parseFloat(value);
    const newErrors = errors;
    if (parsedValue < -180 || parsedValue > 180) {
      newErrors.longitude = t('validations.longitudeInvalidNumber');
      setErrors(newErrors);
      setLongitude('');
      setLongitudeDegrees('');
      setLongitudeMinutes(undefined);
      setLongitudeSeconds(undefined);
      setLongitudeCardinal(undefined);
    } else {
      setLongitude(value);
      if (value === '') {
        newErrors.longitude = t('validations.required');
      } else {
        delete newErrors.longitude;
      }
      setErrors(newErrors);
      const DMSLongitude = getDegreesMinutesAndSeconds(value, 'long');
      setLongitudeDegrees(DMSLongitude.degrees);
      setLongitudeMinutes(DMSLongitude.minutes);
      setLongitudeSeconds(DMSLongitude.seconds);
      setLongitudeCardinal(DMSLongitude.cardinal);
    }
  };

  // Unsaved Modal
  const checkFields = () => (
    (latitude !== '' || longitude !== '' || description !== '' || title !== '' || organization !== '' ||
      date !== undefined || dynamicTags.length > 0 || files.length > 0) &&
    !createdReport
  );

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation) {
      setUnsavedChangesVisibleModal(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const closeModal = () => {
    setUnsavedChangesVisibleModal(false);
    setConfirmedNavigation(false);
  };

  const handleConfirmNavigationClick = () => {
    setUnsavedChangesVisibleModal(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      props.history.push({ pathname: lastLocation.pathname, from: props.history.location.pathname });
    }
  }, [confirmedNavigation, lastLocation]);

  const unsavedFlag = checkFields();
  return (
    <>
      <Prompt
        when={unsavedFlag}
        message={handleBlockedNavigation}
      />
      <AdminModal
        onClose={() => closeModal()}
        open={unsavedChangesVisibleModal}
        hasTrigger={false}
        header={t('reportUnsavedChangesTitle')}
        subtitle={t('reportUnsavedChangesSubtitle')}
        customModal="report-activityfeed-submit-modal report-activityfeed-submit-modal-mini"
      >
        <div className="report-activityfeed-submit-buttons">
          <Button text={t('goBack')} secondary medium handler={() => closeModal()} />
          <Button text={t('leaveAction')} primary medium handler={() => handleConfirmNavigationClick()} />
        </div>
      </AdminModal>
      <div className="create-report__wrapper grayscrollbar">
        <FullHeader title={t('createReport')}>
          <Button
            text={(
              <>
                <CloseIcon />
                {t('cancel')}
              </>
            )}
            secondary
            medium
            handler={() => { props.history.go(-1); }}
          />
        </FullHeader>
        <div className="create-report__content">
          <div className="create-report__header">
            {t('reportDetails')}
          </div>
          <TextInput
            title={t('reportTitleLabel')}
            value={title}
            handleChange={(e) => updateTitle(e.target.value)}
            hasError={!!errors.title}
            errorDescription={errors.title}
            placeholder={t('reportTitlePlaceholder')}
          />
          {!!organizationsEnabled && (
            <SelectBox
              title={t('reportOrganization')}
              placeholder={t('reportOrganization')}
              options={organizationOptions}
              value={organization}
              handleChange={(e, { value }) => updateOrganization(value)}
            />
          )}
          {props.groupTags.map((elem) => {
            let title = findTranslation(elem?.translations, props.currentLanguage);
            if(!title) title = findTranslation(elem?.translations, props.defaultLanguage);
            const filtered_tags = props.tags.filter(e => e.group === elem.id && e.organization === organization);

            return (

              <DynamicTagSelect
                key={elem.id}
                title={title}
                placeholder={t('enterReportTag', { tag: title, interpolation: { escapeValue: false } })}
                tagGroup={elem}
                tagOptions={filtered_tags}
                onChange={addDynamicTag}
                selectedValues={dynamicTags[elem.id]}
              />
            );
          })}
          <CustomTextArea
            title={t('reportDescriptionLabel')}
            value={description}
            onChange={(value) => updateDescription(value)}
            hasError={!!errors.description}
            maxRows={10}
            errorDescription={errors.description}
            placeholder={t('reportDescriptionPlaceholder')}
          />
          <div className="create-report__time">
            <CustomDatePicker
              title={t('reportDateLabel')}
              value={date}
              hasErrors={!!errors.date}
              handleChange={(date) => updateDate(date)}
              maxDate={new Date()}
              placeholder={t('reportDatePlaceholder')}
            />
            <SelectBox
              value={hour}
              options={hourOptions}
              handleChange={(e, { value }) => setHour(value)}
              placeholder={t('reportHour')}
            />
            <SelectBox
              options={minuteOptions}
              value={minute}
              handleChange={(e, { value }) => setMinute(value)}
              placeholder={t('reportMinute')}
            />
            <SelectBox
              options={secondsOptions}
              value={seconds}
              handleChange={(e, { value }) => setSeconds(value)}
              placeholder={t('reportSecond')}
            />
          </div>
          <div className="create-report__title">
            {t('reportMapTitle')}
          </div>
          <div className="create-report__map">
            <Map
              style="mapbox://styles/mapbox/basic-v9"
              containerStyle={{ height: '376px', width: '100%' }}
              onStyleLoad={(map) => { map.resize(); }}
              onClick={(map, e) => onClickMap(map, e)}
              center={(!!longitude && !!latitude)
                ? [longitude, latitude]
                : [
                  props?.options?.longitude?.value || initialLongitude,
                  props?.options?.latitude?.value || initialLatitude,
                ]}
              zoom={[props?.options?.zoom?.value || '8']}
            >
              {latitude && longitude && <Marker className="map__marker" coordinates={[longitude, latitude]} />}
              <ZoomControl className="reports-map__zoomControl" position="top-right" />
            </Map>
            <div className="create-report__map--coordinates">
              <div className="create-report__map--coordinates-container">
                <TextInput
                  title={t('latitude')}
                  value={latitude}
                  type="number"
                  placeholder={t('latitudePlaceholder')}
                  handleChange={(event) => setNewLatitude(event.target.value)}
                  hasError={!!errors.latitude}
                  errorDescription={errors.latitude}
                />
                <TextInput
                  title={t('longitude')}
                  value={longitude}
                  placeholder={t('longitudePlaceholder')}
                  type="number"
                  handleChange={(event) => { setNewLongitude(event.target.value); }}
                  hasError={!!errors.longitude}
                  errorDescription={errors.longitude}
                />
              </div>
              <span> {t('or')} </span>
              <div className="create-report__map--coordinates-containerdms">
                <div className="create-report__map--coordinates-containerdms-subcontainer">
                  <TextInput
                    type="number"
                    placeholder="Deg"
                    value={latitudeDegrees}
                    hasError={!!errors.latitude}
                    hasTitle={false}
                    handleChange={(event) => setDegrees(event.target.value, 'lat')}
                  />
                  <SelectBox
                    hasError={!!errors.latitude}
                    placeholder="Min"
                    value={latitudeMinutes}
                    options={minuteOptions}

                    handleChange={(e, { value }) => setCoordMins(value, 'lat')}
                  />
                  <SelectBox
                    type="number"
                    placeholder="Sec"
                    hasError={!!errors.latitude}
                    value={latitudeSeconds}
                    options={secondsOptions}
                    handleChange={(e, { value }) => setCoordSecs(value, 'lat')}
                  />
                  <div className='create-report__map--coordinates-containerdms-subcontainer-direction'>
                    <SelectBox
                      options={[{ text: 'North', value: 'N', key: 'North' }, { text: 'South', value: 'S', key: 'South' }]}
                      handleChange={(e, { value }) => setCardinal(value, 'lat')}
                      placeholder={t('direction')}
                      value={latitudeCardinal}
                    />
                  </div>
                  <TextInput
                    type="number"
                    placeholder="Deg"
                    value={longitudeDegrees}
                    hasTitle={false}
                    hasError={!!errors.longitude}
                    customInputClass="create-report__map--coordinates-containerdms-subcontainer-input"
                    handleChange={(event) => setDegrees(event.target.value, 'long')}
                  />
                  <SelectBox
                    options={minuteOptions}
                    value={longitudeMinutes}
                    placeholder="Min"
                    hasError={!!errors.longitude}
                    handleChange={(e, { value }) => setCoordMins(value, 'long')}
                  />
                  <SelectBox
                    value={longitudeSeconds}
                    hasError={!!errors.longitude}
                    options={secondsOptions}
                    placeholder="Sec"
                    handleChange={(e, { value }) => setCoordSecs(value, 'long')}
                  />
                  <div className='create-report__map--coordinates-containerdms-subcontainer-direction'>
                    <SelectBox
                      options={[{ text: 'East', value: 'E', key: 'East' }, { text: 'West', value: 'W', key: 'West' }]}
                      handleChange={(e, { value }) => setCardinal(value, 'long')}
                      value={longitudeCardinal}
                      placeholder={t('direction')}
                      customInputClass="create-report__map--coordinates-containerdms-subcontainer-select"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="create-report__header create-report__header-files">
            {t('reportAddMedia')}
          </div>
          <div className="create-report__subtitle">
            {t('reportUploadMedia')}
          </div>
          <MediaUpload
            addFile={addFile}
            removeFile={removeFile}
            files={files}
            title={t('reportDragMedia')}
            subtitle={t('reportSupportMedia')}
          />
          <div className="create-report__button-container">
            <Button text={t('save')} primary medium disabled={Object.keys(errors).length > 0} handler={() => createReport()} />
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({
  common, admin, user, tags,
}) => ({
  defaultLanguage: common.defaultLanguage,
  currentLanguage: common.currentLanguage,
  tags: tags.tags,
  groupTags: tags.groupTags,
  selectedOrganization: admin.selectedOrganizationId,
  userInfo: user.userInfo,
  options: admin.options,
});

CreateReport.propTypes = {
  groupTags: t.array.isRequired,
  tags: t.array.isRequired,
  options: t.object.isRequired,
  userInfo: t.object.isRequired,
  getTags: t.func.isRequired,
  getTagGroups: t.func.isRequired,
  createNewReport: t.func.isRequired,
  selectedOrganization: t.string.isRequired,
  defaultLanguage: t.string.isRequired,
  currentLanguage: t.string.isRequired,
};

export default dashboardHoc(connect(mapStateToProps, mapDispatchToProps)(CreateReport));
