
import '../../../../styles/index.scss';
import clsx from 'clsx';
import { ReactComponent as UrgentIcon } from '../../../../assets/images/urgent_icon.svg'
import { useTranslation } from 'react-i18next';

const urgencyHigh = false;
const urgencyNormal = false;
const urgencyLow = false;

function ReportUrgencyBar(props) {
  const { t } = useTranslation();
  const urgency  = props.urgency;
  return (
    <>
    {['critical', 'moderate', 'minor'].includes(urgency) && (
      <div className={clsx(
        'report-urgency-bar-alert', {
          'report-urgency-bar-alert-critical': urgency === 'critical',
          'report-urgency-bar-alert-normal': urgency === 'moderate',
          'report-urgency-bar-alert-low': urgency === 'minor',
        }
      )}>
        <UrgentIcon className='report-urgency-bar-icon'/>
        { urgency === 'critical' && t('reportCritical') }
        { urgency === 'moderate' && t('reportVeryUrgent')}
        { urgency === 'minor' && t('reportUrgent')}
      </div>
    )}
    </>
  );
}

export default (ReportUrgencyBar);
