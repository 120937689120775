export default {
  login: 'Connexion',
  username: 'Nom d\'utilisateur',
  password: 'Mot de passe',
  email: 'Courriel',
  firstname: 'Prénom',
  lastname: 'Nom de famille',
  role: 'Rôle',
  passwordPlaceholder: 'Entrer un mot de passe',
  usernamePlaceholder: 'Saisissez un nom d\'utilisateur',
  signin: 'S\'identifier',
  forgotMyPassword: 'J\'ai oublié mon mot de passe',
  requirements: 'Conditions',
  resetPassword: 'Réinitialiser le mot de passe',
  resetPasswordSubtitle: 'Saisissez le nom d\'utilisateur associé à votre compte.',
  resetPasswordSubmit: 'Envoyez-moi un lien de réinitialisation',
  resetLinkSent: 'Lien de réinitialisation envoyé',
  resetLinkSubtitle: 'Nous avons envoyé un lien pour réinitialiser votre mot de passe à l\'adresse courriel associée à votre compte.',
  resetLinkSubmit: 'Envoyez-moi le lien à nouveau',
  createNewPassword: 'Créer un nouveau mot de passe',
  passwordReq1: 'Au moins 7 caractères',
  passwordReq2: 'Contient 1 lettre majuscule',
  passwordReq3: 'Contient 1 numéro',
  passwordReq4: 'Contient 1 caractère spécial',
  passwordReq5: 'Contient 1 lettre minuscule',
  newPassword: 'Nouveau mot de passe',
  newPasswordPlaceholder: 'Entrez un nouveau mot de passe',
  reEnterNewPassword: 'Confirmez votre nouveau mot de passe',
  reEnterNewPasswordPlaceholder: 'Confirmez le nouveau mot de passe',
  createNewPasswordSubmit: 'Changer mon mot de passe',
  twoFactorAuth: 'Entrer l\'adresse courriel',
  twoFactorAuthSubtitle: 'Nous avons envoyé un code de vérification à l\'adresse courriel associée à votre compte.',
  twoFactorAuthSubmit: 'Envoyez-moi un code de vérification',
  twoFactorCode: 'Entrez le code de vérification',
  twoFactorCodeSubtitle: 'Nous avons envoyé un code de vérification à l\'adresse courriel associée à ce compte.',
  enterCode: 'Entrez le code',
  enterCodePlaceholder: 'Entrez le code à 6 chiffres',
  twoFactorCodeSubmit: 'Confirmer',
  twoFactorCodeLink: 'Renvoyez mon code',
  twoFactorCodeCheckbox: 'Ne plus me demander 2FA',
  reportsTitle: 'Rapports',
  newReport: 'Nouveau rapport',
  admin: 'Admin',
  tags: 'Balises',
  users: 'Utilisateurs',
  exportreport: 'Exportation de rapports',
  profile: 'Profil',
  terminology: 'Terminologie',
  mysettings: 'Mes paramètres',
  edit: 'Éditer',
  organizations: 'Organisations',
  analytics: 'Statistiques',
  analyticsTitle: 'Cette semaine:',
  analyticsSubmissions: 'Soumissions',
  analyticsReportByCat: 'Rapports par catégorie',
  analyticsFromLastWeek: 'depuis la semaine dernière',
  analyticsRepSubmited: 'Rapports soumis',
  analyticsRepVerified: 'Rapports vérifiés',
  analyticsIssuesResp: 'Réponses envoyées',
  analyticsStoriesCreated: 'Histoires créées',
  analyticsGeographicPrio: 'Priorités géographiques',
  analyticsPopularKeywords: 'Balises populaires',
  analyticsKeyword: 'Balise',
  analyticsMentions: 'Mentions',
  analyticsUsertypes: 'Types d\'utilisateurs',
  analyticsTopUsers: 'Top des utilisateurs',
  analyticsUsers: 'Utilisateurs',
  analyticsActivity: 'Activité',
  contactUsers: 'Contacter les utilisateurs',
  send: 'Envoyer',
  sendToEveryone: 'Envoyer à tout le monde',
  sendSpecificUsers: 'Envoyer un message à des utilisateurs spécifiques',
  selectUsers: 'Sélectionner des utilisateurs',
  enterMessage: 'Entrez votre message ici',
  search: 'Rechercher',
  addNew: 'Ajouter',
  save: 'Sauvegarder',
  ok: 'Ok',
  or: 'Ou',
  noValues: 'Aucune valeur',
  enterFirstName: 'Entrez le prénom',
  enterLastName: 'Entrez le nom de famille',
  enterEmailPlaceholder: 'Entrez l\'adresse courriel',
  createReport: 'Créer un rapport',
  reportDetails: 'Détails du rapport',
  reportTitleLabel: 'Titre',
  reportTitlePlaceholder: 'Entrez le titre du rapport',
  reportDescriptionLabel: 'Description',
  reportDescriptionPlaceholder: 'Entrez la description du rapport',
  reportAuthorLabel: 'Sélectionnez l\'auteur',
  reportAuthorPlaceholder: 'Sélectionnez l\'auteur',
  reportHour: 'Heure',
  reportMinute: 'Minute',
  reportSecond: 'Seconde',
  reportMapLabel: 'Sélectionnez sur la carte',
  reportAddMedia: 'Ajouter des médias',
  reportUploadMedia: 'Ajouter des photos, vidéos, audio ou PDF',
  reportDragMedia: 'Glisser-déposer les fichiers multimédias ici',
  reportSupportMedia: 'Nous supportons photos, vidéos, audio ou documents PDF',
  reportBrowseMedia: 'Parcourir',
  reportUploadFiles: 'Fichiers téléchargés',
  reportPublic: 'Public',
  reportActivity: 'Activité',
  reportSeeActivity: 'Voir l\'activité',
  reportNoActivity: 'Aucune activité pour le moment',
  reportUnverified: 'Non vérifié',
  reportVerified: 'Vérifié',
  reportClosed: 'Fermé',
  reportVerify: 'Vérifier',
  reportSearch: 'Rechercher',
  reportedBy: 'Rapporté par',
  reportWhatHappening: 'Que se passe-t-il?',
  reportLocation: 'Emplacement',
  reportCoordinates: 'Coordonnées',
  copyDMS: 'Copier DMS',
  copyDMM: 'Copier DD',
  reportMediaDescription: 'Description',
  reportImageEvidence: 'Preuves image',
  reportImageDescription: "Description de l'image",
  reportSaveClose: 'Sauver et fermer',
  reportAudioEvidence: 'Preuves audio',
  reportPDFEvidence: 'Preuves PDF',
  reportVideoEvidence: 'Preuves vidéo',
  reportVideoDescription: 'Description de la vidéo',
  reportUpdates: 'Activité',
  reportActivityUploadMedia: 'Ajouter un média',
  reportActivityUploadMediaMessage: 'Souhaitez-vous ajouter ces fichiers au rapport ?',
  reportActivityAssignUser: 'Assigner à un utilisateur',
  reportActivitySetUrgency: 'Modifier l\'urgence',
  reportActivityPlaceholder: 'Ajouter un commentaire ou un média supplémentaire à ce rapport',
  updateAction: 'Mettre à jour',
  editReport: 'Modifier le rapport',
  goBack: 'Retour',
  reportCritical: 'Ce rapport est critique',
  reportVeryUrgent: 'Ce rapport est urgent',
  reportUrgent: 'Ce rapport est important',
  urgency_low: 'Important',
  urgency_medium: 'Urgent',
  urgency_high: 'Critique',
  lifecycle_new: 'Nouveau',
  lifecycle_started: 'En cours',
  lifecycle_closed: 'Fermé',
  visibility_public: 'Public',
  visibility_private: 'Privé',
  status_verified: 'Verifié',
  status_unverified: 'Non vérifié',
  reportFilter: 'Filtres',
  reportStatus: 'Statut',
  reportFolders: 'Dossiers',
  urgent: 'Urgent',
  reportDateCreated: 'Date de création',
  reportAssignment: 'Assignation',
  reportAssignee: 'Assigné',
  chooseUser: 'Choisir un utilisateur',
  reportAuthor: 'Auteur',
  reportDateCreatedPlaceholder: 'Sélectionner une date',
  reportDateLabel: "Quand l'événement s'est-il produit?",
  reportDatePlaceholder: 'Entrez la date',
  reportMapTitle: "Où l'événement s'est-il produit?",
  reportDMSCoordinate: 'Coordonnées DMS',
  reportDMMCoordinate: 'Coordonnées DMM',
  contactRecent: 'Notifications récentes',
  storyTitle: 'Création de contenu',
  storyCreate: 'Créer une histoire',
  storySearch: 'Recherche de contenu',
  published: 'Publié',
  updated: 'Mise à jour',
  publishedOn: 'Publié le',
  storyType: "Type de l'histoire",
  unpublish: 'Dépublier',
  publish: 'Publier',
  unpublished: 'Non-publié',
  readFullReport: 'Lire le rapport complet',
  coverImage: 'Image de couverture',
  storyTextContent: 'Contenu du texte',
  storyTitlePlaceholder: 'Entrez votre titre ici',
  storySubtitlePlaceholder: 'Entrez votre sous-titre ici',
  closeReport: 'Fermer le rapport',
  closeReportMessage: 'Êtes vous certain de vouloir fermer ce rapport ?',
  storyAddNew: 'Ajouter une nouvelle zone de texte',
  saveChanges: 'Sauvegarder les modifications',
  userOrganizationPlaceholder: "Entrez les organisations de l'utilisateur",
  enable2FA: 'Activer 2FA',
  cancel: 'Annuler',
  deleteWarning: 'Cela ne peut pas être annulé',
  createFolder: 'Ajouter un dossier',
  createTagGroup: 'Créer un nouveau groupe de balises',
  createTag: 'Créer un nouveau groupe de balises',
  createUser: 'Créer un nouvel utilisateur',
  createOrganization: 'Créer une nouvelle organisation',
  createLayer: 'Créer un nouveau calque',
  deleteTagGroup: 'Supprimer le groupe de balises',
  deleteTagGroupDescription: 'Voulez-vous vraiment supprimer ce groupe de balises?',
  deleteTag: 'Supprimer un élément de balise',
  deleteTagDescription: 'Voulez-vous vraiment supprimer cet élément de balise?',
  myTags: 'Mes tags',
  editTagGroup: 'Modifier le groupe de balises',
  mapParameters: 'Paramètres de la carte',
  latitude: 'Latitude',
  longitude: 'Longitude',
  zoomLevel: 'Le niveau de zoom',
  layers: 'Couches',
  vector: 'Vecteur',
  raster: 'Raster',
  layerName: 'Nom de la couche',
  layerShapeFiles: 'Nous supportons les fichiers de type shapefiles, KML et GeoJSON',
  dragdropLayers: 'Glisser et déposez les fichiers ici',
  color: 'Couleur',
  translations: 'Traductions',
  name: 'Nom',
  defaultLanguage: 'Langage par défaut',
  selectReportToExport: "Sélectionner les rapports à l'exportation",
  exportAll: 'Exporter tout',
  export: 'Exporter',
  enterNameIn: 'Entrez le nom en',
  enterLayerNameIn: 'Entrez le nom de la couche en',
  enterLayerName: 'Entrez le nom de la couche',
  rasterHostedUrl: 'URL hébergée raster',
  enterRasterHostedUrl: "Entrez l'URL hébergée raster",
  enterColor: 'Entrez la couleur',
  enterUserRole: "Entrez le rôle de l'utilisateur",
  deleteSuccess: 'Oui, supprimer',
  subject: 'Sujet',
  enterSubject: 'Entrez le sujet',
  recipients: 'Destinataires',
  deleteUser: "Supprimer l'utilisateur",
  deleteUserDescription: 'Voulez-vous vraiment supprimer cet utilisateur?',
  deleteOrganization: "Supprimer l'organisation",
  deleteOrganizationDescription: 'Voulez-vous vraiment supprimer cette organisation?',
  singleSelect: 'Liste déroulante',
  multiSelect: 'Sélection multiple',
  textField: 'Champ de texte',
  searchInTheMap: 'Rechercher pendant que je déplace la carte',
  select: 'Sélectionner',
  enterReportTag: 'Entrez le {{tag}} du rapport',
  enterZoom: 'Sélectionnez le zoom',
  basemaps: 'Cartes de base',
  map: 'Carte',
  satelitte: 'Satellite',
  deleteReport: 'Supprimer le rapport',
  deleteReportDescription: 'Voulez-vous vraiment supprimer ce rapport?',
  closeAction: 'Fermer',
  reportLifecycle: 'État',
  languages: 'Langages',
  additionalSettings: 'Paramètres additionnels',
  deleteLayer: 'Supprimer la couche',
  deleteLayerDescription: 'Voulez-vous vraiment supprimer cette couche ?',
  apply: 'Appliquer',
  reset: 'Réinitialiser',
  unverify: 'Dé-vérifier',
  enabledLanguages: 'Langues activées',
  selectLanguages: 'Sélectionnez les langues',
  updateTerminology: 'Actualiser la terminologie',
  currentString: 'Chaîne actuelle',
  resetToDefault: 'Réinitialiser les valeurs par défaut',
  dragCsv: 'Glisser et déposez le fichier CSV de langue ici',
  supportCsv: 'Nous ne supportons que le format CSV',
  anonymousSignIn: 'Connexions anonymes sur mobile',
  enableMobileMaps: 'Cartes sur mobile',
  lessonPath: 'URL des leçons',
  emergencyAlerts: "Alertes d'urgence",
  emergencyContactUser: "Utilisateur de contact d'urgence",
  emergencyContactNumber: "Numéro de contact en cas d'urgence",
  contactNumber: 'Numéro de contact',
  enterCustomLessonPath: 'Entrez une url personnalisée pour les leçons',
  urgency: 'Urgence',
  verificationStatus: 'Vérifié',
  visibility: 'Visibilité',
  analyticsPage: "Page de statistiques",
  broadcastsPage: 'Page des notifications',
  storiesPage: 'Page des histoires',
  dashboardConfiguration: 'Tableau de bord',
  appConfiguration: 'Application mobile',
  publicSite: 'Site public',
  mapOnPublicSite: 'Carte sur le site public',
  grievencePortal: 'Portail des griefs',
  localization: 'Localisation',
  timeZone: 'Fuseau horaire',
  mobileOrganizationLabel: 'Organisation pour application mobile',
  mobileOrganizationPlaceholder: 'Sélectionnez une organisation',
  sendByUsers: 'Envoyer par utilisateur',
  sendByRoles: 'Envoyer par rôle',
  private: 'Privé',
  reportState: 'État',
  date: 'Date',
  startDate: 'Date de début',
  endDate: 'Date de fin',
  organization: 'Organisation',
  seconds: 'Seconds',
  degrees: 'Degrés',
  direction: 'Direction',
  default: 'Défaut',
  custom: 'Personnalisé',
  addAdditionalEmergencyNumber: "Ajouter un numéro de contact d'urgence supplémentaire",
  stories: 'Histoires',
  messages: 'Messages',
  addTagGroup: 'Ajouter un groupe de balises',
  addNewUser: 'Ajouter un utilisateur',
  addNewOrganization: 'Ajouter une organisation',
  addNewLayer: 'Ajouter un calque',
  exportAction: 'Exportation',

  deleteStory: 'Supprimer l\'histoire',
  deleteStoryDescription: 'Êtes vous sûr de vouloir supprimer cette histoire?',
  lifecycleAction: "a définit l'état à <b>{{x}}</b>.",
  replyAction: 'a répondu :',
  verifyAction: 'a vérifié ce rapport.',
  unverifyAction: 'Ce rapport n\'a pas été vérifié.',
  visibilityAction: 'Définissez la visibilité sur <b>{{x}}</b>.',
  taxonomyAction: 'a mis à jour des balises pour ce rapport.',
  assigneeAction: 'a assigné le rapport à <b>{{x}}</b>.',
  urgencyAction: 'a marqué le rapport comme <b>{{x}}</b>.',
  contentAction: 'a édité le rapport.',
  positionAction: 'L\'emplacement de ce rapport a été mis à jour.',
  titleAction: 'Mise à jour du titre de ce rapport.',
  mediaImagesLabel: 'a ajouté {{count}} photo',
  mediaImagesLabel_plural: 'a ajouté {{count}} photos',
  mediaVideosLabel: 'a ajouté {{count}} vidéo',
  mediaVideosLabel_plural: 'a ajouté {{count}} vidéos',
  mediaPdfsLabel: 'a ajouté {{count}} pdf',
  mediaPdfsLabel_plural: 'a ajouté {{count}} documents pdf',
  mediaAudiosLabel: 'a ajouté {{count}} enregistrement audio',
  mediaAudiosLabel_plural: 'a ajouté {{count}} enregistrements audio',
  reportAddFolderTitle: 'Ajouter le rapport à des dossiers',
  reportAddFolderLabel: 'Sélectionner les dossiers',
  see: 'Voir',
  selectRole: 'Sélectionnez un rôle',
  enterPromptText: 'Entrez l\'invite pour la zone de texte',
  contactAuthorText: 'Envoyé par {{author}} le {{date}}',
  timbyBranding: 'Marque Timby',
  publicSiteLanguage: 'Langue du site public',
  publicSiteLanguagePlaceholder: 'Choisissez une langue',
  publicSiteTagline: 'Slogan',
  publicSiteTaglinePlaceholder: 'Entrez le slogan sur votre site',
  publicSitePrimaryColor: 'Couleur primaire',
  publicSiteAboutUs: 'À propos de nous Description de la page',
  publicSiteAboutUsPlaceholder: 'Ajoutez une description de votre organisation qui apparaîtra sur votre site Web',
  publicSiteOrganizationContactName: 'Nom du contact de l\'organisation',
  publicSiteOrganizationContactNamePlaceholder: 'Entrez le nom de l\'organisation qui apparaîtra sur votre site',
  publicSiteOrganizationContactEmail: 'Organisation Contact Email',
  publicSiteOrganizationContactEmailPlaceholder: 'Entrez un email de contact à afficher sur votre site public',
  publicSiteOrganizationContactPhone: 'Numéro de téléphone du contact de l\'organisation',
  publicSiteOrganizationContactPhonePlaceholder: 'Entrez le numéro de téléphone qui sera affiché sur votre site public',
  publicSiteAddCoverImage: 'Ajouter une image de couverture',
  publicSiteAddAboutUsImage: 'Ajouter une image à propos de nous',
  latitudePlaceholder: 'Latitude en degrés décimaux (par exemple 6.298770)',
  longitudePlaceholder: 'Longitude en degrés décimaux (par exemple -10,794428)',
  reportUnsavedChangesTitle: 'Fermer sans enregistrer ?',
  reportUnsavedChangesSubtitle: 'Vous avez des changements non enregistrés. Voulez-vous vraiment quitter cette page sans enregistrer ?',
  leaveAction: 'Quitter',
  seeAllResults: 'Voir tous les résultats',
  searchResults: '{{total}} Résultat trouvé pour <strong>&quot;{{search}}&quot;</strong>',
  searchResults_plural: '{{total}} Résultats trouvés pour <strong>&quot;{{search}}&quot;</strong>',

  notifications: {
    CopyDMS: 'DMS copié dans le presse-papiers',
    CopyDD: 'DD copié dans le presse-papiers',
    usernameAlphabeticRequired: 'Le nom d\'utilisateur doit être alphanumérique',
    login: 'Connexion réussie',
    logout: 'Utilisateur déconnecté',
    resendToken: 'Jeton renvoyé',
    invalidLogin: 'Identifiant invalide',
    error: 'Quelque chose s\'est mal passé !',
    passwordRecoverySuccess: 'E-mail de récupération de mot de passe envoyé',
    passwordNotEqual: 'Les mots de passe ne sont pas égaux',
    passwordChangeSuccess: 'Le mot de passe a été changé avec succès',
    passwordRequirements: 'Le mot de passe n\'a pas les exigences minimales requises',
    fieldValidations: 'Vérifier les validations des champs',
    emptyFields: 'Les champs surlignés en rouge doivent être remplis',
    userUpdateSuccess: 'Utilisateur mis à jour avec succès',
    userDeletedSuccess: 'Utilisateur supprimé avec succès',
    userCreatedSuccess: 'Utilisateur créé avec succès',
    organisationDelete: 'Organisation supprimée avec succès',
    folderCreated: 'Dossier créé',
    reportAddedFolder: 'Rapport ajouté au dossier',
    messageSent: 'Message envoyé',
    reportCreated: 'Rapport créé',
    reportUpdated: 'Rapport mis à jour',
    reportDeleted: 'Rapport supprimé',
    storyCreated: 'Histoire créée',
    storyUpdated: 'Histoire mise à jour',
    storyDeleted: 'Histoire supprimée',
    tagGroupCreatedSuccess: 'Groupe créé avec succès',
    tagGroupUpdatedSuccess: 'Groupe mis à jour avec succès',
    tagGroupDeletedSuccess: 'Groupe supprimé avec succès',
    tagOptionCreatedSuccess: 'Option de balise créée avec succès',
    tagOptionUpdatedSuccess: 'Option de balise mise à jour avec succès',
    tagOptionDeletedSuccess: 'Option de balise supprimée avec succès',
    sessionExpired: 'La session a expiré',
  },

  dateTrans: {
    seconds: 'il y a {{count}} seconde',
    seconds_plural: 'il y a {{count}} secondes',
    minutes: 'il y a {{count}} minute',
    minutes_plural: 'il y a {{count}} minutes',
    hours: 'il y a {{count}} heure',
    hours_plural: 'il y a {{count}} heures',
    days: 'il y a {{count}} jour',
    days_plural: 'il y a {{count}} jours',
    months: 'il y a {{count}} mois',
    months_plural: 'il y a {{count}} mois',
    years: 'il y a {{count}} an',
    years_plural: 'il y a {{count}} ans',
  },

  validations: {
    required: 'Champs requis',
    firstNameExceeded: 'Le prénom ne doit pas dépasser 40 caractères',
    lastNameExceeded: 'Le nom de famille ne doit pas dépasser 40 caractèress',
    invalidEmail: 'Le courriel est invalide',
    usernameExceeded: 'Le nom d\'utilisateur ne doit pas dépasser 50 caractères <br/>',
    usernameAlphaNumber: 'Le nom d\'utilisateur ne doit contenir que des caractères alphanumériques <br/>',
    organizationNameExceeded: 'L\'organisation ne doit pas dépasser 60 caractères',
    layerNameExceeded: 'Le nom du calque ne doit pas dépasser 20 caractères',
    rasterUrlHttps: 'L\'URL raster doit utiliser le protocole https <br/>',
    rasterUrlInvalid: 'L\'URL raster est une URL invalide <br />',
    rasterUrlUnable: 'Impossible de récupérer les informations de l\'URL fournie',
    messageSubjectExceeded: 'Le sujet doit avoir 30 caractères ou moins',
    messageTextExceeded: 'Le message doit contenir 500 caractères ou moins',
    reportTitleExceeded: 'Le titre ne doit pas dépasser 70 caractères',
    reportDescriptionExceeded: 'La description ne doit pas dépasser 10000 caractères',
    reportCommentExceeded: 'Le commentaire ne doit pas dépasser 2000 caractères',
    storyTitle: 'Le titre ne doit pas dépasser 100 caractères',
    storySubtitle: 'Le sous-titre ne doit pas dépasser 100 caractères',
    storyTextField: 'Le champ de texte ne doit pas dépasser 2000 caractères',
    taxonomyGroupNameExceeded: 'Le nom de la taxonomie ne doit pas dépasser 20 caractères',
    taxonomyChildNameExceeded: 'Le nom de la balise ne doit pas dépasser {{elementMaxLength}} caractères',
    maxCodeLength: 'Le code devrait contenir 6 chiffres',
    invalidCode: 'Code invalide',
    invalidToken: 'Jeton invalide',
    latitudeInvalidNumber: 'La latitude doit être comprise entre -90 et 90',
    longitudeInvalidNumber: 'La longitude doit être comprise entre -180 et 180',
  },
  api: {
    NO_CONTACTS: 'Contact d\'urgence invalide',
    NO_OPTIONS: 'Aucune option',
    NO_ADMINS: 'Aucun administrateur',
    REPORT_CLOSED: 'Ce rapport ne peut pas être modifié',
    NO_LANGUAGE: 'Aucun langage',
    INSUFFICIENT_PRIVILEGES: 'Permissions insuffisantes',
    BAD_REQUEST: 'Requête invalide',
    RESOURCE_NOT_FOUND: 'Ressource introuvable',
    UNAUTHENTICATED: 'Échec de l\'authentification',
    VALIDATION_ERROR: 'Une erreur de validation s\'est produite',
    INSUFFICIENT_PARAMETERS: 'Paramètres insuffisants',
    INVALID_PARAMETERS: 'Paramètres invalides',
    INACTIVE_FEATURE: 'Cette fonction est inactive',
    SERVER_ERROR: 'Une erreur interne s\'est produite lors du traitement de votre demande',
    ERROR: 'Une erreur est survenue',
  }
};
