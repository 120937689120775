export const ALERT_SUCCESS = 'ALERT_SUCCESS';
export const ALERT_ERROR = 'ALERT_ERROR';
export const ALERT_INFORMATION = 'ALERT_INFORMATION';
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
export const MAPBOX_SECRET_KEY = process.env.REACT_APP_MAPBOX_TOKEN;
export const MESSAGE_EVERYONE = 'EVERYONE';
export const MESSAGE_SELECT_USERS = 'SELECT_USERS';
export const MESSAGE_SELECT_ROLES = 'SELECT_ROLES';
//export const UTC_PARSER = 'yyyy-LL-ddTHH:mm:ss.SSSZ';
//export const REPORT_EVENT_DATE_PARSER = 'dd-LL-yyyy HH:mm:ss';
export const REPORT_EVENT_DATE_PARSER = "yyyy-MM-dd H:mm '(UTC)'";
export const SAVE_THE_DATE = "yyyy-LL-dd HH:mm:ss";
export const DOMAINS_REGEX = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;
