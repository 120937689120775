import {
  CREATE_REPORT, DELETE_REPORT, SET_VIEW_REPORT,
  GET_REPORTS, SELECT_REPORT, UNSELECT_REPORT,
  UPDATE_ACTIVITY_REPORT, ADD_ACTIVITY_REPORT,
  GET_COMMENTS,
} from '../types';

import { endpoints } from './endpoints';
import i18n from '../translations/i18n';
import {
  addNewAlert, closeLoader, getToken, openLoader,
} from './CommonActions';
import { ALERT_ERROR, ALERT_SUCCESS } from '../shared/consts';
import { history } from '..';
import { getRandomColor, hexToRgb } from '../utils';

export const getReports = () => async (dispatch, getState) => {
  const { reports } = getState();
  const token = await dispatch(getToken());

  try {
    dispatch(openLoader());

    let options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      }
    };

    if(Object.keys(reports.filters).length) {
      options.method = 'POST';
      options.body = JSON.stringify(reports.filters);
    }

    const response = await fetch(endpoints.getReports, options);

    const { error, data, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      dispatch({
        type: GET_REPORTS,
        payload: {
          reports: data
        },
      });
    }
  } catch (e) {
    console.warn(e);
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const getSelectedReport = (reportId) => async (dispatch) => {
  const token = await dispatch(getToken());

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    dispatch(openLoader());
    const response = await fetch(`${endpoints.getReport(reportId)}`, options);
    const { error, data, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
      history.push({ pathname: '/dashboard', from: history.location.pathname });
    } else {
      const selectedReport = data;
      if (!selectedReport) return history.push({ pathname: '/dashboard', from: history.location.pathname });

      dispatch({ type: SELECT_REPORT, payload: { selectedReport } });
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    console.warn(e);
    history.push({ pathname: '/dashboard', from: history.location.pathname });
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

// @fixme: only way for this to work would be to persist it in a cookie
export const setViewReport = (reportId) => async (dispatch, getState) => {
  const { reports } = getState();
  const { reports: currentReports } = reports;

  const updatedReports = currentReports.map((report) => {
    if (report.id === reportId) {
      return { ...report, new: false };
    }
    return { ...report };
  });

  dispatch({ type: SET_VIEW_REPORT, payload: { updatedReports } });

};

export const getReportComments = (reportId) => async (dispatch) => {
  const token = await dispatch(getToken());

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    dispatch(openLoader());
    const response = await fetch(`${endpoints.getReportComments(reportId)}`, options);
    const { error, data, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      dispatch({
        type: GET_COMMENTS,
        payload: { comments: data },
      });
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    console.warn(e);
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const getReportActivity = (reportId) => async (dispatch, getState) => {
  const { admin, reports } = getState();
  const token = await dispatch(getToken());

  const { users } = admin;

  if (reports.activityFeedReachedEnd) return;

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  const LIMIT = 20;

  try {
    dispatch(openLoader());
    const response = await fetch(`${endpoints.getReportActivity(reportId)}?limit=20&offset=${reports.activityFeedOffset}`, options); // this can be changed for a get single element
    const { error, data, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      const {
        total, activity,
      } = data;
      const fetchedActivity = activity || [];

      const newActivityUsersColors = reports.activityUsersColor;

      const activityWithMedia = fetchedActivity.map((elem) => {
        if (!newActivityUsersColors[elem.username]) {
          newActivityUsersColors[elem.username] = hexToRgb(getRandomColor(), 0.3);
        }

        const updatedInfo = {
          media: elem.media,
          color: newActivityUsersColors[elem.username],
        };

        if (elem.event === 'edit_assignee') {
          updatedInfo.data = users.find((user) => (user.id === elem.data))?.username;
        }

        return ({
          ...elem,
          ...updatedInfo,
        });
      });

      dispatch({
        type: UPDATE_ACTIVITY_REPORT,
        payload: {
          activityFeed: activityWithMedia,
          colors: newActivityUsersColors,
          hasReachedEnd: parseInt(reports.activityFeed.length, 10)
            + parseInt(LIMIT, 10) >= parseInt(total, 10),
        },
      });
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    console.warn(e);
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const createNewReport = (reportPayload) => async (dispatch, getState) => {
  const { admin } = getState();
  const token = await dispatch(getToken());

  const reportData = {};

  // Validations
  const errors = {};
  if (reportPayload.title === '') errors.title = i18n.t('validations.required');
  if (reportPayload.title.length > 70) errors.title = i18n.t('validations.reportTitleExceeded');

  if (reportPayload.content === '') errors.description = i18n.t('validations.required');
  if (reportPayload.content.length > 10000) errors.description = i18n.t('validations.reportDescriptionExceeded');

  if (reportPayload.latitude === '' || reportPayload.latitude === null) errors.latitude = i18n.t('validations.required');
  if (reportPayload.longitude === '' || reportPayload.longitude === null) errors.longitude = i18n.t('validations.required');
  if (reportPayload.event_date === null) errors.date = i18n.t('validations.required');

  if (Object.keys(errors).length > 0) {
    dispatch(addNewAlert(i18n.t('notifications.fieldValidations'), ALERT_ERROR));
    return { success: false, errors };
  }

  reportData.title = reportPayload.title;
  reportData.content = reportPayload.content;
  //reportData.organization = reportPayload.organization;
  reportData.created_date = new Date().toISOString().replace('T', ' ').substr(0, 19);
  reportData.event_date = reportPayload.event_date;
  reportData.latitude = reportPayload.latitude;
  reportData.longitude = reportPayload.longitude;
  reportData.source = 'web';
  if(Object.values(reportPayload.taxonomies).length) {
    reportData.taxonomies = reformatTaxonomies(reportPayload.taxonomies);
  }

  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(reportData),
  };

  const organization = (reportPayload.organization)? reportPayload.organization : admin.selectedOrganizationId;

  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.createReport(organization), options,);
    const { data, error, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      if (reportPayload.files.length) { // with medias
        let synced = await dispatch(attachReportMedia(data.id, reportPayload.files));

        if(synced.success) {
          dispatch(addNewAlert(i18n.t('notifications.reportCreated'), ALERT_SUCCESS));
          dispatch({ type: CREATE_REPORT, payload: { newReport : data } });
          dispatch(editReport(data.organization, data.id, {'status':'published'}));
        } else {
          dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
        }
      } else {
        dispatch(addNewAlert(i18n.t('notifications.reportCreated'), ALERT_SUCCESS));
        dispatch({ type: CREATE_REPORT, payload: { newReport : data } });
        dispatch(editReport(data.organization, data.id, {'status':'published'}));
      }

      history.push({ pathname: '/dashboard', from: history.location.pathname });
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    console.warn(e);
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};


export const attachReportMedia = (reportId, reportPayload) => async (dispatch) => {
  const token = await dispatch(getToken());

  var all = [];
  for (var i = 0; i < reportPayload.length; i++) {
    const formData = new FormData();
    formData.append('media', reportPayload[i]);

    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };
    var response = await fetch(endpoints.createReportMedia(reportId), options,);
    all.push(response);
  }

  Promise.all(all)
    .then((results) => {
      if(results.find(e => e.status !== 200)) {
        const message = i18n.t(['notifications.uploadFailed', 'notifications.error']);
        dispatch(addNewAlert(message, ALERT_ERROR));
      } else return { success: true }; // if all good, return true
    })
    .catch(err => { // First rejected promise
      dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
      console.warn(err);
    })
    .finally( () => {
      dispatch(closeLoader());
    });

  return { success: false };
};

const reformatTaxonomies = (taxonomies) => {
  return Object.entries(taxonomies).map(function(tax) {
    const [taxId, taxValue] = tax;
    return { [taxId]: taxValue };
  });
};

export const editReport = (orgId, reportId, reportPayload) => async (dispatch) => {
  const token = await dispatch(getToken());

  const errors = {};
  if (Object.keys(reportPayload).includes('content') && reportPayload.content === '') errors.description = i18n.t('validations.required');
  if (Object.keys(reportPayload).includes('content') && reportPayload.content.length > 10000) errors.description = i18n.t('validations.reportDescriptionExceeded');
  if (Object.keys(reportPayload).includes('title') && reportPayload.title === '') errors.title = i18n.t('validations.required');
  if (Object.keys(reportPayload).includes('title') && reportPayload.title.length > 70) errors.title = i18n.t('validations.reportTitleExceeded');

  if (Object.keys(reportPayload).includes('taxonomies')) reportPayload.taxonomies = reformatTaxonomies(reportPayload.taxonomies);

  if (Object.keys(errors).length > 0) {
    dispatch(addNewAlert(i18n.t('notifications.fieldValidations'), ALERT_ERROR));
    return { success: false, errors };
  }

  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(reportPayload),
  };

  try {
    dispatch(openLoader());
    const response = await fetch(
      endpoints.alterReport(orgId, reportId), options,
    );
    const { error, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      dispatch(addNewAlert(i18n.t('notifications.reportUpdated'), ALERT_SUCCESS));
      dispatch(getSelectedReport(reportId));
      dispatch(getReportComments(reportId));
      //dispatch(getActivityFeedCreated(reportId));
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    console.warn(e);
    return { success: false, errors: {} };
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};


export const closeReport = (orgId, reportId) => async (dispatch) => {
  const token = await dispatch(getToken());

  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    }
  };
  try {
    dispatch(openLoader());
    const response = await fetch(
      endpoints.closeReport(orgId, reportId), options,
    );
    const { error, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      dispatch(addNewAlert(i18n.t('notifications.reportClosed'), ALERT_SUCCESS));
      dispatch(getReportComments(reportId));
      //dispatch(getActivityFeedCreated(reportId));
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    console.warn(e);
    return { success: false, errors: {} };
  } finally {
    dispatch(closeLoader());
  }
};

export const unselectedReport = () => (dispatch) => {
  dispatch({ type: UNSELECT_REPORT });
};

export const addComment = (reportId, commentPayload) => async (dispatch) => {
  const token = await dispatch(getToken());
  const errors = {};

  // Validating Comment
  if (commentPayload.comment === '') errors.comment = i18n.t('validations.required');
  if (commentPayload.comment.length > 2000) errors.comment = i18n.t('validations.reportCommentExceeded');

  if (Object.keys(errors).length > 0) {
    return { success: false, errors };
  }

  const commentData = {
    'content': commentPayload.comment,
  };

  // First, let's create the comment
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentData),
  };

  try {
    dispatch(openLoader());
    const response = await fetch(
      endpoints.createComment(reportId), options,
    );
    const { data, error, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      if (commentPayload.media.length) { // with medias
        let synced = await dispatch(attachCommentMedia(data.id, commentPayload.media));

        if(synced.success) {
          const result = await dispatch(editComment(data.id, {'status':'published'}));
          if(result.success) {
            dispatch(getReportComments(reportId));
            // dispatch(getActivityFeedCreated(reportId));
          }
        }
      } else { // no media
        const result = await dispatch(editComment(data.id, {'status':'published'}));
        if(result.success) {
          dispatch(getReportComments(reportId));
          // dispatch(getActivityFeedCreated(reportId));
        }
      }
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    console.warn(e);
    return { success: false, errors: {} };
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const editComment = (commentId, commentPayload) => async (dispatch) => {
  const token = await dispatch(getToken());

  const options = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentPayload),
  };
  try {
    dispatch(openLoader());
    const response = await fetch(
      endpoints.editComment(commentId), options,
    );
    const { error, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      dispatch(addNewAlert(i18n.t('notifications.reportUpdated'), ALERT_SUCCESS));
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    console.warn(e);
    return { success: false, errors: {} };
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const attachCommentMedia = (commentId, commentPayload) => async (dispatch) => {
  const token = await dispatch(getToken());

  var all = [];
  for (var i = 0; i < commentPayload.length; i++) {
    const formData = new FormData();
    formData.append('media', commentPayload[i]);

    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };
    var response = await fetch(endpoints.createCommentMedia(commentId), options,);
    all.push(response);
  }

  Promise.all(all)
    .then((results) => {
      if(results.find(e => e.status !== 200)) {
        const message = i18n.t(['notifications.uploadFailed', 'notifications.error']);
        dispatch(addNewAlert(message, ALERT_ERROR));
      } else return { success: true }; // if all good, return true
    })
    .catch(err => { // First rejected promise
      dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
      console.warn(err);
    })
    .finally( () => {
      dispatch(closeLoader());
    });

  return { success: false };
};


export const getActivityFeedCreated = (reportId) => async (dispatch, getState) => {
  const { admin, reports } = getState();
  const token = await dispatch(getToken());
  const { users } = admin;

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  const LIMIT = 30;

  try {
    dispatch(openLoader());
    const response = await fetch(`${endpoints.getReportActivity(reportId)}?limit=${LIMIT}&offset=0`, options);
    const { error, data, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      const {
        total, activity,
      } = data;
      const fetchedActivity = activity || [];
      let latestIndex = -1;
      let newActivity = fetchedActivity;
      if (reports.activityFeed.length > 0) {
        const latestActivityFeed = reports.activityFeed[0];
        if (!latestActivityFeed) return { success: true };

        latestIndex = fetchedActivity.findIndex((elem) => (
          elem.id === latestActivityFeed.id
        ));
        if (latestIndex === -1 && reports.activityFeed.length > 0) return { success: true };

        newActivity = fetchedActivity.slice(0, latestIndex);
      }

      const newActivityUsersColors = reports.activityUsersColor;

      const activityWithMedia = newActivity.map((elem) => {
        if (!newActivityUsersColors[elem.author]) {
          newActivityUsersColors[elem.author] = hexToRgb(getRandomColor(), 0.3);
        }
        const updatedInfo = {
          media: elem.media,
          color: newActivityUsersColors[elem.author],
        };

        if (elem.event === 'edit_assignee') {
          updatedInfo.data = users.find((user) => (user.id === elem.data))?.username;
        }

        return ({
          ...elem,
          ...updatedInfo,
        });
      });

      dispatch({
        type: ADD_ACTIVITY_REPORT,
        payload: {
          newActivityFeed: activityWithMedia,
          colors: newActivityUsersColors,
          hasReachedEnd:
            parseInt(activityWithMedia.length, 10)
            + parseInt(reports.activityFeed.length, 10)
            + parseInt(20, 10) >= parseInt(total, 10),
        },
      });
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    console.warn(e);
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const deleteReport = (orgId, reportId) => async (dispatch) => {
  const token = await dispatch(getToken());

  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    dispatch(openLoader());
    const response = await fetch(
      endpoints.alterReport(orgId, reportId), options,
    );
    const { error, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
      return { success: false };
    } else {
      history.push({ pathname: '/dashboard', from: history.location.pathname });
      dispatch(addNewAlert(i18n.t('notifications.reportDeleted'), ALERT_SUCCESS));
      dispatch({ type: DELETE_REPORT, payload: { reportId } });
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    console.warn(e);
    return { success: false };
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};
