import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import type from 'prop-types';
import '../../../styles/index.scss';
import { useTranslation } from 'react-i18next';
import mapDispatchToProps from '../../../actions/index';
import AdminModal from '../AdminModal/AdminModal';
import AdminTagsGroupContainer from './AdminTagGroupContainer';
import AdminTagGroupForm from './forms/AdminTagGroupForm';
import { findTranslation } from '../../../utils/translationsUtils';

const AdminTags = (props) => {
  const { t } = useTranslation();
  const { groupTags, tags } = props;
  const [ open, setOpen ] = useState(false);
  const [ selectedGroupTag, setSelectedGroupTag ] = useState(null);

  useEffect(() => {
    document.title = 'Timby - Tags';
    props.getTags();
    props.getTagGroups();
  }, []);

  const onCloseModal = () => {
    setSelectedGroupTag(null);
    setOpen(false);
  };

  const onEditGroupTag = (groupTag) => {
    setSelectedGroupTag(groupTag);
    setOpen(true);
  };

  const handleUpdateGroup = async (id, data) => {
    return await props.editTagGroup(id, data);
  };

  const handleCreateGroup = async (data) => {
    return await props.createTagGroup(data);
  };

  const GROUP_TAG_TYPES = {
    single: 'singleSelect',
    multi: 'multiSelect',
    text: 'textField',
  };

  return (
    <div className="admin-container__content whitepagescrollbar">
      {/* Title */}
      <div className="max-body-width">

        <div className="admin-container-header">
          <h1 className="admin-container-header-title">
            {t('myTags')}
          </h1>
          {/* Tag Group Form */}
          <div className="admin-container-header-buttons">
            <AdminModal
              actionName={t('addTagGroup')}
              onClose={() => onCloseModal()}
              open={open}
              onOpen={() => setOpen(true)}
              header={selectedGroupTag ? t('editTagGroup') : t('createTagGroup')}
            >
              <AdminTagGroupForm
                edit={!!selectedGroupTag}
                onClose={onCloseModal}
                groupTag={selectedGroupTag}
                onUpdate={handleUpdateGroup}
                onCreate={handleCreateGroup}
              />
            </AdminModal>
          </div>
        </div>
        {/* Tags Groups */}
        {groupTags.map((elem) => {

          let groupTagName = findTranslation(elem?.translations, props.currentLanguage);
          if(!groupTagName) groupTagName = findTranslation(elem?.translations, props.defaultLanguage);
          const filteredTags = tags.filter(e => e.group === elem.id && e.organization === props.selectedOrganizationId);

          return (
            <AdminTagsGroupContainer
              key={elem.id}
              onEdit={() => onEditGroupTag( elem )}
              id={elem.id}
              formTitle={groupTagName}
              type={elem.type}
              title={`${groupTagName} - ${t(GROUP_TAG_TYPES[elem.type])}`}
              childTags={filteredTags || []}
            />
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = ({ common, tags, admin }) => ({
  groupTags: tags.groupTags,
  tags: tags.tags,
  selectedOrganizationId: admin.selectedOrganizationId,
  defaultLanguage: common.defaultLanguage,
  currentLanguage: common.currentLanguage,
});

AdminTags.propTypes = {
  selectedOrganizationId: type.string.isRequired,
  defaultLanguage: type.string.isRequired,
  currentLanguage: type.string.isRequired,
  groupTags: type.array.isRequired,
  tags: type.array.isRequired,
  getTagGroups: type.func.isRequired,
  getTags: type.func.isRequired,
  editTagGroup: type.func.isRequired,
  createTagGroup: type.func.isRequired,
};

AdminTags.displayName = 'AdminTagsContainer';

export default connect(mapStateToProps, mapDispatchToProps)(AdminTags);
