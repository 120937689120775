import '../../../styles/index.scss';
import { ReactComponent as SVG } from '../../../assets/images/zip_upload.svg';

function ReportZip({message}) {

  return (
    <div className="report-zip__wrapper">
      <SVG className="report-zip__icon"/>
      {message}
    </div>
  );
}

export default ReportZip;
