import { connect } from 'react-redux';
import mapDispatchToProps from '../../../actions/index';
import FormContainer from '../FormContainer/FormContainer';
import Button from '../../shared/Button/Button';
import loginHoc from '../LoginHoc/LoginHoc';
import { useTranslation } from 'react-i18next';

const ResetPassword = (props) => {
  const { t } = useTranslation();

  return (
    <FormContainer
      title={t("resetLinkSent")}
      subtitle={`${t("resetLinkSubtitle")}`}
      submit={
        <Button
          text={t("resetLinkSubmit")} primary large
          handler={() => props.requestPasswordRecovery(props.username)}
        />
      }
    />
  );
};

const mapStateToProps = ({ user }) => {
  return {
    username: user.tempUser
  };
};
export default loginHoc(connect(mapStateToProps, mapDispatchToProps)(ResetPassword))
