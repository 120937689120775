import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, isLoggedIn, is2FA, ...rest }) => {
  const routeComponent = (props) =>
    isLoggedIn && is2FA ? (
      <Component {...props}/>
    ) : (
      <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    );

  return <Route {...rest} render={routeComponent} />;
};

export default PrivateRoute;
