// import i18n from 'i18next';

/*
export const getLanguage = () => (i18n.language);

export const addBackendTranslations = (data, language = i18n.language) => {
  i18n.addResources(language, 'backend', data);
};

export const getBackendTranslation = (value, defaultValue, language = i18n.language) => (
  i18n.getResource(language, 'backend', value) || defaultValue
);
*/
// Consider moving elsewhere. This file looks a bit obsolete.

export const findTranslation = (arr, locale) => {
  if(!arr) return;
  const found = arr.find(elem => elem?.locale === locale);
  return found?.data;
};
