import '../../../styles/index.scss';

function FullImage({
  image,
  quote,
  author,
}) {
  return (
    <div className="fullImage__wrapper" style={{backgroundImage: `url(${image})`}}>
      <div className="fullImage__quote">
        <div className="fullImage__message">
          {quote}
        </div>
        <div className="fullImage__author">
          {author}
        </div>
      </div>
    </div>
  );
}

export default FullImage;
