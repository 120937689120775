import '../../../../styles/index.scss';
import { Dropdown } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { getRandomTint } from '../../../../utils'


import { ReactComponent as CloseIcon } from '../../../../assets/images/close.svg'
import CustomCheckbox from '../../../ui/CustomCheckbox/CustomCheckbox';
import { useTranslation } from 'react-i18next';

function MessageCustomSelect(props) {
  const { t } = useTranslation();
  const [selectOpen, setSelectOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(props.options);

  useEffect(() => {
    setFilteredOptions(props.options);
  }, [props.options]);

  const addValueToSelectedOptions = (e, {value}) => {
    const newOption = {...value, color: getRandomTint()};
    const updatedSelectedOptions = [...props.selectedOptions, newOption];
    props.changeSelectedOptions(updatedSelectedOptions);
  };

  const findValueOnSelectedOptions = (value) => {
    const optionFound = props.selectedOptions.find((elem) => {
      return (value === elem.id);
    });
    return !!optionFound;
  };

  const removeValueFromSelectedOptionsEvent = (event, {value}) => {
    removeValueFromSelectedOptions(value.id);
  };

  const removeValueFromSelectedOptions = (value) => {
    const newSelectedOptions = props.selectedOptions.filter((elem) => {
      if (value === elem.id)
        return false;
      return true;
    });

    props.changeSelectedOptions(newSelectedOptions);
  };

  const handleSearchOptions = (event) => {
    const filteredOptions = props.options.filter((elem) => {
      const value = event.target.value;
      return (elem.text.toLowerCase().startsWith(value.toLowerCase()));
    });
    setFilteredOptions(filteredOptions);
  };

  const onCloseSelect = () => {
    setSelectOpen(false);
    setFilteredOptions(props.options);
  };

  return (
    <>
      <div className={clsx('message-form-userselect',{
        "message-form-userselect-searchinput" :selectOpen
      })}>
        <Dropdown
          className="select-box__wrapper"
          fluid
          selection
          search={true}
          onSearchChange={handleSearchOptions}
          placeholder={selectOpen ? t('search') : props?.placeholder || t('selectUsers')}
          selectOnNavigation={false}
          selectOnBlur={false}
          item
          onOpen={() => setSelectOpen(true)}
          onClose={onCloseSelect}
          icon={selectOpen ? null : 'chevron down'}
        >
          <Dropdown.Menu>
            {!filteredOptions.length && (
              <Dropdown.Item disabled>
                {t('noResults')}
              </Dropdown.Item>
            )}
            {filteredOptions.map((elem) => (
              <Dropdown.Item key={`messageuser-select-${elem.id}`} value={elem}
                onClick={findValueOnSelectedOptions(elem.id)
                  ? removeValueFromSelectedOptionsEvent
                  : addValueToSelectedOptions
                }>
                <div className='message-form-userselect-option'>
                  <CustomCheckbox label={elem.text} checked={findValueOnSelectedOptions(elem.id)}/>
                </div>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {/* Results */}
      {props.selectedOptions.length > 0 && (
        <div className='message-form-userselect-results'>
          {(props.selectedOptions.map((elem) => {
            return (
              <div
                className='message-form-userselect-results-elem'
                style={{backgroundColor: elem.color }}
                key={`messageuser-customresult-${elem.id}`}
              >
                <span>{elem.text}</span>
                <CloseIcon
                  className='message-form-userselect-results-elem-closeicon'
                  onClick={() => removeValueFromSelectedOptions(elem.id)}
                />
              </div>
            );
          }))}
        </div>
      )}
    </>
  );
}

export default MessageCustomSelect;
