import SelectBox from '../../ui/SelectBox/SelectBox';
import React, { useEffect, useState } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import mapDispatchToProps from '../../../actions';

function OrganizationsMenu (props) {

  const [selectedOrg, setSelectedOrg] = useState(props.selectedOrganizationId);
  const organizationOptions = props?.userInfo?.organizations?.map(
    (elem) => ({
      key: elem.id,
      value: elem.id,
      text: elem.name
    })
  );

  useEffect(() => {
    const onChangeOrganization = async () => {
      await props.updateSelectedOrganization(selectedOrg);
    };
    onChangeOrganization();
  }, [selectedOrg]);

  return (
    <SelectBox
      medium
      options={organizationOptions}
      value={selectedOrg}
      handleChange={(e, { value }) => setSelectedOrg(value)}
    />
  );
}

const mapStateToProps = ({ admin, user }) => ({
  selectedOrganizationId: admin.selectedOrganizationId,
  userInfo: user.userInfo,
});

OrganizationsMenu.propTypes = {
  userInfo: t.object.isRequired,
  selectedOrganizationId: t.string,
  updateSelectedOrganization: t.func.isRequired,
  unselectUser: t.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationsMenu);
