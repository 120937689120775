import React, { useEffect, useRef, useState } from 'react';
import t from 'prop-types';
import { TextArea } from 'semantic-ui-react';
import clsx from 'clsx';

const MIN_ROW = 1;

function CustomTextArea({
  value, onChange, placeholder, hasTitle, title, hasError, errorDescription, minRows, maxRows,
}) {
  const [rows, setRows] = useState(minRows || MIN_ROW);
  const textareaRef = useRef();

  useEffect(() => {
    const textareaLineHeight = 24;

    const textareaHtml = textareaRef.current;
    textareaRef.current.rows = minRows || MIN_ROW;
    // Forces textarea to resize number of rows
    const currentRows = (textareaHtml.ref.current.scrollHeight / textareaLineHeight);

    let newRows = Math.round(currentRows);
    if (maxRows !== null) {
      newRows = newRows > maxRows ? maxRows : newRows;
    }
    textareaRef.current.rows = newRows;

    setRows(newRows);
  }, []);

  const handleChange = (event) => {
    const textareaLineHeight = 24;

    event.target.rows = minRows || MIN_ROW;

    const currentRows = (event.target.scrollHeight / textareaLineHeight);
    let newRows = Math.round(currentRows);
    if (maxRows !== null) {
      newRows = newRows > maxRows ? maxRows : newRows;
    }
    textareaRef.current.rows = newRows;

    event.target.rows = newRows;
    setRows(newRows);
    onChange(event.target.value);
  };

  return (
    <div className="text-input__wrapper">
      {hasTitle && <div className="text-input__label">{title}</div>}
      <div className={clsx('custom-textarea', {
        'text-input__input__error': hasError,
      })}
      >
        <TextArea
          rows={`${rows || MIN_ROW}`}
          value={value}
          ref={textareaRef}
          className="custom-textarea-input"
          placeholder={placeholder}
          onChange={((e) => { handleChange(e); })}
        />
      </div>
      {hasError && errorDescription && (
        <span className="text-input__error" dangerouslySetInnerHTML={{ __html: errorDescription }} />
      )}
    </div>
  );
}

CustomTextArea.propTypes = {
  hasTitle: t.bool,
  title: t.string,
  placeholder: t.string,
  value: t.string,
  onChange: t.func,
  hasError: t.bool,
  errorDescription: t.string,
  minRows: t.number,
  maxRows: t.number,
};

CustomTextArea.defaultProps = {
  hasTitle: true,
  title: '',
  placeholder: '',
  value: '',
  hasError: false,
  onChange: () => {},
  errorDescription: '',
  minRows: 1,
  maxRows: null,
};

export default CustomTextArea;
