import {
  ADD_ACTIVITY_REPORT,
  APPLY_FILTERS,
  CREATE_REPORT,
  DELETE_REPORT,
  GET_REPORTS,
  RESET_FILTERS,
  SELECT_REPORT,
  SET_VIEW_REPORT,
  UNSELECT_REPORT,
  UPDATE_ACTIVITY_REPORT,
  RESET_REPORTS,
  GET_COMMENTS,
} from '../types';

const initialState = {
  reports: [],
  filters: {},
  selectedReport: null,
  isMapPanel: false,
  comments: [],
  activityFeed: [],
  activityUsersColor: {},
  activityFeedOffset: 0,
  activityFeedReachedEnd: false,
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case (GET_REPORTS): {
      return {
        ...state,
        reports: action.payload.reports,
        filters: {
          ...(state.filters || {}),
        },
      };
    }

    case (CREATE_REPORT): {
      const oldReports = state.reports || [];
      const { newReport } = action.payload;

      return {
        ...state,
        reports: oldReports.concat(newReport),
        selectedReport: newReport,
      };
    }

    case (UPDATE_ACTIVITY_REPORT): {
      const oldActivity = state.activityFeed || [];
      const { colors, activityFeed: newActivity } = action.payload;
      return {
        ...state,
        activityFeed: oldActivity.concat(...newActivity),
        activityUsersColor: colors,
        activityFeedOffset: oldActivity.concat(...newActivity).length,
        activityFeedReachedEnd: action.payload.hasReachedEnd,
      };
    }

    case (GET_COMMENTS): {
      return {
        ...state,
        comments: action.payload.comments,
      };
    }
    case (APPLY_FILTERS): {
      return {
        ...state,
        filters: action.payload.filters,
      };
    }

    case (RESET_FILTERS): {
      return {
        ...state,
        filters: {},
      };
    }

    case (RESET_REPORTS): {
      return {
        ...state,
        reports: []
      };
    }

    case (SELECT_REPORT): {
      const { selectedReport } = action.payload;
      return {
        ...state,
        selectedReport
      };
    }

    case (SET_VIEW_REPORT): {
      const { updatedReports } = action.payload;
      return {
        ...state,
        reports: updatedReports,
      };
    }

    case (ADD_ACTIVITY_REPORT): {
      const oldActivity = state.activityFeed || [];
      const { colors, newActivityFeed: newActivity } = action.payload;
      return {
        ...state,
        activityFeed: [...newActivity, ...oldActivity],
        activityUsersColor: colors,
        activityFeedOffset: [...newActivity, ...oldActivity].length,
        activityFeedReachedEnd: action.payload.hasReachedEnd,
      };
    }

    case (UNSELECT_REPORT): {
      return {
        ...state,
        selectedReport: null,
        activityFeed: [],
        activityFeedOffset: 0,
        activityFeedReachedEnd: false,
      };
    }

    case (DELETE_REPORT): {
      const { reportId } = action.payload;
      const filteredReports = state.reports.filter((elem) => (elem.id !== reportId));

      return {
        ...state,
        reports: filteredReports,
        selectedReport: null,
      };
    }
    default:
      return state;
  }
};

export default reportsReducer;
