import '../../../styles/index.scss';
import SearchReports from './SearchReports/SearchReports';
import TriangleButton from '../../shared/TriangleButton/TriangleButton';

function ReportHeader({
  filtersHandler,
  showFilters
}) {

  return (
    <div className="report-header__wrapper">
      {!showFilters &&
        <TriangleButton handler={filtersHandler} />
      }
      <SearchReports />
    </div>
  );
}

export default ReportHeader;
