import React, { useEffect, useState } from 'react';
import t from 'prop-types';
import SelectBox from '../../ui/SelectBox/SelectBox';
import { findTranslation } from '../../../utils/translationsUtils';
import { connect } from 'react-redux';
import mapDispatchToProps from '../../../actions';

const DynamicTagSelect = ({
  title, placeholder, alwaysMulti,
  tagGroup, tagOptions, selectedValues, onChange,
  defaultLanguage, currentLanguage
}) => {

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if(!options.length) {
      setOptions(tagOptions.map((elem) => ({
        key: elem.id,
        value: elem.id,
        text: findTranslation(elem?.translations, currentLanguage)? findTranslation(elem?.translations, currentLanguage) : findTranslation(elem?.translations, defaultLanguage),
      })));
    }
  }, [tagOptions]);

  /*
  const handleAddValue = (e, { value }) => {
    setOptions((prevState) => [{ text: value, value: value }, ...prevState]);
  };

  // Text type allow searching + adding values. Single-select.
  if (tagGroup.type === 'text') {
    return (
      <SelectBox
        title={title}
        placeholder={placeholder}
        allowAdditions={true}
        hideAdditions={false}
        search={true}
        options={options}
        clearable={true}
        value={selectedValues || undefined}
        onAddItem={ handleAddValue }
        handleChange={(e, { value }) => {
          onChange(tagGroup.id, value);
        }}
      />
    );
  }
  */

  // Multi-select
  if (tagGroup.type === 'multi' || alwaysMulti) {
    return (
      <SelectBox
        title={title}
        placeholder={placeholder}
        handleChange={(e, { value }) => {
          onChange(tagGroup.id, value); }}
        multiple={true}
        clearable={true}
        value={selectedValues || []}
        options={options}
      />
    );
  }

  // Single-select
  return (
    <SelectBox
      title={title}
      placeholder={placeholder}
      handleChange={(e, { value }) => {
        onChange(tagGroup.id, value); }}
      clearable={true}
      value={selectedValues || ''}
      options={options}
    />
  );
};

DynamicTagSelect.propTypes = {
  title: t.string,
  alwaysMulti: t.bool,
  selectedValues: t.oneOfType([t.object, t.array, t.string]),
  tagGroup: t.object.isRequired,
  tagOptions: t.array.isRequired,
  placeholder: t.string.isRequired,
  onChange: t.func.isRequired,
};

DynamicTagSelect.defaultProps = {
  title: '',
  alwaysMulti: false,
};

const mapStateToProps = ({ common }) => ({
  defaultLanguage: common.defaultLanguage,
  currentLanguage: common.currentLanguage,
});

export default connect(mapStateToProps,  mapDispatchToProps)(DynamicTagSelect);
