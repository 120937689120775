import Dropzone from 'react-dropzone';
import '../../../styles/index.scss';
import React, { useState, useLayoutEffect, useEffect } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import FiltersPanel from '../FiltersPanel/FiltersPanel';
import ReportHeader from '../ReportHeader/ReportHeader';
import ReportsMap from '../ReportsMap/ReportsMap';
import ReportZip from '../ReportZip/ReportZip';
import ReportListContainer from '../ReportListContainer/ReportListContainer';
import dashboardHoc from '../DashboardHoc/DashboardHoc';
import mapDispatchToProps from '../../../actions/index';
import { isDemo } from '../../../utils';

function ReportsContainer(props) {
  const [showFiltersPanel, setShowFiltersPanel] = useState(true);

  useEffect(() => {
    if (props.reports.length === 0) props.getReports();
    if (props.layers.length === 0) props.getOrganizationLayers();

    document.title = 'Timby - Reports';
  }, []);

  useLayoutEffect(() => {
    setTimeout(
      () => { window.dispatchEvent(new Event('resize')); },
      200,
    );
  }, [showFiltersPanel]);

  return (
    <Dropzone
      accept="application/zip"
      onDrop={(acceptedFiles) => {}}
    >
      {({ getRootProps, isDragActive, isDragReject }) => (
        <div className="reports-container__dropzone--wrapper" {...getRootProps()}>
          {isDemo() && isDragActive
            && (
            <ReportZip
              message={isDragReject
                ? 'This file will be rejected !!' : 'Drag and drop report .zip files here'}
            />
            )}
          <div className={clsx('reports-container__small-panel', {
            'reports-container__small-panel--hidden': !showFiltersPanel,
          })}
          >
            <FiltersPanel
              showPanel={showFiltersPanel}
              handleShow={() => setShowFiltersPanel(false)}
            />
          </div>
          <div className="reports-container__mid-panel">
            <ReportHeader
              filtersHandler={() => setShowFiltersPanel(true)}
              showFilters={showFiltersPanel}
              noButtons
            />
            <ReportListContainer />
          </div>
          <div className="reports-container__large-panel">
            {window.dispatchEvent(new Event('resize'))}
            <ReportsMap />
          </div>
        </div>
      )}
    </Dropzone>
  );
}

const mapStateToProps = ({
  admin, reports, folders,
}) => ({
  reports: reports.reports,
  layers: admin.layers,
  folders: folders.selectedFolders,
});

export default dashboardHoc(connect(mapStateToProps, mapDispatchToProps)(ReportsContainer));
