import {
  ADD_TAG_GROUP, ADD_TAG,
  UPDATE_TAG_GROUP, UPDATE_TAG,
  DELETE_TAG_GROUP, DELETE_TAG,
  GET_TAG_GROUPS, GET_ORGANIZATION_TAGS, GET_TAGS,
  APPLY_FILTERS, RESET_FILTERS, RESET_REPORTS,
} from '../types';
import i18n from '../translations/i18n';
import { endpoints } from './endpoints';
import { addNewAlert, closeLoader, getToken, openLoader } from './CommonActions';
import { ALERT_ERROR, ALERT_SUCCESS } from '../shared/consts';

const GROUP_TAG_MAX_LENGTH = 20;
const TAG_ELEMENT_MAX_LENGTH = 40;
const TAG_TEXT_PROMPT_ELEMENT_MAX_LENGTH = 100;

export const getTagGroups = () => async (dispatch) => {
  try {
    const token = await dispatch(getToken());

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    dispatch(openLoader());

    const fetchTagsGroups = await fetch(endpoints.getTaxonomyGroups, options);
    const { error, data, status } = await fetchTagsGroups.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      dispatch({ type: GET_TAG_GROUPS, payload: { tagGroups: data } });
    }

  } catch {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const getTags = () => async (dispatch) => {
  try {
    const token = await dispatch(getToken());

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    dispatch(openLoader());

    const fetchTags = await fetch(endpoints.getTaxonomies, options);
    const { error, data, status } = await fetchTags.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      dispatch({ type: GET_TAGS, payload: { tags: data } });
    }

  } catch {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const getOrganizationTags = (selectedOrganization) => async (dispatch) => {
  try {
    const token = await dispatch(getToken());

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    dispatch(openLoader());

    const fetchTags = await fetch(endpoints.getOrganizationTaxonomies(selectedOrganization), options);
    const { error, data, status } = await fetchTags.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      dispatch({ type: GET_ORGANIZATION_TAGS, payload: { organizationTags: data } });
    }

  } catch {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const createTagGroup = (groupData) => async (dispatch, getState) => {
  const { common } = getState();
  const errors = {};
  const token = await dispatch(getToken());

  // Validations
  // Check that at least the default app language is filled
  const hasDefaultTranslation = groupData.name[common.defaultLanguage];
  if (!hasDefaultTranslation) {
    errors[common.defaultLanguage] = i18n.t('validations.required');
  }

  /* this was before: we were checking that all languages were filled.
  const languagesCodes = languages.map((elem) => elem.locale);
  languagesCodes.map((elem) => {
    if (Object.keys(groupData.name).filter(e => { return e.locale === elem; } )) return null;
    errors[elem] = i18n.t('validations.required');
    return null;
  });
  */

  // check that no value is more than the allowed nb of chars
  Object.keys(groupData.name).map((key) => {
    if (groupData.name[key].length > GROUP_TAG_MAX_LENGTH) {
      errors[key] = i18n.t('validations.taxonomyGroupNameExceeded');
    }
    return null;
  });

  if (Object.keys(errors).length > 0) {
    dispatch(addNewAlert(i18n.t('notifications.fieldValidations'), ALERT_ERROR));
    return { success: false, errors };
  }

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(groupData),
  };

  dispatch(openLoader());
  try {
    const response = await fetch(endpoints.createTaxonomyGroup, options);
    const { error, status, data } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      dispatch(addNewAlert(i18n.t('notifications.tagGroupCreatedSuccess'), ALERT_SUCCESS));
      groupData = data;
      delete groupData.name;
      dispatch({ type: ADD_TAG_GROUP, payload: groupData });
      dispatch(getTagGroups());
    }
  } catch (e) {
    console.warn(e);
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    return { success: false, errors: [] };
  } finally {
    dispatch(closeLoader());
  }
  return { success: true, errors: [] };
};

export const editTagGroup = (groupTagId, groupData) => async (dispatch, getState) => {
  const { common } = getState();
  const token = await dispatch(getToken());
  const errors = {};

  // Validation
  // check that at least the default app language is filled
  const hasDefaultTranslation = groupData.name[common.defaultLanguage];
  if (!hasDefaultTranslation) {
    errors[common.defaultLanguage] = i18n.t('validations.required');
  }

  // check that no value is more than the allowed nb of chars
  Object.keys(groupData.name).map((key) => {
    if (groupData.name[key].length > GROUP_TAG_MAX_LENGTH) {
      errors[key] = i18n.t('validations.taxonomyGroupNameExceeded');
    }
    return null;
  });

  if (Object.keys(errors).length > 0) {
    dispatch(addNewAlert(i18n.t('notifications.fieldValidations'), ALERT_ERROR));
    return { success: false, errors };
  }

  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(groupData),
  };

  dispatch(openLoader());
  try {
    const response = await fetch(
      endpoints.alterTaxonomyGroupElement(groupTagId), options,
    );
    const { error, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      delete groupData.name;
      dispatch({ type: UPDATE_TAG_GROUP, payload: groupData });
      dispatch(getTagGroups());
      dispatch(addNewAlert(i18n.t('notifications.tagGroupUpdatedSuccess'), ALERT_SUCCESS));
    }
  } catch (e) {
    console.warn(e);
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    return { success: false, errors: [] };
  } finally {
    dispatch(closeLoader());
  }
  return { success: true, errors: [] };
};

export const deleteTagGroup = (groupTagId) => async (dispatch) => {
  const token = await dispatch(getToken());

  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  dispatch(openLoader());
  try {
    const response = await fetch(
      endpoints.alterTaxonomyGroupElement(groupTagId), options,
    );
    const { error, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
      return { success: false };
    } else {
      dispatch(addNewAlert(i18n.t('notifications.tagGroupDeletedSuccess'), ALERT_SUCCESS));
      dispatch({ type: DELETE_TAG_GROUP, payload: groupTagId });
      dispatch(getTagGroups());
    }
  } catch (e) {
    console.warn(e);
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    return { success: false };
  } finally {
    dispatch(closeLoader());
  }
  return { success: true };
};

export const createTag = (groupTagId, tagData, type = 'single') => async (dispatch, getState) => {
  const { common, admin } = getState();
  const token = await dispatch(getToken());
  const errors = {};

  // Validation
  // Check that at least the default app language is filled
  const hasDefaultTranslation = tagData.name[common.defaultLanguage];
  if (!hasDefaultTranslation) {
    errors[common.defaultLanguage] = i18n.t('validations.required');
  }

  // validate labels length
  // check that no value is more than the allowed nb of chars
  const elementMaxLength = type === 'text' ? TAG_TEXT_PROMPT_ELEMENT_MAX_LENGTH : TAG_ELEMENT_MAX_LENGTH;
  Object.keys(tagData.name).map((key) => {
    if (tagData.name[key].length > elementMaxLength) {
      errors[key] = i18n.t('validations.taxonomyChildNameExceeded', { elementMaxLength });
    }
    return null;
  });

  if (Object.keys(errors).length > 0) {
    dispatch(addNewAlert(i18n.t('notifications.fieldValidations'), ALERT_ERROR));
    return { success: false, errors };
  }

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(tagData),
  };

  dispatch(openLoader());
  try {
    const response = await fetch(
      endpoints.createTaxonomyChildElement(admin.selectedOrganizationId, groupTagId), options,
    );
    const { error, data, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      dispatch(addNewAlert(i18n.t('notifications.tagOptionCreatedSuccess'), ALERT_SUCCESS));
      tagData.id = data;
      delete tagData.name;

      dispatch({ type: ADD_TAG, payload: tagData });
      dispatch(getTags());
    }
  } catch (e) {
    console.warn(e);
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    return { success: false, errors: [] };
  } finally {
    dispatch(closeLoader());
  }
  return { success: true, errors: [] };
};

export const editTag = (groupTagId, tagId, tagData) => async (dispatch, getState) => {
  const { admin, common } = getState();
  const token = await dispatch(getToken());
  const errors = {};

  // Validation
  // Check that at least the default app language is filled
  const hasDefaultTranslation = tagData.name[common.defaultLanguage];
  if (!hasDefaultTranslation) {
    errors[common.defaultLanguage] = i18n.t('validations.required');
  }

  // validate labels length
  // check that no value is more than the allowed nb of chars
  const elementMaxLength = tagData.type === 'text' ? TAG_TEXT_PROMPT_ELEMENT_MAX_LENGTH : TAG_ELEMENT_MAX_LENGTH;
  Object.keys(tagData.name).map((key) => {
    if (tagData.name[key].length > elementMaxLength) {
      errors[key] = i18n.t('validations.taxonomyChildNameExceeded', { elementMaxLength });
    }
    return null;
  });

  if (Object.keys(errors).length > 0) {
    dispatch(addNewAlert(i18n.t('notifications.fieldValidations'), ALERT_ERROR));
    return { success: false, errors };
  }

  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(tagData),
  };

  dispatch(openLoader());
  try {
    const response = await fetch(
      endpoints.alterTaxonomyChildElement(admin.selectedOrganizationId, tagId), options,
    );
    const { error, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      dispatch(addNewAlert(i18n.t('notifications.tagOptionUpdatedSuccess'), ALERT_SUCCESS));
      delete tagData.name;
      dispatch({ type: UPDATE_TAG, payload: tagData });
    }
  } catch (e) {
    console.warn(e);
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));

    return { success: false, errors: [] };
  } finally {
    dispatch(closeLoader());
  }
  return { success: true, errors: [] };
};

export const deleteTag = (groupTagId, tagId) => async (dispatch, getState) => {
  const { admin } = getState();
  const token = await dispatch(getToken());

  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  dispatch(openLoader());
  try {
    const response = await fetch(
      endpoints.alterTaxonomyChildElement(admin.selectedOrganizationId, tagId), options,
    );
    const { error, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
      return { success: false };
    } else {
      dispatch(addNewAlert(i18n.t('notifications.tagOptionDeletedSuccess'), ALERT_SUCCESS));
      dispatch({ type: DELETE_TAG, payload: tagId });
      dispatch(getTags());
    }
  } catch (e) {
    console.warn(e);
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    return { success: false };
  } finally {
    dispatch(closeLoader());
  }
  return { success: true };
};

export const filterTaxonomies = (
  tagGroupId,
  selectedValue,
) => async (dispatch) => {
  dispatch({
    type: APPLY_FILTERS,
    payload: {
      tagGroup: tagGroupId,
      selectedValue,
    },
  });
  return true;
};

export const applyFilters = () => async (dispatch, getState) => {
  const { tags } = getState();

  try {
    dispatch(openLoader());
    dispatch({ type: RESET_REPORTS });
    dispatch({ type: APPLY_FILTERS, payload: { filters: tags.selectedTags } });
  } catch (e) {
    console.warn(e);
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const resetFilters = () => async (dispatch) => {

  try {
    dispatch(openLoader());
    dispatch({ type: RESET_REPORTS });
    dispatch({ type: RESET_FILTERS });
  } catch (e) {
    console.warn(e);
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };

};
