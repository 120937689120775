import React, { useState } from 'react';
import t from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import RoundedIcon from '../../../shared/RoundedIcon/RoundedIcon';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/delete.svg';
import { ReactComponent as ClockIcon } from '../../../../assets/images/clock.svg';
import LocalDate from '../../../shared/LocalDate/LocalDate';

function StoryReport({
  dragged = false, report, isEditable = false, editHandler = () => {}
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <div className="story-report-container">
      {isEditable && <div className="story-report-edit"><RoundedIcon hover handler={() => editHandler()} SvgComponent={DeleteIcon} /></div> /*  Insert delete icon here with handler */}
      <div className="story-report-title">
        {report.title}
      </div>
      <div className="story-report-info">
        <div className="story-report-info-date">
          <ClockIcon className="story-report-info-date-icon" />
          <LocalDate date={report.event_date} format='full' />
        </div>
      </div>
      <div className={clsx('story-report-body', {
        'story-report-body-open': open,
      })}
      >
        {(!open && !dragged) && <div className="story-report-body-gradient" />}
        <span className="story-report-body-description">{report.content}</span>
      </div>
      <div
        tabIndex={-9999}
        role="button"
        onClick={() => setOpen(!open)}
        onKeyDown={() => setOpen(!open)}
        className="story-report-opencontainer"
      >
        {!open ? t('readFullReport') : t('closeReport')}
      </div>
    </div>
  );
}

StoryReport.propTypes = {
  report: t.object.isRequired,
  isEditable: t.bool,
  editHandler: t.func,
  dragged: t.bool,
};

StoryReport.defaultProps = {
  isEditable: false,
  editHandler: () => {},
  dragged: false,
};

export default StoryReport;
