import {
  SAVE_USER_DATA,
  SET_TOKEN,
  UPDATE_USER,
  UPDATE_USER_PROFILE,
  USER_LOGIN_ERROR,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_TWOFACTOR,
  USER_LOGOUT,
  USER_SET_PASSWORD_ERROR,
  USER_TWO_FACTOR_ERROR,
  USER_TWO_FACTOR_SUCCESS,
} from '../types';
import { history } from '../index';
import i18n from '../translations/i18n';
import { endpoints } from './endpoints';
import { ALERT_ERROR, ALERT_SUCCESS } from '../shared/consts';
import { decrypt, findOption } from '../utils';
import { convertPasswordErrorsIntoText } from './AdminActions';
import { addNewAlert, closeLoader, getToken, openLoader, setUpCookie, changeLanguage } from './CommonActions';

const MIN_PASSWORD_LENGTH = 7;
const MAX_PASSWORD_LENGTH = 72;

const loginData = (userData) => ({
  id: userData?.id,
  email: findOption('email', userData?.options),
  firstname: findOption('firstname', userData?.options),
  lastname: findOption('lastname', userData?.options),
  language: findOption('language', userData?.options),
  secondfactor: findOption('secondfactor', userData?.options),
  needs2FA: false,
  role: userData?.role?.name || '',
  organizations: userData?.organizations || [],
  capabilities: userData?.role.capabilities.map((elem) => (elem.name)),
});

export const doLogin = (username, password) => async (dispatch, getState) => {
  // Validate Fields
  let errors = {};

  const usernameRegex = /^[a-zA-z0-9_.-]+$/i;

  if(!username) {
    errors.username = i18n.t('validations.required');
  } else if (!usernameRegex.exec(username)) {
    errors.username = i18n.t('notifications.usernameAlphabeticRequired');
  }

  if(!password) {
    errors.password = i18n.t('validations.required');
  }

  if (Object.keys(errors).length > 0) {
    dispatch(closeLoader());
    return { success: false, errors };
  }

  const args = {
    'username': username,
    'password': password,
  };

  const options = {
    method: 'POST',
    'Content-Type': 'application/json',
    Accept: 'application/json',
    body: JSON.stringify(args),
  };

  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.login, options);
    const { data, error, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));

      return { success: false, errors: {} };
    } else if (status === 401 && data.startsWith('2FA')) {
      dispatch({
        type: USER_LOGIN_TWOFACTOR,
        payload: {
          userInfo: {
            username,
            needs2FA: true,
            password,
          },
        },
        twoFactor: false,
      });
      history.push({
        pathname: '/two-factor-code',
        from: history.location.pathname,
      });
      return { success: true };
    } else {
      const { languages } = getState().common;
      const userData = data?.user;
      const userLanguage = findOption('language', userData?.options);
      const selectedLanguage = languages.find((lang) => (lang.locale === userLanguage));
      if (selectedLanguage?.locale) dispatch(changeLanguage(selectedLanguage.locale));

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: {
          userInfo: {
            username,
            ...loginData(userData),
          },
          token: data?.token?.access_token,
          ...(selectedLanguage?.code) && { language: selectedLanguage.code },
          defaultOrganization: userData?.organizations.length > 0
            ? userData?.organizations[0]
            : null,
        },
      });

      dispatch(setUpCookie(data.token));
      dispatch({
        type: SET_TOKEN,
        payload: { token: data?.token?.access_token },
      });

      dispatch(addNewAlert(i18n.t('notifications.login'), ALERT_SUCCESS));
      history.push({
        pathname: '/dashboard',
        from: history.location.pathname,
      });
      return { success: true };
    }
  } catch (e) {
    console.warn(e);
    dispatch({
      type: USER_LOGIN_ERROR,
      payload: '',
    });
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }
};

export const doLogout = () => (dispatch) => {
  dispatch({ type: USER_LOGOUT });
  dispatch(addNewAlert(i18n.t('notifications.logout'), ALERT_SUCCESS));
  history.push('/login');
};

export const twoFactorSend = () => async (dispatch, getState) => {
  const state = getState();
  const { userInfo } = state.user;

  const decodedPassword = decrypt(userInfo.password);

  const args = {
    'username': userInfo.username,
    'password': decodedPassword,
  };

  const options = {
    method: 'POST',
    'Content-Type': 'application/json',
    Accept: 'application/json',
    body: JSON.stringify(args),
  };

  const response = await fetch(endpoints.login, options);
  const { status, error } = await response.json();

  if (status !== 200 && status !== 401) {
    const message = i18n.t(['api.'+error.type, 'api.ERROR']);
    dispatch(addNewAlert(message, ALERT_ERROR));
    return { success: false };
  }

  dispatch(addNewAlert(i18n.t('notifications.resendToken'), ALERT_SUCCESS));
  return { success: true };
};

export const twoFactorSubmit = (twoFactorCode, remember2fa) => async (dispatch, getState) => {
  // Validate Code
  if (!twoFactorCode) {
    dispatch({
      type: USER_TWO_FACTOR_ERROR,
      payload: '',
    });
    return {
      success: false,
      errors: { code: i18n.t('validations.required') },
    };
  }

  if (twoFactorCode && twoFactorCode.length !== 6) {
    dispatch({
      type: USER_TWO_FACTOR_ERROR,
      payload: '',
    });
    return {
      success: false,
      errors: { code: i18n.t('validations.maxCodeLength') },
    };
  }

  const state = getState();
  const { userInfo } = state.user;

  const decodedPassword = decrypt(userInfo.password);

  const args = {
    'username': userInfo.username,
    'password': decodedPassword,
    'code': twoFactorCode,
  };
  if (remember2fa) {
    args.remember2fa = remember2fa;
  }

  const options = {
    method: 'POST',
    'Content-Type': 'application/json',
    Accept: 'application/json',
    body: JSON.stringify(args),
  };

  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.twoFactor, options);
    const { data, status, error } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));

      return {
        success: false,
        errors: { code: i18n.t('validations.invalidCode') },
      };

    } else {
      const userData = data?.user;
      const { languages } = state.common;
      const userLanguage = findOption('language', userData?.options);
      const selectedLanguage = languages.find((lang) => (lang.locale === userLanguage));
      if (selectedLanguage?.locale) dispatch(changeLanguage(selectedLanguage.locale));

      dispatch({
        type: USER_TWO_FACTOR_SUCCESS,
        payload: {
          userInfo: {
            ...userInfo,
            ...loginData(userData),
          },
          token: data?.token?.access_token,
          twoFactor: true,
          ...(selectedLanguage?.locale) && { language: selectedLanguage.locale },
          defaultOrganization: userData?.organizations?.length
            ? userData?.organizations[0]
            : null,
        },
      });

      dispatch(setUpCookie(data.token));
      dispatch({
        type: SET_TOKEN,
        payload: { token: data?.token?.access_token },
      });

      dispatch(addNewAlert(i18n.t('notifications.login'), ALERT_SUCCESS));

      history.push({
        pathname: '/dashboard',
        from: history.location.pathname,
      });
    }

  } catch (e) {
    dispatch({
      type: USER_TWO_FACTOR_ERROR,
      payload: '',
    });
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }
  return {
    success: true,
    errors: [],
  };
};

export const requestPasswordRecovery = (username) => async (dispatch) => {
  // Validate Fields
  let errors = {};

  if(!username) {
    errors.username = i18n.t('validations.required');
  }

  if (Object.keys(errors).length > 0) {
    dispatch(closeLoader());
    return { success: false, errors };
  }

  dispatch({
    type: SAVE_USER_DATA,
    payload: {
      tempUser: username,
    },
  });

  const formData = new FormData();
  formData.append('username', username);
  const options = {
    method: 'POST',
    body: formData,
  };
  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.resetPassword(username), options);
    const { error, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
      return { success: false, errors: {} };
    } else {
      dispatch(addNewAlert(i18n.t('notifications.passwordRecoverySuccess'), ALERT_SUCCESS));
      history.push({
        pathname: '/confirm-password',
        from: history.location.pathname,
      });
      return { success: true };
    }
  } catch {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }
};

export const checkToken = () => () => {
  const searchParams = new URLSearchParams(history.location.search);
  if (!searchParams.get('token')) {
    return history.replace('/login');
  }
  return {
    uid: searchParams.get('uid'),
    token: searchParams.get('token'),
  };
};

const checkPasswordErrors = (password, regex, error, errors) => {
  if (!regex.exec(unescape(password))) {
    errors.push(error);
  }
  return errors;
};

const checkPasswordRegex = (newPassword) => {
  const regexChecks = [];
  // Regex that checks if a string has at least 1 uppercase string
  const passwordUppercaseRegex = /^(?=.*[A-Z])^/;
  checkPasswordErrors(newPassword, passwordUppercaseRegex, 'upperCaseRequired', regexChecks);
  // Regex that checks if a string has at least 1 downcase string
  const passwordDowncaseRegex = /^(?=.*[a-z])^/;
  checkPasswordErrors(newPassword, passwordDowncaseRegex, 'downCaseRequired', regexChecks);
  // Regex that checks if a string has at least 1 number/digit ASCII
  const passwordNumbersRegex = /^(?=.*\d)^/;
  checkPasswordErrors(newPassword, passwordNumbersRegex, 'numberRequired', regexChecks);
  // Regex that checks if a string has at least 1 non alphanumeric digits
  const passwordNonAlphaNumericAsciiRegex = /^(?=.*[\W\s_])^/;
  checkPasswordErrors(newPassword, passwordNonAlphaNumericAsciiRegex, 'specialCharRequired', regexChecks);
  // Regex that checks if a string has at least 1 non ascii regex
  const passwordAllAsciiRegex = /^[\000-\177]*$/;
  if (passwordAllAsciiRegex.test(unescape(newPassword))) {
    regexChecks.push('NonAsciiRequired');
  }

  return regexChecks;
};

export const getPasswordErrors = (
  newPassword,
  newPasswordConfirmation,
  dispatcher = true,
) => (dispatch) => {
  let errors = [];

  if (newPassword !== newPasswordConfirmation) {
    errors.push('notEqual');
  }

  const regexChecks = checkPasswordRegex(newPassword);

  if (newPassword.length < MIN_PASSWORD_LENGTH) {
    // console.warn('Password does not meet the length requirements')
    errors.push('minLength');
  }

  if (newPassword.length > MAX_PASSWORD_LENGTH) {
    errors.push('maxLength');
  }

  // Must include characters from at least three (3) of the regex validations
  if (regexChecks.length >= 3) {
    errors = errors.concat(regexChecks);
  }

  if (errors.length > 0) {
    if (dispatcher) {
      dispatch({
        type: USER_SET_PASSWORD_ERROR,
        payload: { passwordErrors: errors },
      });
    }
    return {
      success: true,
      errors,
    };
  }

  return {
    success: false,
    errors,
  };
};

export const resetPassword = (
  newPassword,
  newPasswordConfirmation,
) => async (dispatch) => {

  const errors = {};
  const errorsResult = dispatch(getPasswordErrors(newPassword, newPasswordConfirmation, false));

  if (errorsResult.success) {
    errors.password = convertPasswordErrorsIntoText(errorsResult.errors);
    dispatch(addNewAlert(errors.password, ALERT_ERROR));
    return { success: false, errors };
  } else {
    const { uid, token } = dispatch(checkToken());

    const body = {
      password: newPassword,
      uid,
      token,
    };

    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    };

    try {
      dispatch(openLoader());
      const response = await fetch(endpoints.setPasswordRecovery(uid), options);
      const { error, status } = await response.json();

      if (status !== 200 && error) {
        const message = i18n.t(['api.'+error.type, 'api.ERROR']);
        dispatch(addNewAlert(message, ALERT_ERROR));
      } else {
        dispatch(addNewAlert(i18n.t('notifications.passwordChangeSuccess'), ALERT_SUCCESS));
        history.push('/login');
      }
    } catch (e) {
      console.log(e);
      dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    } finally {
      dispatch(closeLoader());
    }
  }



};

export const editUser = (uid, userData) => async (dispatch, getState) => {
  const token = await dispatch(getToken());
  const { userInfo } = getState().user;

  const errors = {};
  if (userData.password) {
    const errorsResult = dispatch(getPasswordErrors(userData.password, userData.password, false));
    if (errorsResult.success) {
      errors.password = convertPasswordErrorsIntoText(errorsResult.errors);
      dispatch(addNewAlert(errors.password, ALERT_ERROR));
    }
  }

  // Validation
  if (userData.firstname && userData.firstname.length > 40) errors.firstname = i18n.t('validations.firstNameExceeded');
  if (userData.lastname && userData.lastname.length > 40) errors.lastname = i18n.t('validations.lastNameExceeded');

  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (userData.email && !emailRegex.exec(userData.email)) {
    errors.email = i18n.t('validations.invalidEmail');
  }

  if (Object.keys(errors).length > 0) {
    dispatch(addNewAlert(i18n.t('notifications.fieldValidations'), ALERT_ERROR));
    return { success: false, errors };
  }

  let data = {};
  if(userData.password) data.password = userData.password;

  let userOptions = {};
  if(userData.firstname) userOptions.firstname = userData.firstname;
  if(userData.lastname) userOptions.lastname = userData.lastname;
  if(userData.email) userOptions.email = userData.email;
  if(userData.secondfactor) userOptions.secondfactor = userData.secondfactor;
  if(userData.language) userOptions.language = userData.language;
  if(Object.entries(userOptions).length) data.options = userOptions;


  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.editUser(uid), options);
    const { error, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      dispatch(addNewAlert(i18n.t('notifications.userUpdateSuccess'), ALERT_SUCCESS));
      dispatch({ type: UPDATE_USER, payload: { userData: userData }, });

      dispatch({ type: UPDATE_USER_PROFILE,
        payload: {
          userInfo: {
            ...userInfo,
            ...userData
          }
        },
      });
    }
  } catch {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};
