import React, { forwardRef } from 'react';
import t from 'prop-types';
import '../../../styles/index.scss';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { ReactComponent as CalendarIcon } from '../../../assets/images/calendar.svg';

function ExportReportDatePicker({ handleChange, startDate, endDate }) {
  const CustomDateInput = forwardRef(({ onClick }, ref) => (
    <>
      <CalendarIcon onClick={onClick} ref={ref} />
    </>
  ));

  CustomDateInput.displayName = 'CustomDateInput';

  return (
    <DatePicker
      isClearable={startDate !== null}
      selected={startDate}
      onChange={handleChange}
      startDate={startDate}
      endDate={endDate}
      shouldCloseOnSelect={startDate && !endDate}
      selectsRange
      customInput={<CustomDateInput />}
    />
  );
}

ExportReportDatePicker.propTypes = {
  handleChange: t.func.isRequired,
  startDate: t.string.isRequired,
  endDate: t.string.isRequired,
};

export default ExportReportDatePicker;
