import React, { useEffect, useState } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import '../../../../styles/index.scss';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import mapDispatchToProps from '../../../../actions/index';
import RoundedIcon from '../../../shared/RoundedIcon/RoundedIcon';
import TextInput from '../../../shared/TextInput/TextInput';
import Button from '../../../shared/Button/Button';
import { ReactComponent as EditIcon } from '../../../../assets/images/edit.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/images/close.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/delete.svg';
import DeleteModal from '../../DeleteModal/DeleteModal';
import { findTranslation } from '../../../../utils/translationsUtils';

// Controls a tag row

const AdminTagElement = (props) => {

  const getDefaultTranslationState = () => (
    props.languages.map((elem) => (
      {
        value: props.tag ? findTranslation(props.tag.translations, elem.locale) || '' : '',
        name: elem.name,
        locale: elem.locale,
        key: `name_${elem.locale}`,
      }))
  );

  const getDefaultName = () => {
    return findTranslation(props.tag?.translations, props.defaultLanguage);
  };

  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [name, setName] = useState(getDefaultName());
  const [tagTranslation, setTagTranslation] = useState(getDefaultTranslationState());

  const handleTagTranslation = (value, index) => {
    const updatedTranslation = [...tagTranslation];
    const selectedTranslation = updatedTranslation[index];
    selectedTranslation.value = value;
    if (selectedTranslation.locale === props.defaultLanguage) setName(value);
    setTagTranslation(updatedTranslation);
  };

  useEffect(() => {
    setTagTranslation(getDefaultTranslationState());
  }, [props.languages, props.tag]);

  useEffect(() => {
    if (editMode) {
      setName(tagTranslation.find((elem) => elem.code === props.defaultLanguage).value);
    } else {
      setName(getDefaultName());
    }
  }, [props.defaultLanguage]);

  const getFilledTranslations = () => {
    const translationsHash = {};

    tagTranslation.map(elem => {
      translationsHash[elem.locale] = elem.value;
    });

    return translationsHash;
  };

  const cancelEditMode = () => {
    setEditMode(false);
    setErrors({});
    setTagTranslation(getDefaultTranslationState());
    setName(getDefaultName());
  };

  const onDeleteTag = async () => {
    const result = await props.deleteTag(props.groupTagId, props.tag.id);
    if (result.success) {
      setOpenDelete(false);
    }
  };

  const onEditTag = async () => {
    const tagData = {
      name: getFilledTranslations(),
      type: props.groupType
    };

    const result = await props.editTag(props.groupTagId, props.tag.id, tagData);
    if (result.success) {
      setEditMode(false);
      setErrors({});
    } else {
      setErrors(result.errors);
    }
  };

  return (
    <div
      className={clsx('admin_tag_element-linecontainer', {
        'admin_tag_element-linecontainer-editmode': editMode,
      })}
    >
      <div
        className="admin_tag_element-linecontainer-info"
      >
        {/* Tag View */}
        <span className="admin_tag_element-linecontainer__text">
          {name}
          {/* Tags Name */}
        </span>
        <div className={clsx('admin_tag_element-linecontainer__icons')}>
          <RoundedIcon
            SvgComponent={editMode ? CloseIcon : EditIcon}
            hover
            border
            handler={editMode ? () => cancelEditMode() : () => setEditMode(true)}
          />
          <RoundedIcon SvgComponent={DeleteIcon} hover border handler={() => setOpenDelete(true)} />
        </div>
      </div>
      <DeleteModal
        onClose={() => setOpenDelete(false)}
        open={openDelete}
        onOpen={() => setOpenDelete(true)}
        hasTrigger={false}
        header={t('deleteTag')}
        subtitle={t('deleteTagDescription')}
        deleteAction={() => onDeleteTag()}
      />
      {editMode
      && (
        <div className="admin_tag_element-form__wrapper">
          <div className="admin_tag_element-form__input-container">
            {tagTranslation.map((elem, index) => (
              <TextInput
                title={`${t('name')} - ${elem.name}`}
                placeholder={`${t('enterNameIn')} - ${elem.name}`}
                value={elem.value}
                hasError={!!errors[elem.locale]}
                errorDescription={errors[elem.locale]}
                key={`tag-${props.tag.id}-${elem.locale}`}
                handleChange={(event) => handleTagTranslation(event.target.value, index)}
              />
            ))}
          </div>
          <div className="admin_tag_element-form__buttons">
            <Button text={t('save')} primary medium handler={() => onEditTag()} />
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ common }) => ({
  languages: common.languages,
  defaultLanguage: common.defaultLanguage,
});

AdminTagElement.propTypes = {
  languages: t.array.isRequired,
  defaultLanguage: t.string.isRequired,
  tag: t.object.isRequired,
  deleteTag: t.func.isRequired,
  groupTagId: t.string.isRequired,
  editTag: t.func.isRequired,
  groupType: t.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminTagElement);
