import '../../../styles/index.scss';
import React from 'react';
import t from 'prop-types';
import clsx from 'clsx';

function RoundedIcon({
  disabled,
  border,
  hover,
  handler,
  medium,
  small,
  large,
  SvgComponent,
  iconColor,
}) {
  return (
    <div
      className={clsx(
        'rounded-icon_wrapper', {
          'rounded-icon_wrapper--border': border && !disabled,
          'rounded-icon_wrapper--hover': hover && !disabled,
          'rounded-icon_wrapper--large': large,
          'rounded-icon_wrapper--medium': medium,
          'rounded-icon_wrapper--small': small,
          'rounded-icon_wrapper--disabled': disabled,
        },
      )}
      role="button"
      tabIndex={-9999}
      onKeyDown={disabled ? undefined : handler}
      onClick={disabled ? undefined : handler}
    >
      <SvgComponent className="rounded-icon-svg" style={{ fill: iconColor }} />
    </div>
  );
}

RoundedIcon.propTypes = {
  disabled: t.bool,
  border: t.bool,
  hover: t.bool,
  handler: t.func,
  medium: t.bool,
  small: t.bool,
  large: t.bool,
  SvgComponent: t.node.isRequired,
  iconColor: t.string,
};

RoundedIcon.defaultProps = {
  disabled: false,
  border: false,
  hover: false,
  handler: () => {},
  medium: false,
  small: false,
  large: false,
  iconColor: '',
};

export default RoundedIcon;
