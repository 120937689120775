/* eslint-disable react/jsx-props-no-spreading */
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import t from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Button from '../../../shared/Button/Button';
import TextInput from '../../../shared/TextInput/TextInput';
import Toggle from '../../../ui/Toggle/Toggle';
import FiltersPanel from '../../FiltersPanel/FiltersPanel';
import ReportHeader from '../../ReportHeader/ReportHeader';
import StoryOptionalTextField from './StoryOptionalTextField';
import StoryReportsList from './StoryReportsList';
import StoryReport from '../StoryReport/StoryReport';
import { ReactComponent as CloseIcon } from '../../../../assets/images/close.svg';
import mapDispatchToProps from '../../../../actions';
import StoryCoverImage from './StoryCoverImage';

const PUBLISHED = 'Published';
const UNPUBLISHED = 'Unpublished';

const reorder = (list, startIndex, endIndex) => {
  if (list.length === 0) {
    return [];
  }
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result || [];
};

function StoryForm(props) {
  const { t } = useTranslation();

  const [showFiltersPanel, setShowFiltersPanel] = useState(false);
  const [title, setTitle] = useState(props.storyContent?.title || '');
  const [subTitle, setSubTitle] = useState(props.storyContent?.subtitle || '');
  const [status, setStatus] = useState(props.storyContent?.status === PUBLISHED);
  const [image, setImage] = useState(props.storyContent?.coverImage || '');
  const [imageFile, setImageFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [optionalFields, setOptionalFields] = useState(props.storyContent?.bodyContent || []);
  // [{id: boxid?, textInput: string, type: text}, {id: boxid, type: report, reportInfo: report}]

  useEffect(() => {
    if (props.reports.length === 0) props.getReports();
  }, []);

  const addImage = (files) => {
    const file = files[0];
    if (file === undefined) return setImage('');

    setImage(URL.createObjectURL(file));
    return setImageFile(file);
  };

  const addNewTitleBox = () => {
    const newBoxId = `item-${optionalFields.length}`;
    const newTextBoxes = optionalFields;
    newTextBoxes.unshift({
      id: newBoxId,
      index: optionalFields.length,
      type: 'text',
      textInput: '',
      rows: 1,
    });

    setOptionalFields([...newTextBoxes]);
  };

  const removeTitleBox = (remId) => {
    const filteredTiltes = optionalFields.filter((elem) => (elem.id !== remId));
    setOptionalFields([...filteredTiltes]);
  };

  const changeValueFromOptionalText = (id, value, rows) => {
    const newTextBoxes = optionalFields;
    const newErrors = errors;
    if (value.length > 2000) {
      newErrors[id] = t('validations.storyTextField');
    } else {
      delete newErrors[id];
    }

    newTextBoxes.map((elem) => {
      const newElem = elem;
      if (newElem.id === id) {
        newElem.textInput = value;
        newElem.rows = rows;
      }
      return elem;
    });
    setErrors(newErrors);
    setOptionalFields([...newTextBoxes]);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.source.droppableId === 'reports-fields') {
      const reportIndex = result.source.index;
      const destinationIndex = result.destination.index;
      const selectedReport = props.reports[reportIndex];

      selectedReport.reportId = selectedReport.id;
      const reportInfo = {
        ...selectedReport, id: `report-${selectedReport.id}`, index: 9999, type: 'report',
      };
      if (!(optionalFields.find((elem) => elem.type === 'report' && elem.reportId === selectedReport.id))) {
        optionalFields.splice(destinationIndex, 0, reportInfo);
      }

      optionalFields.map((elem, i) => {
        const newElem = elem;
        newElem.index = i;
        return newElem;
      });

      setOptionalFields(optionalFields);
    } else {
      const items = reorder(optionalFields, result.source.index, result.destination.index);

      items.map((elem, i) => {
        const newElem = elem;
        newElem.index = i;
        return newElem;
      });

      setOptionalFields(items);
    }
  };

  const getItemStyle = (isDragging, existingStyle) => ({
    userSelect: 'none',
    margin: '0 0 16px 0',
    background: isDragging ? '#F2F8F7' : 'white',
    ...existingStyle,
  });

  const onSaveStory = async () => {
    const storyData = {
      ...props.storyContent,
      title,
      status: status ? PUBLISHED : UNPUBLISHED,
      subtitle: subTitle,
      coverImage: imageFile || image,
      bodyContent: optionalFields,
    };
    let result = {};
    if (props.storyContent) {
      result = await props.updateStory(storyData);
    } else {
      result = await props.createNewStory(storyData);
    }
    if (result.success) {
      props.onSubmitHandler();
      setErrors({});
    } else {
      setErrors(result.errors);
    }
  };

  const updateTitleField = (value) => {
    const newErrors = errors;
    if (value.length > 100) {
      newErrors.title = t('validations.storyTitle');
      setErrors(newErrors);
    } else {
      delete newErrors.title;
      setErrors(newErrors);
    }
    setTitle(value);
  };

  const updateSubTitleField = (value) => {
    const newErrors = errors;
    if (value.length > 100) {
      newErrors.title = t('validations.storySubtitle');
      setErrors(newErrors);
    } else {
      delete newErrors.subtitle;
      setErrors(newErrors);
    }
    setSubTitle(value);
  };

  return (
    <>
      <DragDropContext
        onDragEnd={onDragEnd}
      >
        <div className={clsx('reports-container__small-panel', {
          'reports-container__small-panel--hidden': !showFiltersPanel,
        })}
        >
          <FiltersPanel
            showPanel={showFiltersPanel}
            handleShow={() => setShowFiltersPanel(false)}
            createButton={false}
          />
        </div>
        <div className="reports-container__mid-panel">
          <ReportHeader
            filtersHandler={() => setShowFiltersPanel(true)}
            showFilters={showFiltersPanel}
            noButtons
          />
          <StoryReportsList reports={props.reports} getMoreReports={props.getReports} />
        </div>
        <div className="story-create__container whitepagescrollbar">
          <div className="story-create__header">
            <div className="story-create__header-toggle">
              <Toggle handleChange={() => { setStatus(!status); }} label={t('reportPublic')} inverse value={status} />
            </div>
            <Button
              secondary
              text={(
                <>
                  <CloseIcon />
                  {t('cancel')}
                </>
              )}
              medium
              handler={() => { props.onCloseHandler(); }}
            />
          </div>
          <div className="story-create__body">
            <div className="story-create__body-input-title">
              {t('coverImage')}
            </div>
            <StoryCoverImage
              image={image}
              addFile={addImage}
            />
            <div>
              <TextInput
                value={title}
                hasTitle={false}
                hasError={!!errors.title}
                errorDescription={errors.title}
                placeholder={t('storyTitlePlaceholder')}
                handleChange={(event) => { updateTitleField(event.target.value); }}
                customInputClass="story-create__body-titleinput"
              />
            </div>
            <div>
              <TextInput
                value={subTitle}
                hasTitle={false}
                hasError={!!errors.subtitle}
                errorDescription={errors.subtitle}
                placeholder={t('storySubtitlePlaceholder')}
                handleChange={(event) => { updateSubTitleField(event.target.value); }}
                customInputClass="story-create__body-subtitleinput"
              />
            </div>
            <Droppable droppableId="droppable-fields">
              {(provided) => (
                <>
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="story-create__body-optionalinputs"
                  >
                    {optionalFields.map((elem) => {
                      if (elem.type === 'text') {
                        return (
                          <Draggable draggableId={elem.id} key={elem.id} index={elem.index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style,
                                )}
                              >
                                <StoryOptionalTextField
                                  textInput={elem.textInput}
                                  hasError={!!errors[elem.id]}
                                  errorDescription={errors[elem.id]}
                                  key={elem.id}
                                  rows={elem.rows}
                                  onChange={(value, rows) => {
                                    changeValueFromOptionalText(elem.id, value, rows);
                                  }}
                                  editHandler={() => removeTitleBox(elem.id)}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      }
                      if (elem.type === 'report') {
                        return (
                          <Draggable draggableId={elem.id} key={elem.id} index={elem.index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style,
                                )}
                              >
                                <StoryReport
                                  dragged={snapshot.isDragging}
                                  report={elem}
                                  isEditable
                                  editHandler={() => removeTitleBox(elem.id)}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      }
                      return (<></>);
                    })}
                  </div>
                  {provided.placeholder}
                </>
              )}
            </Droppable>
            <div
              role="button"
              onKeyDown={addNewTitleBox}
              onClick={addNewTitleBox}
              tabIndex={0}
              className="story-create__body-addcontainer"
            >
              <span className="story-create__body-addcontainer-text">
                +
                {t('storyAddNew')}
              </span>
            </div>
            <div className="story-create__submit">
              <Button
                primary
                medium
                text={t('save')}
                handler={() => { onSaveStory(); }}
              />
            </div>
          </div>
        </div>
      </DragDropContext>
    </>
  );
}

const mapStateToProps = ({ reports }) => ({
  reports: reports.reports,
});

StoryForm.propTypes = {
  onCloseHandler: t.func.isRequired,
  onSubmitHandler: t.func.isRequired,
  getReports: t.func.isRequired,
  updateStory: t.func.isRequired,
  createNewStory: t.func.isRequired,
  storyContent: t.object.isRequired,
  reports: t.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(StoryForm);
