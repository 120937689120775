import React from 'react';
import t from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import '../../../../styles/index.scss';
import DynamicTagSelect from '../../../shared/DynamicTagSelect/DynamicTagSelect';
import { getRandomTint } from '../../../../utils';
import { findTranslation } from '../../../../utils/translationsUtils';

function ReportCategories(props) {
  const { t } = useTranslation();

  return (
    <div className={props.editMode ? '' : "report-categories-container" }>
      {!(props.editMode) && (
        <>
          {(props.groupTags.map((elem) => {
            let selectedValues = props?.selectedCategories[elem.id];
            if(typeof selectedValues === 'string') selectedValues = [selectedValues];
            let groupTagName = findTranslation(elem?.translations, props.currentLanguage);
            if(!groupTagName) groupTagName = findTranslation(elem?.translations, props.defaultLanguage);

            return (
              <div className="report-categories-element" key={`category-${elem.id}`}>
                <div className="report-categories-element-title">
                  {groupTagName}
                </div>
                <div className="report-categories-element-values">
                  {!selectedValues && (
                    <div className="report-categories-element-placeholder">
                      {t('noValues')}
                    </div>
                  )}
                  {selectedValues?.map((tax) => {
                    const taxonomy = props.tags.find(e => e.id === tax);
                    let label = findTranslation(taxonomy?.translations, props.currentLanguage);
                    if(!label) label = findTranslation(taxonomy?.translations, props.defaultLanguage);

                    return (
                      <div
                        className="report-categories-element-value"
                        key={tax}
                        style={{ backgroundColor: getRandomTint() }}
                      >
                        {label}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          }))}
        </>
      )}
      {(props.editMode) && (
        <>
          {(props.groupTags.map((elem) => {
            let title = findTranslation(elem?.translations, props.currentLanguage);
            if(!title) title = findTranslation(elem?.translations, props.defaultLanguage);
            const filtered_tags = props.tags.filter(e => e.group === elem.id && e.organization === props.organization);

            return (
              <div className="report-categories-element-select" key={`dynamic-element-${elem.id}`}>
                <DynamicTagSelect
                  title={title}
                  placeholder={title}
                  tagGroup={elem}
                  tagOptions={filtered_tags}
                  onChange={props.updateCategories}
                  selectedValues={props.selectedCategories[elem.id]}
                />
              </div>
            );
          }))}
        </>
      )}
    </div>
  );
}

const mapStateToProps = ({ tags, common }) => ({
  defaultLanguage: common.defaultLanguage,
  currentLanguage: common.currentLanguage,
  groupTags: tags.groupTags,
  tags: tags.tags,
});

ReportCategories.propTypes = {
  selectedCategories: t.object.isRequired,
  editMode: t.bool.isRequired,
  updateCategories: t.func.isRequired,
  defaultLanguage: t.string.isRequired,
  currentLanguage: t.string.isRequired,
  groupTags: t.array.isRequired,
  tags: t.array.isRequired,
};
export default connect(mapStateToProps, null)(ReportCategories);
