import { GET_MESSAGES } from '../types';
import { addNewAlert, closeLoader, getToken, openLoader } from './CommonActions';
import i18n from '../translations/i18n';
import {
  ALERT_ERROR, ALERT_SUCCESS, MESSAGE_SELECT_USERS, MESSAGE_SELECT_ROLES,
} from '../shared/consts';
import { endpoints } from './endpoints';

export const getMessages = () => async (dispatch, getState) => {
  const { admin } = getState();
  const token = await dispatch(getToken());
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    dispatch(openLoader());
    const response = await fetch(`${endpoints.getBroadcastedMessages(admin.selectedOrganizationId)}`, options);
    const { error, data, status } = await response.json();
    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      dispatch({ type: GET_MESSAGES, payload: { messages: data } });
    }
  } catch (e) {
    console.warn(e);
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const createNewMessage = (messagePayload) => async (dispatch, getState) => {
  const { admin } = getState();
  const token = await dispatch(getToken());

  const errors = {};
  if (messagePayload.subject === '') errors.subject = i18n.t('validations.required');
  if (messagePayload.subject.length > 30) errors.subject = i18n.t('validations.messageSubjectExceeded');

  if (messagePayload.message === '') errors.message = i18n.t('validations.required');
  if (messagePayload.message.length > 500) errors.message = i18n.t('validations.messageTextExceeded');

  if (Object.keys(errors).length > 0) {
    dispatch(addNewAlert(i18n.t('notifications.fieldValidations'), ALERT_ERROR));
    return { success: false, errors };
  }

  let recipients = 'everyone';

  switch (messagePayload.type) {
    case MESSAGE_SELECT_USERS: {
      recipients = [];
      messagePayload.recipients.map((recipient) => (
        recipients.push(recipient.id)
      ));
      break;
    }
    case MESSAGE_SELECT_ROLES: {
      recipients = messagePayload.recipients;
      break;
    }
  }

  const broadcastData = {
    'subject': messagePayload.subject,
    'content': messagePayload.message,
    'recipient': recipients
  };

  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(broadcastData),
  };

  try {
    dispatch(openLoader());
    const response = await fetch(
      endpoints.sendBroadcastMessage(admin.selectedOrganizationId), options,
    );
    const { error, status } = await response.json();
    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      dispatch(addNewAlert(i18n.t('notifications.messageSent'), ALERT_SUCCESS));
      dispatch(getMessages());
    }
  } catch (e) {
    console.warn(e);
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    return { success: false, errors: [] };
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};
