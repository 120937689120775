import React from 'react';
import { connect } from 'react-redux';
import mapDispatchToProps from '../../../actions';
import { intlFormat, parse } from 'date-fns';
import { REPORT_EVENT_DATE_PARSER } from '../../../shared/consts';
import {
  date_long_timezone,
  date_standard,
  date_standard_sameyear,
  date_standard_datetime,
  localizeDate,
} from '../../../utils/dateUtils';

const LocalDate = (props) => {
  if(!props.date) return null;
  let formattedDate;
  const parsedDate = (props.parser)? parse(props.date, props.parser, new Date) : parse(props.date, REPORT_EVENT_DATE_PARSER, new Date);
  const convertedDate = localizeDate(parsedDate);

  switch(props.format) {
    case 'full':
      //formattedDate = intlFormat(convertedDate, {...date_long_timezone, timeZone: props.timezone }, { locale: props.locale.code });
      formattedDate = intlFormat(convertedDate, {...date_long_timezone }, { locale: props.locale.code });
      break;

    case 'datetime':
      //formattedDate = intlFormat(convertedDate, {...date_standard_datetime, timeZone: props.timezone }, { locale: props.locale.code });
      formattedDate = intlFormat(convertedDate, {...date_standard_datetime }, { locale: props.locale.code });
      break;

    default:
      //formattedDate = intlFormat(convertedDate, {...date_standard, timeZone: props.timezone }, { locale: props.locale.code });
      formattedDate = intlFormat(convertedDate, {...date_standard }, { locale: props.locale.code });
      if (parsedDate.getFullYear() === new Date().getFullYear()) {
        //formattedDate = intlFormat(convertedDate, {...date_standard_sameyear, timeZone: props.timezone }, { locale: props.locale.code });
        formattedDate = intlFormat(convertedDate, {...date_standard_sameyear }, { locale: props.locale.code });
      }
  }

  return formattedDate;
};

const mapStateToProps = ({ common }) => ({
  locale: common.currentLocale,
  timezone: common.currentTimezone,
});

export default connect(mapStateToProps,  mapDispatchToProps)(LocalDate);
