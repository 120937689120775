import '../../../../styles/index.scss';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import types from 'prop-types';
import { useTranslation } from 'react-i18next';
import mapDispatchToProps from '../../../../actions/index';
import Button from '../../../shared/Button/Button';
import { ReactComponent as ClipIcon } from '../../../../assets/images/clip.svg';
import { ReactComponent as PersonIcon } from '../../../../assets/images/report_user.svg';
import { ReactComponent as WarningIcon } from '../../../../assets/images/red_warning.svg';
import TextInput from '../../../shared/TextInput/TextInput';
import AdminModal from '../../AdminModal/AdminModal';
import SelectBox from '../../../ui/SelectBox/SelectBox';

function getClassFromType(type) {
  if (type.startsWith('video')) {
    return 'report-activityfeed-info-files-element-video';
  }
  if (type.startsWith('audio')) {
    return 'report-activityfeed-info-files-element-audio';
  }
  if (type.startsWith('image')) {
    return 'report-activityfeed-info-files-element-image';
  }
  if (type === 'application/pdf') {
    return 'report-activityfeed-info-files-element-pdf';
  }
  return '';
}

function ReportActivityFeedSubmit(props) {
  const { t } = useTranslation();
  const URGENCY_STATES = [
    { text: t('urgency_low'), value: 'minor', key: 'minor' },
    { text: t('urgency_medium'), value: 'moderate', key: 'moderate' },
    { text: t('urgency_high'), value: 'critical', key: 'critical' },
  ];
  const [comment, setComment] = useState('');
  const [errors, setErrors] = useState({});
  const [userModal, setUserModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [urgencyModal, setUrgencyModal] = useState(false);
  const [assignee, setAssignee] = useState(null);
  const [urgency, setUrgency] = useState(null);
  const [files, setFiles] = useState([]);
  const [fileModal, setFileModal] = useState(false);

  const [assignmentEnabled, setAssignmentEnabled] = useState(Number(props?.options?.assignment_enabled?.value) || false);
  const [urgencyEnabled, setUrgencyEnabled] = useState(Number(props?.options?.urgency_enabled?.value) || false);
  const [lifecycleEnabled, setLifecycleEnabled] = useState(Number(props?.options?.lifecycle_enabled?.value) || false);

  useEffect(() => {
    setAssignmentEnabled(Number(props?.options?.assignment_enabled?.value));
  }, [props?.options?.assignment_enabled?.value]);

  useEffect(() => {
    setUrgencyEnabled(Number(props?.options?.urgency_enabled?.value));
  }, [props?.options?.urgency_enabled?.value]);

  useEffect(() => {
    setLifecycleEnabled(Number(props?.options?.lifecycle_enabled?.value));
  }, [props?.options?.lifecycle_enabled?.value]);

  useEffect(() => {
    if (props.selectedReport) {
      setUrgency(props.selectedReport.urgency);
      setAssignee(props.selectedReport.assignee);
    }
  }, [props.selectedReport]);

  const onUpdateAssignee = async () => {
    const reportData = {
      assignee: assignee,
    };
    await props.editReport(props.selectedReport.organization, props.selectedReport.id, reportData);
    setUserModal(false);
    setAssignee(null);
  };

  const onUpdateUrgency = async () => {
    const reportData = {
      urgency: urgency,
    };
    await props.editReport(props.selectedReport.organization, props.selectedReport.id, reportData);
    setUrgencyModal(false);
    setUrgency(null);
  };

  const onUploadFiles = (event) => {
    const { files } = event.target;
    if (files.length === 0) return;

    setFiles([...files]);
    setFileModal(true);
  };

  const onCommentReport = async () => {
    const commentData = {
      comment,
      media: files,
    };
    const response = await props.addComment(props.selectedReport.id, commentData);
    props.setIsReportClosed(false);

    if (response.success) {
      setErrors({});
      setComment('');
      setFiles([]);
    } else {
      setErrors(response.errors);
    }
    return true;
  };

  const onCloseReport = async () => {
    if(comment) await onCommentReport();
    await props.closeReport(props.selectedReport.organization, props.selectedReport.id);

    props.setIsReportClosed(true);
    setCloseModal(false);
    setAssignee(null);
  };

  const updateComments = (value) => {
    const newErrors = errors;

    if (value.length > 2000) {
      newErrors.comment = t('validations.reportCommentExceeded');
      setErrors(newErrors);
    } else if(value.length && props.isReportClosed) {
      newErrors.comment = t('validations.reportWillReopen');
      setErrors(newErrors);
    } else {
      delete newErrors.comment;
      setErrors(newErrors);
    }
    setComment(value);
  };

  return (
    <>
      <div className="report-activityfeed-submit">
        <div className="report-activityfeed-submit-box">
          <TextInput
            textarea
            placeholder={t('reportActivityPlaceholder')}
            hasTitle={false}
            value={comment}
            hasError={!!errors.comment}
            errorDescription={errors.comment}
            handleChange={(event) => { updateComments(event.target.value); }}
            customInputClass="report-activityfeed-submit-input"
          />
          <div className="report-activityfeed-submit-actions">
            <div className="report-activityfeed-submit-action-group">
              <div
                className="report-activityfeed-submit-action-button"
                role="button"
                tabIndex={-9999}
                onKeyDown={() => {
                  document.getElementById('activityUploadMedia').click();
                }}
                onClick={() => {
                  document.getElementById('activityUploadMedia').click();
                }}
              >
                <input
                  multiple
                  id="activityUploadMedia"
                  type="file"
                  className="report-activityfeed-submit-fileinput"
                  files={files}
                  onChange={(event) => { onUploadFiles(event); }}
                  accept="image/*, video/*, audio/*, .pdf"
                />
                <ClipIcon />
                {t('reportActivityUploadMedia')}
                {files.length > 0 && ` (${files.length})`}
              </div>
              { (!!assignmentEnabled && !props.isReportClosed) && (
                <div
                  className="report-activityfeed-submit-action-button"
                  role="button"
                  tabIndex={-9999}
                  onClick={() => setUserModal(true)}
                  onKeyDown={() => setUserModal(true)}
                >
                  <PersonIcon />
                  {t('reportActivityAssignUser')}
                </div>
              )}

              { (!!urgencyEnabled && !props.isReportClosed) && (
                <div
                  className="report-activityfeed-submit-action-button"
                  role="button"
                  tabIndex={-9999}
                  onClick={() => setUrgencyModal(true)}
                  onKeyDown={() => setUrgencyModal(true)}
                >
                  <WarningIcon />
                  {t('reportActivitySetUrgency')}
                </div>
              )}
            </div>
            <div className="report-activityfeed-submit-buttons-container">
              { (!!lifecycleEnabled && !props.isReportClosed) && (
                <div className="report-activityfeed-submit-close-button">
                  <Button text={t('closeAction')} secondary medium handler={() => setCloseModal(true)} />
                </div>
              )}
              <Button text={t('updateAction')} disabled={comment === ''} primary medium handler={() => onCommentReport()} />
            </div>
          </div>
        </div>
      </div>
      {!!assignmentEnabled && (
        <AdminModal
          hasTrigger={false}
          header={t('reportActivityAssignUser')}
          customModal="report-activityfeed-submit-modal report-activityfeed-submit-modal-mini"
          onClose={() => setUserModal(false)}
          open={userModal}
          scrolling={false}
          onOpen={() => setUserModal(true)}
        >
          <div>
            <SelectBox
              value={assignee}
              options={props.users.map((elem) => ({
                text: elem.username, key: elem.id, value: elem.id,
              }))}
              handleChange={(e, { value }) => setAssignee(value)}
              placeholder={t('chooseUser')}
            />
            <div className="report-activityfeed-submit-buttons">
              <Button text={t('cancel')} secondary medium handler={() => { setUserModal(false); setAssignee(null); }} />
              <Button text={t('save')} disabled={!assignee} primary medium handler={() => onUpdateAssignee()} />
            </div>
          </div>
        </AdminModal>
      )}
      {!!urgencyEnabled && (
        <AdminModal
          hasTrigger={false}
          customModal="report-activityfeed-submit-modal report-activityfeed-submit-modal-mini"
          header={t('reportActivitySetUrgency')}
          onClose={() => setUrgencyModal(false)}
          open={urgencyModal}
          scrolling={false}
          onOpen={() => setUrgencyModal(true)}
        >
          <div>
            <SelectBox
              value={urgency}
              options={URGENCY_STATES}
              handleChange={(e, { value }) => setUrgency(value)}
              placeholder={t('reportActivitySetUrgency')}
            />
            <div className="report-activityfeed-submit-buttons">
              <Button text={t('cancel')} secondary medium handler={() => setUrgencyModal(false)} />
              <Button text={t('save')} disabled={!urgency} primary medium handler={() => onUpdateUrgency()} />
            </div>
          </div>
        </AdminModal>
      )}
      {!!lifecycleEnabled && (
        <AdminModal
          hasTrigger={false}
          customModal="report-activityfeed-submit-modal report-activityfeed-submit-modal-mini"
          header={t('closeReport')}
          subtitle={t('closeReportMessage')}
          onClose={() => setCloseModal(false)}
          open={closeModal}
          scrolling={false}
          onOpen={() => setCloseModal(true)}
        >
          <div>
            <div className="report-activityfeed-submit-buttons">
              <Button text={t('cancel')} secondary medium handler={() => setCloseModal(false)} />
              <Button text={t('closeAction')} primary medium handler={() => onCloseReport()} />
            </div>
          </div>
        </AdminModal>
      )}
      <AdminModal
        hasTrigger={false}
        customModal="report-activityfeed-submit-modal"
        header={t('reportActivityUploadMedia')}
        subtitle={t('reportActivityUploadMediaMessage')}
        onClose={() => setFileModal(false)}
        open={fileModal}
        scrolling={false}
        onOpen={() => setFileModal(true)}
      >
        <div className="report-activityfeed-submit-files-container">
          {files.map((elem, i) => (
            <div key={i} className="report-activityfeed-submit-files-info">
              <div
                style={elem.type.startsWith('image') ? { backgroundImage: `url(${URL.createObjectURL(elem)})`, backgroundSize: 'cover' } : {}}
                className={`report-activityfeed-info-files-element ${getClassFromType(elem.type)}`}
                key={`report-activityfeed-submit-files-info-${elem.id}`}
              />
              {elem.name}
            </div>
          ))}
        </div>
        <div className="report-activityfeed-submit-buttons">
          <Button text={t('cancel')} secondary medium handler={() => setFileModal(false)} />
          <Button text={t('ok')} primary medium handler={() => setFileModal(false)} />
        </div>
      </AdminModal>
    </>
  );
}

const mapStateToProps = ({ admin, reports }) => ({
  users: admin.users,
  selectedReport: reports.selectedReport,
  options: admin.options,
});

ReportActivityFeedSubmit.propTypes = {
  users: types.array.isRequired,
  options: types.object.isRequired,
  selectedReport: types.object.isRequired,
  addComment: types.func.isRequired,
  editReport: types.func.isRequired,
  closeReport: types.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportActivityFeedSubmit);
