import '../../../styles/index.scss';
import React, { useEffect, useState } from 'react';
import t from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import DeleteModal from '../DeleteModal/DeleteModal';
import dashboardHoc from '../DashboardHoc/DashboardHoc';
import StoryForm from '../StoriesContainer/StoryForm/StoryForm';
import RoundedIcon from '../../shared/RoundedIcon/RoundedIcon';
import Button from '../../shared/Button/Button';
import LocalDate from '../../shared/LocalDate/LocalDate';
import { ReactComponent as DeleteIcon } from '../../../assets/images/delete.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/edit.svg';
import { ReactComponent as ShareIcon } from '../../../assets/images/share.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/images/download.svg';
import { ReactComponent as LeftArrowIcon } from '../../../assets/images/arrow-left.svg';
import StoryInfo from './StoryInfo/StoryInfo';
import Toggle from '../../ui/Toggle/Toggle';
import { isUserReader } from '../../../utils';
import mapDispatchToProps from '../../../actions/index';
import { SAVE_THE_DATE } from '../../../shared/consts';

const PUBLISHED = 'Published';
const UNPUBLISHED = 'Unpublished';

function StoryInfoContainer(props) {
  const [edit, setEdit] = useState(props.history?.location?.edit || false);
  const [selectedStory, setSelectedStory] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    const checkSelectStory = async () => {
      if (id === undefined) {
        props.history.push('/dashboard');
      }
      const selectedStory = await props.getSelectedStory(id);
      setSelectedStory(selectedStory);
    };
    checkSelectStory();
    if (props.stories.length === 0) {
      props.getStories();
    }
    document.title = 'Timby - View Story';
  }, [id, props.stories]);

  const changeStoryStatus = async () => {
    const storyData = {
      ...selectedStory,
      status: selectedStory.status === PUBLISHED ? UNPUBLISHED : PUBLISHED,
    };
    await props.updateStory(storyData);
  };

  if (!selectedStory) {
    return <></>;
  }

  if (edit) {
    return (
      <StoryForm
        storyContent={selectedStory}
        onCloseHandler={() => { setEdit(false); }}
        onSubmitHandler={() => { setEdit(false); }}
      />
    );
  }

  return (
    <div className="story-container">
      <div className="story-info-container-header">
        <div
          role="button"
          tabIndex={-9999}
          className="story-info-container-header-goback"
          onKeyDown={() => { props.history.push({ pathname: '/stories', from: props.history.location.pathname }); }}
          onClick={() => { props.history.push({ pathname: '/stories', from: props.history.location.pathname }); }}
        >
          <LeftArrowIcon />
          <span className="story-info-container-header-goback-text">{t('goBack')}</span>
        </div>
        <div className="story-info-container-header-buttons">
          <RoundedIcon hover border handler={() => {}} SvgComponent={ShareIcon} />
          <RoundedIcon hover border handler={() => {}} SvgComponent={DownloadIcon} />
          {!isUserReader(props.userInfo) && (
            <>
              <RoundedIcon
                hover
                border
                handler={() => { setEdit(true); }}
                SvgComponent={EditIcon}
              />
              <RoundedIcon
                hover
                border
                handler={() => { setDeleteModal(true); }}
                SvgComponent={DeleteIcon}
              />
              <Button text={selectedStory.status === PUBLISHED ? t('unpublish') : t('publish')} primary medium handler={() => changeStoryStatus()} />
            </>
          )}
        </div>
      </div>
      <div className="story-container__wrapper">
        <div className="story-info-container-body">
          <div className="story-info-container-body-publishcontainer">
            <div className="story-info-container-body-publishcontainer-publish">
              {selectedStory.status === PUBLISHED && (
                <>
                  <p className="story-info-container-body-publishcontainer-title">
                    { t('publishedOn') }
                  </p>
                  <p className="story-info-container-body-publishcontainer-publish-text">
                    {t('updated')}
                    {' '}
                    <LocalDate date={selectedStory.date} parser={SAVE_THE_DATE} />
                  </p>
                </>
              )}
            </div>
            <div className="story-info-container-body-publishcontainer-status">
              <p className="story-info-container-body-publishcontainer-title">
                {t('storyType')}
              </p>
              <Toggle disabled={isUserReader(props.userInfo)} handleChange={() => changeStoryStatus()} inverse label={t('reportPublic')} value={selectedStory.status === 'Published'} />
            </div>
          </div>
          <StoryInfo
            selectedStory={selectedStory}
            report={{
              id: 0,
              verified: true,
              new: true,
              title: 'Local farming in the area',
              description: 'Local residents resort to farming in order to make a livelihood for their families',
              date: 'Nov 20',
            }}
          />
        </div>
      </div>
      <DeleteModal
        onClose={() => setDeleteModal(false)}
        open={deleteModal}
        onOpen={() => setDeleteModal(true)}
        hasTrigger={false}
        header={t('deleteStory')}
        subtitle={t('deleteStoryDescription')}
        deleteAction={() => props.deleteStory(selectedStory.id)}
      />
    </div>
  );
}

const mapStateToProps = ({ user, stories }) => ({
  userInfo: user.userInfo,
  stories: stories.stories,
});

StoryInfoContainer.propTypes = {
  deleteStory: t.func.isRequired,
  updateStory: t.func.isRequired,
  getSelectedStory: t.func.isRequired,
  getStories: t.func.isRequired,
  userInfo: t.object.isRequired,
  stories: t.array.isRequired,
  history: t.object.isRequired,
};

export default dashboardHoc(connect(mapStateToProps, mapDispatchToProps)(StoryInfoContainer));
