import '../../../styles/index.scss';

function SplitView({
  leftContent,
  rightContent,
}) {
  return (
    <div className="split-view__wrapper">
      <div className="split-view__left">
        {leftContent}
      </div>
      <div className="split-view__right">
        {rightContent}
      </div>
    </div>
  );
}

export default SplitView;
