export default [
  {
    text: '+93',
    key: '+93',
    value: '+93',
  },
  {
    text: '+355',
    key: '+355',
    value: '+355',
  },
  {
    text: '+213',
    key: '+213',
    value: '+213',
  },
  {
    text: '+1684',
    key: '+1684',
    value: '+1684',
  },
  {
    text: '+376',
    key: '+376',
    value: '+376',
  },
  {
    text: '+244',
    key: '+244',
    value: '+244',
  },
  {
    text: '+1264',
    key: '+1264',
    value: '+1264',
  },
  {
    text: '+672',
    key: '+672',
    value: '+672',
  },
  {
    text: '+1268',
    key: '+1268',
    value: '+1268',
  },
  {
    text: '+54',
    key: '+54',
    value: '+54',
  },
  {
    text: '+374',
    key: '+374',
    value: '+374',
  },
  {
    text: '+297',
    key: '+297',
    value: '+297',
  },
  {
    text: '+61',
    key: '+61',
    value: '+61',
  },
  {
    text: '+43',
    key: '+43',
    value: '+43',
  },
  {
    text: '+994',
    key: '+994',
    value: '+994',
  },
  {
    text: '+1242',
    key: '+1242',
    value: '+1242',
  },
  {
    text: '+973',
    key: '+973',
    value: '+973',
  },
  {
    text: '+880',
    key: '+880',
    value: '+880',
  },
  {
    text: '+1246',
    key: '+1246',
    value: '+1246',
  },
  {
    text: '+375',
    key: '+375',
    value: '+375',
  },
  {
    text: '+32',
    key: '+32',
    value: '+32',
  },
  {
    text: '+501',
    key: '+501',
    value: '+501',
  },
  {
    text: '+229',
    key: '+229',
    value: '+229',
  },
  {
    text: '+1441',
    key: '+1441',
    value: '+1441',
  },
  {
    text: '+975',
    key: '+975',
    value: '+975',
  },
  {
    text: '+591',
    key: '+591',
    value: '+591',
  },
  {
    text: '+387',
    key: '+387',
    value: '+387',
  },
  {
    text: '+267',
    key: '+267',
    value: '+267',
  },
  {
    text: '+55',
    key: '+55',
    value: '+55',
  },
  {
    text: '+246',
    key: '+246',
    value: '+246',
  },
  {
    text: '+673',
    key: '+673',
    value: '+673',
  },
  {
    text: '+359',
    key: '+359',
    value: '+359',
  },
  {
    text: '+226',
    key: '+226',
    value: '+226',
  },
  {
    text: '+257',
    key: '+257',
    value: '+257',
  },
  {
    text: '+855',
    key: '+855',
    value: '+855',
  },
  {
    text: '+237',
    key: '+237',
    value: '+237',
  },
  {
    text: '+1',
    key: '+1',
    value: '+1',
  },
  {
    text: '+238',
    key: '+238',
    value: '+238',
  },
  {
    text: '+ 345',
    key: '+ 345',
    value: '+ 345',
  },
  {
    text: '+236',
    key: '+236',
    value: '+236',
  },
  {
    text: '+235',
    key: '+235',
    value: '+235',
  },
  {
    text: '+56',
    key: '+56',
    value: '+56',
  },
  {
    text: '+86',
    key: '+86',
    value: '+86',
  },
  {
    text: '+61',
    key: '+61',
    value: '+61',
  },
  {
    text: '+57',
    key: '+57',
    value: '+57',
  },
  {
    text: '+269',
    key: '+269',
    value: '+269',
  },
  {
    text: '+242',
    key: '+242',
    value: '+242',
  },
  {
    text: '+243',
    key: '+243',
    value: '+243',
  },
  {
    text: '+682',
    key: '+682',
    value: '+682',
  },
  {
    text: '+506',
    key: '+506',
    value: '+506',
  },
  {
    text: '+225',
    key: '+225',
    value: '+225',
  },
  {
    text: '+385',
    key: '+385',
    value: '+385',
  },
  {
    text: '+53',
    key: '+53',
    value: '+53',
  },
  {
    text: '+357',
    key: '+357',
    value: '+357',
  },
  {
    text: '+420',
    key: '+420',
    value: '+420',
  },
  {
    text: '+45',
    key: '+45',
    value: '+45',
  },
  {
    text: '+253',
    key: '+253',
    value: '+253',
  },
  {
    text: '+1767',
    key: '+1767',
    value: '+1767',
  },
  {
    text: '+1849',
    key: '+1849',
    value: '+1849',
  },
  {
    text: '+593',
    key: '+593',
    value: '+593',
  },
  {
    text: '+20',
    key: '+20',
    value: '+20',
  },
  {
    text: '+503',
    key: '+503',
    value: '+503',
  },
  {
    text: '+240',
    key: '+240',
    value: '+240',
  },
  {
    text: '+291',
    key: '+291',
    value: '+291',
  },
  {
    text: '+372',
    key: '+372',
    value: '+372',
  },
  {
    text: '+251',
    key: '+251',
    value: '+251',
  },
  {
    text: '+500',
    key: '+500',
    value: '+500',
  },
  {
    text: '+298',
    key: '+298',
    value: '+298',
  },
  {
    text: '+679',
    key: '+679',
    value: '+679',
  },
  {
    text: '+358',
    key: '+358',
    value: '+358',
  },
  {
    text: '+33',
    key: '+33',
    value: '+33',
  },
  {
    text: '+594',
    key: '+594',
    value: '+594',
  },
  {
    text: '+689',
    key: '+689',
    value: '+689',
  },
  {
    text: '+241',
    key: '+241',
    value: '+241',
  },
  {
    text: '+220',
    key: '+220',
    value: '+220',
  },
  {
    text: '+995',
    key: '+995',
    value: '+995',
  },
  {
    text: '+49',
    key: '+49',
    value: '+49',
  },
  {
    text: '+233',
    key: '+233',
    value: '+233',
  },
  {
    text: '+350',
    key: '+350',
    value: '+350',
  },
  {
    text: '+30',
    key: '+30',
    value: '+30',
  },
  {
    text: '+299',
    key: '+299',
    value: '+299',
  },
  {
    text: '+1473',
    key: '+1473',
    value: '+1473',
  },
  {
    text: '+590',
    key: '+590',
    value: '+590',
  },
  {
    text: '+1671',
    key: '+1671',
    value: '+1671',
  },
  {
    text: '+502',
    key: '+502',
    value: '+502',
  },
  {
    text: '+224',
    key: '+224',
    value: '+224',
  },
  {
    text: '+245',
    key: '+245',
    value: '+245',
  },
  {
    text: '+595',
    key: '+595',
    value: '+595',
  },
  {
    text: '+509',
    key: '+509',
    value: '+509',
  },
  {
    text: '+379',
    key: '+379',
    value: '+379',
  },
  {
    text: '+504',
    key: '+504',
    value: '+504',
  },
  {
    text: '+852',
    key: '+852',
    value: '+852',
  },
  {
    text: '+36',
    key: '+36',
    value: '+36',
  },
  {
    text: '+354',
    key: '+354',
    value: '+354',
  },
  {
    text: '+91',
    key: '+91',
    value: '+91',
  },
  {
    text: '+62',
    key: '+62',
    value: '+62',
  },
  {
    text: '+98',
    key: '+98',
    value: '+98',
  },
  {
    text: '+964',
    key: '+964',
    value: '+964',
  },
  {
    text: '+353',
    key: '+353',
    value: '+353',
  },
  {
    text: '+44',
    key: '+44',
    value: '+44',
  },
  {
    text: '+972',
    key: '+972',
    value: '+972',
  },
  {
    text: '+39',
    key: '+39',
    value: '+39',
  },
  {
    text: '+1876',
    key: '+1876',
    value: '+1876',
  },
  {
    text: '+81',
    key: '+81',
    value: '+81',
  },
  {
    text: '+44',
    key: '+44',
    value: '+44',
  },
  {
    text: '+962',
    key: '+962',
    value: '+962',
  },
  {
    text: '+77',
    key: '+77',
    value: '+77',
  },
  {
    text: '+254',
    key: '+254',
    value: '+254',
  },
  {
    text: '+686',
    key: '+686',
    value: '+686',
  },
  {
    text: '+850',
    key: '+850',
    value: '+850',
  },
  {
    text: '+82',
    key: '+82',
    value: '+82',
  },
  {
    text: '+965',
    key: '+965',
    value: '+965',
  },
  {
    text: '+996',
    key: '+996',
    value: '+996',
  },
  {
    text: '+856',
    key: '+856',
    value: '+856',
  },
  {
    text: '+371',
    key: '+371',
    value: '+371',
  },
  {
    text: '+961',
    key: '+961',
    value: '+961',
  },
  {
    text: '+266',
    key: '+266',
    value: '+266',
  },
  {
    text: '+231',
    key: '+231',
    value: '+231',
  },
  {
    text: '+218',
    key: '+218',
    value: '+218',
  },
  {
    text: '+423',
    key: '+423',
    value: '+423',
  },
  {
    text: '+370',
    key: '+370',
    value: '+370',
  },
  {
    text: '+352',
    key: '+352',
    value: '+352',
  },
  {
    text: '+853',
    key: '+853',
    value: '+853',
  },
  {
    text: '+389',
    key: '+389',
    value: '+389',
  },
  {
    text: '+261',
    key: '+261',
    value: '+261',
  },
  {
    text: '+265',
    key: '+265',
    value: '+265',
  },
  {
    text: '+60',
    key: '+60',
    value: '+60',
  },
  {
    text: '+960',
    key: '+960',
    value: '+960',
  },
  {
    text: '+223',
    key: '+223',
    value: '+223',
  },
  {
    text: '+356',
    key: '+356',
    value: '+356',
  },
  {
    text: '+692',
    key: '+692',
    value: '+692',
  },
  {
    text: '+596',
    key: '+596',
    value: '+596',
  },
  {
    text: '+222',
    key: '+222',
    value: '+222',
  },
  {
    text: '+230',
    key: '+230',
    value: '+230',
  },
  {
    text: '+262',
    key: '+262',
    value: '+262',
  },
  {
    text: '+52',
    key: '+52',
    value: '+52',
  },
  {
    text: '+691',
    key: '+691',
    value: '+691',
  },
  {
    text: '+373',
    key: '+373',
    value: '+373',
  },
  {
    text: '+377',
    key: '+377',
    value: '+377',
  },
  {
    text: '+976',
    key: '+976',
    value: '+976',
  },
  {
    text: '+382',
    key: '+382',
    value: '+382',
  },
  {
    text: '+1664',
    key: '+1664',
    value: '+1664',
  },
  {
    text: '+212',
    key: '+212',
    value: '+212',
  },
  {
    text: '+258',
    key: '+258',
    value: '+258',
  },
  {
    text: '+95',
    key: '+95',
    value: '+95',
  },
  {
    text: '+264',
    key: '+264',
    value: '+264',
  },
  {
    text: '+674',
    key: '+674',
    value: '+674',
  },
  {
    text: '+977',
    key: '+977',
    value: '+977',
  },
  {
    text: '+31',
    key: '+31',
    value: '+31',
  },
  {
    text: '+599',
    key: '+599',
    value: '+599',
  },
  {
    text: '+687',
    key: '+687',
    value: '+687',
  },
  {
    text: '+64',
    key: '+64',
    value: '+64',
  },
  {
    text: '+505',
    key: '+505',
    value: '+505',
  },
  {
    text: '+227',
    key: '+227',
    value: '+227',
  },
  {
    text: '+234',
    key: '+234',
    value: '+234',
  },
  {
    text: '+683',
    key: '+683',
    value: '+683',
  },
  {
    text: '+672',
    key: '+672',
    value: '+672',
  },
  {
    text: '+1670',
    key: '+1670',
    value: '+1670',
  },
  {
    text: '+47',
    key: '+47',
    value: '+47',
  },
  {
    text: '+968',
    key: '+968',
    value: '+968',
  },
  {
    text: '+92',
    key: '+92',
    value: '+92',
  },
  {
    text: '+680',
    key: '+680',
    value: '+680',
  },
  {
    text: '+970',
    key: '+970',
    value: '+970',
  },
  {
    text: '+507',
    key: '+507',
    value: '+507',
  },
  {
    text: '+675',
    key: '+675',
    value: '+675',
  },
  {
    text: '+595',
    key: '+595',
    value: '+595',
  },
  {
    text: '+51',
    key: '+51',
    value: '+51',
  },
  {
    text: '+63',
    key: '+63',
    value: '+63',
  },
  {
    text: '+872',
    key: '+872',
    value: '+872',
  },
  {
    text: '+48',
    key: '+48',
    value: '+48',
  },
  {
    text: '+351',
    key: '+351',
    value: '+351',
  },
  {
    text: '+1939',
    key: '+1939',
    value: '+1939',
  },
  {
    text: '+974',
    key: '+974',
    value: '+974',
  },
  {
    text: '+40',
    key: '+40',
    value: '+40',
  },
  {
    text: '+7',
    key: '+7',
    value: '+7',
  },
  {
    text: '+250',
    key: '+250',
    value: '+250',
  },
  {
    text: '+262',
    key: '+262',
    value: '+262',
  },
  {
    text: '+590',
    key: '+590',
    value: '+590',
  },
  {
    text: '+290',
    key: '+290',
    value: '+290',
  },
  {
    text: '+1869',
    key: '+1869',
    value: '+1869',
  },
  {
    text: '+1758',
    key: '+1758',
    value: '+1758',
  },
  {
    text: '+590',
    key: '+590',
    value: '+590',
  },
  {
    text: '+508',
    key: '+508',
    value: '+508',
  },
  {
    text: '+1784',
    key: '+1784',
    value: '+1784',
  },
  {
    text: '+685',
    key: '+685',
    value: '+685',
  },
  {
    text: '+378',
    key: '+378',
    value: '+378',
  },
  {
    text: '+239',
    key: '+239',
    value: '+239',
  },
  {
    text: '+966',
    key: '+966',
    value: '+966',
  },
  {
    text: '+221',
    key: '+221',
    value: '+221',
  },
  {
    text: '+381',
    key: '+381',
    value: '+381',
  },
  {
    text: '+248',
    key: '+248',
    value: '+248',
  },
  {
    text: '+232',
    key: '+232',
    value: '+232',
  },
  {
    text: '+65',
    key: '+65',
    value: '+65',
  },
  {
    text: '+421',
    key: '+421',
    value: '+421',
  },
  {
    text: '+386',
    key: '+386',
    value: '+386',
  },
  {
    text: '+677',
    key: '+677',
    value: '+677',
  },
  {
    text: '+252',
    key: '+252',
    value: '+252',
  },
  {
    text: '+27',
    key: '+27',
    value: '+27',
  },
  {
    text: '+211',
    key: '+211',
    value: '+211',
  },
  {
    text: '+500',
    key: '+500',
    value: '+500',
  },
  {
    text: '+34',
    key: '+34',
    value: '+34',
  },
  {
    text: '+94',
    key: '+94',
    value: '+94',
  },
  {
    text: '+249',
    key: '+249',
    value: '+249',
  },
  {
  },
  {
    text: '+47',
    key: '+47',
    value: '+47',
  },
  {
    text: '+268',
    key: '+268',
    value: '+268',
  },
  {
    text: '+46',
    key: '+46',
    value: '+46',
  },
  {
    text: '+41',
    key: '+41',
    value: '+41',
  },
  {
    text: '+963',
    key: '+963',
    value: '+963',
  },
  {
    text: '+886',
    key: '+886',
    value: '+886',
  },
  {
    text: '+992',
    key: '+992',
    value: '+992',
  },
  {
    text: '+255',
    key: '+255',
    value: '+255',
  },
  {
    text: '+66',
    key: '+66',
    value: '+66',
  },
  {
    text: '+670',
    key: '+670',
    value: '+670',
  },
  {
    text: '+228',
    key: '+228',
    value: '+228',
  },
  {
    text: '+690',
    key: '+690',
    value: '+690',
  },
  {
    text: '+676',
    key: '+676',
    value: '+676',
  },
  {
    text: '+1868',
    key: '+1868',
    value: '+1868',
  },
  {
    text: '+216',
    key: '+216',
    value: '+216',
  },
  {
    text: '+90',
    key: '+90',
    value: '+90',
  },
  {
    text: '+993',
    key: '+993',
    value: '+993',
  },
  {
    text: '+1649',
    key: '+1649',
    value: '+1649',
  },
  {
    text: '+688',
    key: '+688',
    value: '+688',
  },
  {
    text: '+256',
    key: '+256',
    value: '+256',
  },
  {
    text: '+380',
    key: '+380',
    value: '+380',
  },
  {
    text: '+971',
    key: '+971',
    value: '+971',
  },
  {
    text: '+44',
    key: '+44',
    value: '+44',
  },
  {
    text: '+1',
    key: '+1',
    value: '+1',
  },
  {
    text: '+598',
    key: '+598',
    value: '+598',
  },
  {
    text: '+998',
    key: '+998',
    value: '+998',
  },
  {
    text: '+678',
    key: '+678',
    value: '+678',
  },
  {
    text: '+58',
    key: '+58',
    value: '+58',
  },
  {
    text: '+84',
    key: '+84',
    value: '+84',
  },
  {
    text: '+1284',
    key: '+1284',
    value: '+1284',
  },
  {
    text: '+1340',
    key: '+1340',
    value: '+1340',
  },
  {
    text: '+681',
    key: '+681',
    value: '+681',
  },
  {
    text: '+967',
    key: '+967',
    value: '+967',
  },
  {
    text: '+260',
    key: '+260',
    value: '+260',
  },
  {
    text: '+263',
    key: '+263',
    value: '+263',
  },
];
