/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo } from 'react';
import t from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import UploadedFile from '../../ui/UploadedFile/UploadedFile';
import Button from '../Button/Button';
import DropIcon from '../../../assets/images/drop.png';
import ActiveDropIcon from '../../../assets/images/accepted_drop.png';

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '30px 0px',
  borderRadius: '12px',
  border: '1px dashed #E0E0E0',
};

const activeStyle = {
  border: '2px solid rgba(27, 135, 96, 1)',
  backgroundColor: '#F2F8F7',
};

const acceptStyle = {
  border: '2px solid rgba(27, 135, 96, 1)',
  backgroundColor: '#F2F8F7',
};

function MediaUpload({
  addFile, removeFile, files, acceptedFiles, title, subtitle,
}) {
  const { t } = useTranslation();

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    addFile(acceptedFiles);
  }, [files]);

  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: acceptedFiles, onDrop });

  const openDialog = () => {
    open();
  };

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? {} : {}),
  }), [
    isDragActive,
    isDragReject,
    isDragAccept,
  ]);

  return (
    <>
      <div className="create-report__media">
        <div
          {...getRootProps({ style })}
          onClick={() => {}}
          onKeyDown={() => {}}
          role="button"
          tabIndex={-9999}
        >
          <input {...getInputProps()} />
          <div className="layer-drop__icon">
            {isDragActive
              ? <img src={ActiveDropIcon} style={{ marginBottom: '20px' }} alt="active-drop" />
              : <img src={DropIcon} style={{ marginBottom: '20px' }} alt="drop" />}
          </div>
          <div className="layer-drop__header">
            {title}
          </div>
          <div className="layer-drop__subheader">
            {subtitle}
          </div>
          <Button text={t('reportBrowseMedia')} medium secondary handler={() => { openDialog(); }} />
        </div>
      </div>
      {
        files.length > 0
        && (
        <div className="create-report__media-uploaded">
          <div className="create-report__header">
            {t('reportUploadFiles')}
          </div>
          <div className="create-report__media-files">
            {files.map((file) => (
              <UploadedFile key={file.name} file={file} removeHandler={() => removeFile(file)} />
            ))}
          </div>
        </div>
        )
      }
    </>
  );
}

MediaUpload.propTypes = {
  addFile: t.func,
  removeFile: t.func,
  files: t.array,
  acceptedFiles: t.string,
  title: t.string,
  subtitle: t.string,
};

MediaUpload.defaultProps = {
  addFile: () => {},
  removeFile: () => {},
  files: [],
  acceptedFiles: 'image/*, pdf, audio/*, video/*, .pdf',
  title: '',
  subtitle: '',
};

export default MediaUpload;
