import {
  FETCHING_END, FETCHING_START,
} from '../types';

const initialState = {
  isFetching: false,
};

const loadingReducer = (state = initialState, action) => {
  switch (action.type){
    case FETCHING_START: 
      return { isFetching: true };
    case FETCHING_END: 
      return { isFetching: false};
    default: 
      return state;
  } 
};

export default loadingReducer;
