import '../../../styles/index.scss';
import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

function LinkRef({to, handler, text, disabled}) {
  return (
    <Link
      className={clsx('link-ref', {
        'link-ref-disabled': disabled,
      })}
      to={to}
      disabled={disabled}
      onClick={disabled ? () => {} : handler}
    >
      {text}
    </Link>
  );
}

export default LinkRef;
