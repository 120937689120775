import '../../../styles/index.scss';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dashboardHoc from '../DashboardHoc/DashboardHoc';
import FullHeader from '../../ui/FullHeader/FullHeader';
import mapDispatchToProps from '../../../actions/index';
import { isEmpty } from '../../../utils';
import AdminTabs from '../AdminTabs/AdminTabs';
import AdminTags from '../AdminTags/AdminTags';
import AdminUsersList from '../AdminUsersList/AdminUsersList';
import AdminOrganizationsList from '../AdminOrganizationsList/AdminOrganizationsList';
import AdminLayers from '../AdminLayers/AdminLayers';
import AdminExportReport from '../AdminExportReport/AdminExportReport';
import AdminSettings from '../AdminSettings/AdminSettings';
import OrganizationsMenu from '../OrganizationsMenu/OrganizationsMenu';

function AdminContainer(props) {
  const { t } = useTranslation();
  let location = useLocation();
  const [currentPage, setCurrentPage] = useState(location.pathname);
  const [activeTab, setActiveTab] = useState(null);
  const [showOrganizationsMenu, setShowOrganizationsMenu] = useState(false);
  const [organizationsEnabled, setOrganizationsEnabled] = useState(!!Number(props?.options?.organizations_shown?.value) || false);

  const pages = [
    'tags', 'users', 'organizations', 'layers', 'export-reports', 'settings'
  ];

  /* Effects */
  useEffect(() => {
    if (isEmpty(props?.options)) props.getOptions();
  }, [props]);

  useEffect(() => {
    setOrganizationsEnabled(!!Number(props?.options?.organizations_shown?.value));
  }, [props?.options?.organizations_shown?.value]);

  useEffect(() => {
    if(!!organizationsEnabled
      && !location.pathname.startsWith('/admin/organizations')
      && !location.pathname.startsWith('/admin/settings')
    ) {
      setShowOrganizationsMenu(true);
    }
  }, [organizationsEnabled, location.pathname]);

  useEffect(() => {
    setCurrentPage(location.pathname);
    setActiveTab(pages.find(el => currentPage.indexOf(el) !== -1));
  }, [location]);

  // Displaying Pages
  const getCurrentPanel = () => {
    switch (activeTab) {
      case 'users':
        return (<AdminUsersList/>);
      case 'organizations':
        return (<AdminOrganizationsList/>);
      case 'layers':
        return (<AdminLayers/>);
      case 'export-reports':
        return (<AdminExportReport/>);
      case 'settings':
        return (<AdminSettings currentPage={currentPage}/>);
      case 'tags':
      default:
        return (<AdminTags/>);
    }
  };

  return (
    <>
      <div className="admin-container__wrapper">
        <FullHeader title={t('admin')}>
          {showOrganizationsMenu && (
            <OrganizationsMenu />
          )}
        </FullHeader>

        <AdminTabs activeTab={activeTab} currentPage={currentPage}/>
        {getCurrentPanel()}
      </div>
    </>
  );
}

const mapStateToProps = ({ admin }) => ({
  selectedOrganizationId: admin.selectedOrganizationId,
  options: admin.options,
});
export default dashboardHoc(connect(mapStateToProps, mapDispatchToProps)(AdminContainer));
