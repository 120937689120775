import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isUserAdmin } from '../utils';

const AdminRoute = ({ component: Component, isLoggedIn, userInfo, is2FA, ...rest }) => {
  const routeComponent = (props) =>
    (isLoggedIn && is2FA && isUserAdmin(userInfo))? (
      <Component {...props}/>
    ) : (
      (isLoggedIn && is2FA) ? (
        <Redirect to={{ pathname: '/not-found', state: { from: props.location } }} />
      ) : (
        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      )
    );

  return <Route {...rest} render={routeComponent} />;
};

export default AdminRoute;
