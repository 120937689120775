import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import TRANSLATIONS_AR from './ar/translations';
import TRANSLATIONS_EN from './en/translations';
import TRANSLATIONS_ES from './es/translations';
import TRANSLATIONS_FR from './fr/translations';
import TRANSLATIONS_PT from './pt/translations';
import TRANSLATIONS_TA from './ta/translations';
import TRANSLATIONS_CN from './cn/translations';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      fr: {
        translation: TRANSLATIONS_FR,
      },
      es: {
        translation: TRANSLATIONS_ES,
      },
      pt: {
        translation: TRANSLATIONS_PT,
      },
      ta: {
        translation: TRANSLATIONS_TA,
      },
      cn: {
        translation: TRANSLATIONS_CN,
      },
      ar: {
        translation: TRANSLATIONS_AR,
      },
    },
    lng: 'en',
    fallbackLng: 'en',
    supportedLngs: ['en', 'fr', 'es', 'pt', 'ta', 'cn', 'ar'],
  });

export default i18n;
