import React from 'react'
import { Loader } from 'semantic-ui-react'

const Loading = () => (
  <div className='loading__wrapper'>
    <Loader active />
  </div>
);

export default Loading
