import {
  HANDSHAKE,
  UPDATE_DEFAULT_LANGUAGE,
  UPDATE_CURRENT_LANGUAGE,
  UPDATE_CURRENT_TIMEZONE,
} from '../types';

import enLocale from "date-fns/locale/en-US";
import Cookies from 'js-cookie';

const getUserLanguage = () => {
  return Cookies.get('language')? JSON.parse(Cookies.get('language')) : {
    language: 'en_US',
    locale: enLocale
  };
};

const initialState = {
  backgroundImage: '',
  quote: '',
  quote_author: '',
  languages: [],
  defaultLanguage: 'en_US',
  currentLanguage: getUserLanguage().language,
  currentLocale: getUserLanguage().locale,
  currentTimezone: 'Europe/London',
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDSHAKE: {
      return {
        ...state,
        backgroundImage: action.payload.backgroundImage,
        quote: action.payload.quote,
        quote_author: action.payload.quote_author,
        languages: action.payload.languages,
        currentLanguage: action.payload.currentLanguage.locale,
        defaultLanguage: action.payload.defaultLanguage.locale,
        currentTimezone: action.payload.timezone,
      };
    }
    case UPDATE_DEFAULT_LANGUAGE:
      return {
        ...state,
        defaultLanguage: action.payload.language,
      };
    case UPDATE_CURRENT_LANGUAGE:
      return {
        ...state,
        currentLanguage: action.payload.language,
        currentLocale: action.payload.locale,
      };
    case UPDATE_CURRENT_TIMEZONE:
      return {
        ...state,
        currentTimezone: action.payload.timezone,
      };
    default:
      return state;
  }
};

export default commonReducer;
