import '../../../../styles/index.scss';
import React, { useEffect, useState } from 'react';
import types from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import mapDispatchToProps from '../../../../actions/index';
import SelectBox from '../../../ui/SelectBox/SelectBox';
// import MessageCustomSelect from '../../MessageForm/MessageCustomSelect/MessageCustomSelect';
import { isEmpty, getRandomTint } from '../../../../utils';
import Button from '../../../shared/Button/Button';
import t from 'prop-types';

const AdminLanguages = (props) => {
  const { t } = useTranslation();
  const [selectedAppLang, setSelectedAppLang] = useState('en_US');
  const [enabledLanguages, setEnabledLanguages] = useState([]);
  const [availableLanguages, setAvailableLanguages] = useState([]);

  useEffect(() => {
    document.title = 'Timby - Languages Settings';
    if (isEmpty(props?.options)) props.getOptions();
  }, []);

  // Get site language as default
  useEffect(() => {
    setSelectedAppLang(props?.options?.default_language?.value);
  }, [props?.options?.default_language?.value]);

  // fill in available languages
  useEffect(() => {
    if (props.languages) {
      setEnabledLanguages(props.languages.map((lang) => ({
        value: lang.locale, text: lang.name, id: lang.locale, color: getRandomTint(),
      })));
      const formattedLanguages = props.languages.map((lang) => ({
        value: lang.locale, text: lang.name, key: lang.locale, id: lang.locale,
      }));
      setAvailableLanguages(formattedLanguages);
    }
  }, [props.languages]);

  const handleUpdateOptions = async () => {
    const data = {
      options: {
        default_language: {
          ...props.options.default_language,
          value: selectedAppLang,
        }
      }
    };

    const result = await props.updateOptions(data);
    if (result.success) props.updateDefaultLanguage(selectedAppLang);
    if (result.errors) setErrors(result.errors);
  };

  return (
      <div className="admin-settings-section admin-languages">
        <div className="admin-settings-section__header">
          <h1 className="admin-settings-section__header-title">
            {t('languages')}
          </h1>
        </div>
        <div className="admin-settings__box">
          <div className="admin-settings__element">
            <SelectBox
                placeholder="Select Default Language"
                title={t('defaultLanguage')}
                value={selectedAppLang}
                options={availableLanguages}
                handleChange={(e, { value }) => setSelectedAppLang(value)}
            />
          </div>
          <div className="admin-settings__element">
            <div className="select-box enabled-languages-box">
              <div className="text-input__label">{t('enabledLanguages')}</div>
              <div className="tags">
              {enabledLanguages.map(e => (
                <span key={e.value} className="tag" style={{'backgroundColor': e.color}}>{e.text}</span>
              ))}
              </div>
              {/*
              <MessageCustomSelect
                  options={availableLanguages}
                  placeholder={t('selectLanguages')}
                  value={enabledLanguages}
                  selectedOptions={enabledLanguages}
                  changeSelectedOptions={setEnabledLanguages}
              />
              */}
            </div>
          </div>
        </div>

        <div className='admin-settings-save'>
          <Button text={t('save')} primary medium handler={() => handleUpdateOptions()} />
        </div>

      </div>
  );
};

const mapStateToProps = ({ common, admin }) => ({
  options: admin.options,
  languages: common.languages,
});

AdminLanguages.displayName = 'AdminLanguages';

AdminLanguages.propTypes = {
  languages: types.array.isRequired,
  options: t.object,
  getOptions: t.func,
  updateOptions: t.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLanguages);
