import {
  GET_ROLES, GET_ORGANIZATIONS,
  GET_USERS, ADD_USER, SELECT_USER, UNSELECT_USER, UPDATE_USER, DELETE_USER,
  CREATE_ORGANIZATION, UPDATE_ORGANIZATION, DELETE_ORGANIZATION,
  UPDATE_SELECTED_ORGANIZATION, GET_ORGANIZATION_LAYERS,
  GET_OPTIONS, UPDATE_OPTIONS,
} from '../types';
import i18n from '../translations/i18n';
import { endpoints } from './endpoints';
import {
  addNewAlert, closeLoader, openLoader, getToken,
} from './CommonActions';
import { getPasswordErrors } from './UserActions';
import { findOption } from '../utils';
import {
  ALERT_ERROR, ALERT_SUCCESS, DOMAINS_REGEX,
} from '../shared/consts';

const LAYER_MAX_LENGTH = 20;

export const convertPasswordErrorsIntoText = (errors) => {
  const converter = {
    notEqual: i18n.t('notEqual'),
    minLength: i18n.t('passwordReq1'),
    upperCaseRequired: i18n.t('passwordReq2'),
    downCaseRequired: i18n.t('passwordReq5'),
    numberRequired: i18n.t('passwordReq3'),
    specialCharRequired: i18n.t('passwordReq4'),
    maxLength: 'Password exceeded max characters',
  };
  let text = '';
  errors.map((elem) => {
    if (converter[elem]) text += `${converter[elem]}<br/>`;
    return null;
  });

  return text;
};

export const getUsers = () => async (dispatch) => {
  try {
    const token = await dispatch(getToken());
    dispatch(openLoader());

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(endpoints.getUsers, options,);
    const { error, data, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      dispatch({ type: GET_USERS, payload: { users: data } });
    }

  } catch {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const getOrganizationUsers = (orgId) => async (dispatch) => {
  try {
    const token = await dispatch(getToken());
    dispatch(openLoader());

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(endpoints.getOrganizationUsers(orgId), options);
    const { error, data, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
      return false;
    } else {
      return data;
    }

  } catch {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};


export const getUser = (uid) => async (dispatch) => {
  try {
    const token = await dispatch(getToken());
    dispatch(openLoader());

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(endpoints.getUser(uid), options,);
    const { error, data, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      dispatch({ type: SELECT_USER, payload: data });
    }

  } catch {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const updateUser = (user, formData) => async (dispatch, getState) => {
  const token = await dispatch(getToken());
  const { admin } = getState();
  const errors = {};

  // Validation
  if (formData.firstname && formData.firstname.length > 40) errors.firstname = i18n.t('validations.firstNameExceeded');
  if (formData.lastname && formData.lastname.length > 40) errors.lastname = i18n.t('validations.lastNameExceeded');

  if (formData.password) {
    const errorsResult = dispatch(getPasswordErrors(formData.password, formData.password, false));
    if (errorsResult.success) {
      dispatch(addNewAlert(i18n.t('notifications.passwordRequirements'), ALERT_ERROR));
      errors.password = convertPasswordErrorsIntoText(errorsResult.errors);
    }
  }

  const role = admin.roles.find(e => e.id === formData.role_id);
  if (role.name !== 'Mobile' && !formData.email) errors.email = i18n.t('validations.required');
  if(formData.secondfactor === 'email' && !formData.email) errors.email = i18n.t('validations.required');

  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (formData.email && !emailRegex.exec(formData.email)) {
    errors.email = i18n.t('validations.invalidEmail');
  }

  if (Object.keys(errors).length > 0) {
    dispatch(addNewAlert(i18n.t('notifications.fieldValidations'), ALERT_ERROR));
    return { success: false, errors };
  }

  // Setup for posting data
  let data = {};
  if(formData.password) data.password = formData.password;
  if(formData.organizations) data.organizations = formData.organizations;
  if(formData.role_id !== user.role.id) data.role_id = formData.role_id;

  // don't send orgs if role === admin
  if (role.name === 'Administrator') delete data.organizations;

  let userOptions = {};
  if(formData.firstname !== findOption('firstname', user.options)) userOptions.firstname = formData.firstname;
  if(formData.lastname !== findOption('lastname', user.options)) userOptions.lastname = formData.lastname;
  if(formData.email !== findOption('email', user.options)) userOptions.email = formData.email;
  if(formData.enable2FA !== (findOption('secondfactor', user.options) === 'email')) {
    userOptions.secondfactor = formData.enable2FA ? 'email' : 'none';
  }
  if(Object.entries(userOptions).length) data.options = userOptions;

  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.editUser(user.id), options);
    const { userData, error, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      dispatch(addNewAlert(i18n.t('notifications.userUpdateSuccess'), ALERT_SUCCESS));
      dispatch({ type: UPDATE_USER, payload: { userData: userData }, });
    }
  } catch {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const deleteUser = (uid) => async (dispatch) => {
  const token = await dispatch(getToken());

  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.editUser(uid), options);
    const { error, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
      return { success: false };
    } else {
      dispatch(addNewAlert(i18n.t('notifications.userDeletedSuccess'), ALERT_SUCCESS));
      dispatch({ type: DELETE_USER, payload: { userId: uid }, });
      dispatch(getUsers());
    }
  } catch {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    return { success: false };
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const createUser = (userData) => async (dispatch, getState) => {
  const token = await dispatch(getToken());
  const { admin } = getState();
  const errors = {};

  // Validations
  const errorsResult = dispatch(getPasswordErrors(userData.password, userData.password, false));
  if (errorsResult.success) {
    errors.password = convertPasswordErrorsIntoText(errorsResult.errors);
  }

  if (userData.organizations.length === 0) errors.organizations = i18n.t('validations.required');
  if (!userData.role_id) errors.role = i18n.t('validations.required');
  if (!userData.username) errors.username = i18n.t('validations.required');

  if (userData.username && userData.username.length > 50) {
    errors.username = (errors.username || '') + i18n.t('validations.usernameExceeded') + '<br />';
  }
  const usernameRegex = /^[a-zA-z0-9_.-]+$/i;
  if (userData.username && !usernameRegex.exec(userData.username)) {
    errors.username = (errors.username || '') + i18n.t('validations.usernameAlphaNumber') + '<br />';
  }

  if (userData.firstname && userData.firstname.length > 40) errors.firstname = i18n.t('validations.firstNameExceeded');
  if (userData.lastname && userData.lastname.length > 40) errors.lastname = i18n.t('validations.lastNameExceeded');

  const role = admin.roles.find(e => e.id === userData.role_id);
  if (role.name !== 'Mobile' && !userData.email) errors.email = i18n.t('validations.required');
  if(userData.secondfactor === 'email' && !userData.email) errors.email = i18n.t('validations.required');

  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (userData.email && !emailRegex.exec(userData.email)) {
    errors.email = i18n.t('validations.invalidEmail');
  }

  userData.options = {};
  if(userData.firstname) userData.options.firstname = userData.firstname;
  if(userData.lastname) userData.options.lastname = userData.lastname;
  if(userData.email) userData.options.email = userData.email;
  if(userData.secondfactor) userData.options.secondfactor = userData.secondfactor;

  const data = {
    'username': userData.username,
    'password': userData.password,
    'role_id': userData.role_id,
    'organizations': userData.organizations,
    'options': userData.options,
  };

  // don't send orgs if role === admin
  if (role.name === 'Administrator') delete data.organizations;

  if (Object.keys(errors).length > 0) {
    dispatch(addNewAlert(i18n.t('notifications.fieldValidations'), ALERT_ERROR));
    return { success: false, errors };
  }
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.createUser, options);
    const { userData, error, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      dispatch(addNewAlert(i18n.t('notifications.userCreatedSuccess'), ALERT_SUCCESS));
      dispatch({ type: ADD_USER, payload: { user: userData } });
      dispatch(getUsers());
    }
  } catch (e) {
    console.warn(e);
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const getOrganizations = () => async (dispatch) => {
  try {
    const token = await dispatch(getToken());

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    dispatch(openLoader());

    const response = await fetch(endpoints.getOrganizations, options);
    const { error, data, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      dispatch({ type: GET_ORGANIZATIONS, payload: { organizations: data } });
    }
  } catch {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return true;
};

export const getRoles = () => async (dispatch) => {
  try {
    dispatch(openLoader());
    const token = await dispatch(getToken());

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(endpoints.getRoles, options);
    const { error, data, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      dispatch({ type: GET_ROLES, payload: { roles: data } });
    }

  } catch {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return true;
};

export const createOrganization = (organizationData) => async (dispatch) => {
  const token = await dispatch(getToken());

  if (organizationData.name === '') return { success: false, errors: { name: i18n.t('validations.required') } };
  if (organizationData.name.length > 60) return { success: false, errors: { name: i18n.t('validations.organizationNameExceeded') } };
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(organizationData),
  };

  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.createOrganization, options);
    const { error, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      dispatch({ type: CREATE_ORGANIZATION, payload: { organization: organizationData } });
      dispatch(getOrganizations());
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const updateSelectedOrganization = (organizationId) => (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_ORGANIZATION,
    payload: { selectedOrganizationId: organizationId },
  });

  dispatch(unselectUser());
};

export const unselectUser = () => (dispatch) => {
  dispatch({ type: UNSELECT_USER });
};

export const updateOrganization = (organizationId, organizationData) => async (dispatch) => {
  const token = await dispatch(getToken());

  if (organizationData.name === '') return { success: false, errors: { name: i18n.t('validations.required') } };
  if (organizationData.name.length > 60) return { success: false, errors: { name: i18n.t('validations.organizationNameExceeded') } };

  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(organizationData),
  };

  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.organization(organizationId), options);
    const { error, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      dispatch({ type: UPDATE_ORGANIZATION, payload: { organization: organizationData } });
      dispatch(getOrganizations());
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

// This should never be called.
export const deleteOrganization = (organizationId) => async (dispatch) => {
  const token = await dispatch(getToken());

  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.organization(organizationId), options);
    const { error, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
      return { success: false };
    } else {
      dispatch({ type: DELETE_ORGANIZATION });
      dispatch(addNewAlert(i18n.t('notifications.organisationDelete'), ALERT_SUCCESS));
      dispatch(getOrganizations());
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    return { success: false };
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};


export const getOptions = () => async (dispatch) => {
  const token = await dispatch(getToken());
  let endpoint = endpoints.options;

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await fetch(endpoint, options);
    const { data, status } = await response.json();
    if (status === 200) {
      const formattedOptions = data.reduce((a, v) => ({ ...a, [v.name]: v }), {});
      dispatch({ type: GET_OPTIONS, payload: formattedOptions });
      return formattedOptions;
    }
  } catch (e) {
    console.log(e);
    return false;
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const updateOptions = (optionsData) => async (dispatch) => {
  const token = await dispatch(getToken());

  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(optionsData),
  };

  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.options, options,);
    const { error, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      dispatch({
        type: UPDATE_OPTIONS,
        payload: optionsData,
      });
      dispatch(addNewAlert(i18n.t('notifications.optionsSaved'), ALERT_SUCCESS));
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const getOrganizationLayers = () => async (dispatch, getState) => {
  const { admin } = getState();
  const token = await dispatch(getToken());
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.getLayers(admin.selectedOrganizationId), options);
    const { error, data, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      dispatch({ type: GET_ORGANIZATION_LAYERS, payload: { layers: data || [] } });
    }
  } catch {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
  } finally {
    dispatch(closeLoader());
  }

  return true;
};

export const validateLayerTranslations = (layerData, errors) => async (dispatch, getState) => {
  const store = getState();
  const { common } = store;
  const { languages } = common;

  const newErrors = errors;
  Object.keys(layerData.name).map((elem) => {
    if (!layerData.name[elem]) newErrors[elem] = i18n.t('validations.required');
    return null;
  });

  languages.map((elem) => {
    if (Object.keys(layerData.name).includes(elem.locale)) return null;

    newErrors[elem.locale] = i18n.t('validations.required');
    return null;
  });

  Object.keys(layerData.name).map((key) => {
    if (layerData.name[key].length > LAYER_MAX_LENGTH) {
      newErrors[key] = i18n.t('validations.layerNameExceeded');
    }
    return null;
  });

  return newErrors;
};

export const validateLayerData = async (layerData, errors) => {
  const newErrors = errors;

  if(layerData.type === 'raster') {
    if (!layerData.url) {
      newErrors.raster = i18n.t('validations.required');
    } else {
      if (!layerData.url.startsWith('https://')) newErrors.raster = (newErrors.raster || '') + i18n.t('validations.rasterUrlHttps') + '<br />';
      const domainCheck = layerData.url.match(DOMAINS_REGEX) == null;
      if (domainCheck || !layerData.url.includes('/{z}/{x}/{y}')) {
        newErrors.raster = (newErrors.raster || '') + i18n.t('validations.rasterUrlInvalid') + '<br />';
      }

      if (!newErrors.raster) {
        const testUrl = layerData.url.replace('{y}', 0).replace('{z}', 0).replace('{x}', 0);
        try {
          const testResponse = await fetch(testUrl, { method: 'GET' });
          const { status: urlStatus } = testResponse;
          if (urlStatus !== 200) newErrors.raster = (newErrors.raster || '') + i18n.t('validations.rasterUrlUnable');
        } catch (e) {
          newErrors.raster = (newErrors.raster || '') + i18n.t('validations.rasterUrlUnable');
        }
      }
    }
  } else if (layerData.type === 'vector') {
    if (!layerData.tileset.startsWith('mapbox://')) newErrors.vectorTileset = i18n.t('validations.vectorTilesetNotMapbox');
    if (!layerData.tileset) newErrors.vectorTileset = i18n.t('validations.required');
    if (!layerData.layername) newErrors.vectorLayerName = i18n.t('validations.required');
    if (!layerData.geometry) newErrors.vectorGeometry = i18n.t('validations.required');
  }

  return newErrors;
};

export const createLayer = (layerData) => async (dispatch, getState) => {
  const { admin } = getState();
  const token = await dispatch(getToken());

  dispatch(openLoader());

  let errors = {};
  errors = await dispatch(validateLayerTranslations(layerData, errors));
  errors = await validateLayerData(layerData, errors);

  if (Object.keys(errors).length > 0) {
    dispatch(closeLoader());
    return { success: false, errors };
  }

  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(layerData),
  };

  try {
    dispatch(openLoader());
    const response = await fetch(endpoints.createLayer(admin.selectedOrganizationId), options);
    const { error, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      dispatch(getOrganizationLayers(admin.selectedOrganizationId));
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    return { success: false, errors: {} };
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const updateLayer = (layerId, layerData) => async (dispatch, getState) => {
  const { admin } = getState();
  const token = await dispatch(getToken());
  dispatch(openLoader());

  let errors = {};
  errors = await dispatch(validateLayerTranslations(layerData, errors));
  errors = await validateLayerData(layerData, errors);

  if (Object.keys(errors).length > 0) {
    dispatch(closeLoader());
    return { success: false, errors };
  }

  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(layerData),
  };

  try {
    dispatch(openLoader());
    const response = await fetch(
      endpoints.alterLayer(admin.selectedOrganizationId, layerId), options,
    );
    const { error, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
    } else {
      dispatch(getOrganizationLayers(admin.selectedOrganizationId));
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    return { success: false, errors: {} };
  } finally {
    dispatch(closeLoader());
  }

  return { success: true };
};

export const deleteLayer = (layerId) => async (dispatch, getState) => {
  const { admin } = getState();
  const token = await dispatch(getToken());

  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    dispatch(openLoader());
    const response = await fetch(
      endpoints.alterLayer(admin.selectedOrganizationId, layerId), options,
    );
    const { error, status } = await response.json();

    if (status !== 200 && error) {
      const message = i18n.t(['api.'+error.type, 'api.ERROR']);
      dispatch(addNewAlert(message, ALERT_ERROR));
      return false;
    } else {
      dispatch(getOrganizationLayers(admin.selectedOrganizationId));
    }
  } catch (e) {
    dispatch(addNewAlert(i18n.t('notifications.error'), ALERT_ERROR));
    return false;
  } finally {
    dispatch(closeLoader());
  }

  return true;
};
