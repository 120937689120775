import '../../../styles/index.scss';
import {Popup} from 'react-mapbox-gl';

import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import { ReactComponent as ViewIcon } from '../../../assets/images/open.svg';
import RoundedIcon from '../../shared/RoundedIcon/RoundedIcon';
import React from "react";
import LocalDate from '../../shared/LocalDate/LocalDate';

function MapPopup({
  coordinates,
  title,
  description,
  author,
  date,
  media,
  viewReport,
  closePopup,
}) {

  const image = media.filter(e => e.type === 'image').length;
  const video = media.filter(e => e.type === 'video').length;
  const audio = media.filter(e => e.type === 'audio').length;
  const document = media.filter(e => e.type === 'document').length;

  const mainImage = (image)? media.filter(e => e.type === 'image')[0].src : false;

  return (
    <Popup
      coordinates={coordinates}
      closeOnClick
      onClick={() => {}}
      anchor={'center'}
      offset={{
        "bottom-left": [12, -38],
        "bottom": [0, -38],
        "bottom-right": [-12, -38],
      }}
    >
      <div className="map-popup__wrapper">
        <div className='map-popup__close'>
          <RoundedIcon
              SvgComponent={ViewIcon}
              hover
              border
              iconColor={'#333333'}
              small
              handler={() => viewReport()}
          />
          <RoundedIcon
            SvgComponent={CloseIcon}
            hover
            border
            iconColor={'#333333'}
            small
            handler={() => closePopup()}
          />
        </div>
        <div className="map-popup__main-image">
          {mainImage && (
              <img src={mainImage} alt="" />
          )}
        </div>
        <div className="map-popup__content">
          <div className="map-popup__content-title">
            {title}
          </div>
          <div className="map-popup__content-description">
            {description}
          </div>
          <div className="map-popup__author-container">
            <div className="map-popup__author-container--wrapper">
              <div className="map-popup__author-label">
                {'Date reported'}
              </div>
              <div className="map-popup__author-value">
                <LocalDate date={date} />
              </div>
            </div>
            <div className="map-popup__author-container--wrapper">
              <div className="map-popup__author-label">
                {'Author'}
              </div>
              <div className="map-popup__author-value">
                {author}
              </div>
            </div>
          </div>
          <div className="map-popup__assets-container">
            {image > 0 &&
              <div className="map-popup__assets-element">
              <div className="map-popup__assets-element-image map-popup__assets-element-image--image" />
                <div className="map-popup__assets-element-value">
                  {image}
                </div>
              </div>
            }
            {video > 0 &&
              <div className="map-popup__assets-element">
              <div className="map-popup__assets-element-image map-popup__assets-element-image--video"/>
                <div className="map-popup__assets-element-value">
                  {video}
                </div>
              </div>
            }
            {audio > 0 &&
              <div className="map-popup__assets-element">
              <div className="map-popup__assets-element-image map-popup__assets-element-image--audio" />
                <div className="map-popup__assets-element-value">
                  {audio}
                </div>
              </div>
            }
            {document > 0 &&
              <div className="map-popup__assets-element">
              <div className="map-popup__assets-element-image map-popup__assets-element-image--document" />
                <div className="map-popup__assets-element-value">
                  {document}
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </Popup>
  );
}

export default MapPopup;
