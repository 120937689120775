import '../../../styles/index.scss';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Button from '../../shared/Button/Button';
import mapDispatchToProps from '../../../actions/index';
import TextInput from '../../shared/TextInput/TextInput';

const AdminOrganizationContent = (props) => {
  const { t } = useTranslation();
  const { organization, edit = true, onClose } = props;
  const [name, setName] = useState(organization?.name);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setName(organization?.name || '');
  }, [organization]);

  const handleUpdateOrganization = async () => {
    const organizationData = {
      name,
    };

    const result = await props.updateOrganization(organization.id, organizationData);
    if (result.success) {
      onClose();
    } else {
      setErrors(result.errors);
    }
  };

  const handleCreateOrganization = async () => {
    const organizationData = {
      name,
    };
    const result = await props.createOrganization(organizationData);
    if (result.success) {
      onClose();
    } else {
      setErrors(result.errors);
    }
  };

  const updateOrganizationName = (value) => {
    const newErrors = errors;
    if (value.length > 60) {
      newErrors.name = t('validations.organizationNameExceeded');
    } else {
      delete newErrors.name;
    }

    setErrors(newErrors);
    setName(value);
  };

  return (
    <div className={clsx('organization-list-content__wrapper', {
      'organization-list-content__wrapper--create': !edit,
    })}
    >
      <TextInput
        title={t('name')}
        placeholder="Enter Organization Name"
        value={name}
        hasError={!!(Object.keys(errors).length)}
        errorDescription={errors.name}
        handleChange={(event) => updateOrganizationName(event.target.value)}
      />
      <div className={clsx('organization-list-content__actions', {
        'organization-list-content__actions--create': !edit,
      })}
      >
        {edit && (
          <div className="organization-list-content__actions-button">
            <Button
              text={t('save')}
              primary
              medium
              handler={() => handleUpdateOrganization()}
            />
          </div>
        )}

      </div>
      {!edit
        && (
        <div className="admin-modal__actions">
          <Button text={t('cancel')} secondary medium handler={onClose} />
          <Button text={t('save')} primary medium handler={() => handleCreateOrganization()} />
        </div>
        )}
    </div>
  );
};

export default connect(null, mapDispatchToProps)(AdminOrganizationContent);
