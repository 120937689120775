import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextArea } from 'semantic-ui-react';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/delete.svg';
import RoundedIcon from '../../../shared/RoundedIcon/RoundedIcon';

const MIN_ROW = 1;

function StoryOptionalTextField({
  textInput, onChange, rows, editHandler, hasError, errorDescription,
}) {
  const { t } = useTranslation();
  const handleChange = (event) => {
    const textareaLineHeight = 26;

    event.target.rows = 1;

    const currentRows = (event.target.scrollHeight / textareaLineHeight);

    event.target.rows = currentRows;

    onChange(event.target.value, Math.round(currentRows));
  };

  return (
    <div className="story-create__body-textarea-box">
      <div className={clsx('story-create__body-textarea', {
        'text-input__input__error': hasError,
      })}
      >
        <div className="story-create__body-textarea-edit"
        >
          <RoundedIcon hover SvgComponent={DeleteIcon} handler={() => editHandler()} />
        </div>
        <TextArea
          rows={`${rows || MIN_ROW}`}
          value={textInput}
          className="story-create__body-textarea-input"
          placeholder={t('enterMessage')}
          onChange={((e) => { handleChange(e); })}
        />
      </div>
      {hasError && errorDescription && (
        <span className="text-input__error" dangerouslySetInnerHTML={{ __html: errorDescription }} />
      )}
  </div>
  );
}

export default StoryOptionalTextField;
