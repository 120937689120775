import * as React from 'react';
import t from 'prop-types'
import { Redirect, Route } from 'react-router-dom';

const LoginRoute = ({ 
  component: Component, isLoggedIn, is2FA, ...rest
}) => {
  const routeComponent = (props) => {
    if (isLoggedIn && is2FA) {
      return (
        <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
      );
    }
    if (!is2FA) {
      return (
        <Redirect to={{ pathname: '/two-factor-code', state: { from: props.location } }} />
      );
    }

    return (<Component {...props} />);
  };
  return <Route {...rest} render={routeComponent} />;
};

LoginRoute.propTypes = {
  isLoggedIn: t.oneOfType([t.bool, t.string]),
  is2FA: t.bool,
  component: t.object.isRequired,
};

LoginRoute.defaultProps = {
  is2FA: true,
  isLoggedIn: false,
};
export default LoginRoute;
