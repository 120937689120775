import '../../../../styles/index.scss';
import RoundedIcon from '../../../shared/RoundedIcon/RoundedIcon';
import { ReactComponent as EditIcon } from '../../../../assets/images/edit.svg'
import { ReactComponent as ShareIcon } from '../../../../assets/images/share.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/images/delete.svg'
import { connect } from 'react-redux';
import { isUserReader } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import LocalDate from '../../../shared/LocalDate/LocalDate';
import { SAVE_THE_DATE } from '../../../../shared/consts';

const PUBLISHED = 'Published';
const UNPUBLISHED = 'Unpublished';

function StoryElement({title, desc, status, date, onClick, color, userInfo, deleteAction, editAction}) {
  const { t } = useTranslation();
  return (
    <div className='story-element-container defaultscrollbar' style={{ backgroundColor: color }}>
      <div onClick={() => { onClick(); }} className='story-element-body'>
        <div className='story-element-title'>
          {title}
        </div>
        <div className='story-element-subtitle'>
          {desc}
        </div>
      </div>
      <div className='story-element-footer'>
        <div className='story-element-footer-status'>
          <div className='story-element-footer-status-text'>
            { status === PUBLISHED ? t('published') : t('unpublished') }
          </div>
          <div className='story-element-footer-status-date'>
            {t('updated')} <LocalDate date={date} parser={SAVE_THE_DATE} />
          </div>
        </div>
        <div className='story-element-footer-actions'>
          {!isUserReader(userInfo) && (
            <>
              <RoundedIcon SvgComponent={EditIcon} hover border handler={() => editAction()} />
              <RoundedIcon SvgComponent={ShareIcon} hover border handler={() => {}}  />
              <RoundedIcon SvgComponent={DeleteIcon} hover border handler={() => deleteAction()}/>
            </>
          )}

        </div>
      </div>
    </div>
  );
}
const mapStateToProps = ({ user }) => {
  return {
    userInfo: user.userInfo
  };
};

export default connect(mapStateToProps, null)(StoryElement);
