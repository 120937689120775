import React from 'react';
import types from 'prop-types';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import '../../../../styles/index.scss';
import clsx from 'clsx';
import TextInput from '../../../shared/TextInput/TextInput';
import RoundedIcon from '../../../shared/RoundedIcon/RoundedIcon';
import { ReactComponent as VideoIcon } from '../../../../assets/images/video_popup.svg';
import { ReactComponent as AudioIcon } from '../../../../assets/images/audio_popup.svg';
import { ReactComponent as FileIcon } from '../../../../assets/images/document_popup.svg';
import { ReactComponent as PDFIcon } from '../../../../assets/images/media_pdf.svg';
import { ReactComponent as DownloadIcon } from '../../../../assets/images/download.svg';

function ReportMedia(props) {
  const { t } = useTranslation();

  const TypeIcon = () => {
    switch (props.type) {
      case 'audio':
        return <AudioIcon className="report-media__caption-svg" />;
      case 'video':
        return <VideoIcon className="report-media__caption-svg" />;
      default:
        return <FileIcon className="report-media__caption-svg" />;
    }
  };

  const tofilename = (path) => path.slice(path.lastIndexOf('/') + 1);

  return (
    <>
      <div className={clsx(`report-media media-type-${props.type}`, {
        'report-media--editmode': props.editMode,
      })}>
        {props.medias.map((elem, i) => (
          <div key={i} className="report-media-container">
            {props.type === 'pdf' && (
              <div className="report-media__file">
                <PDFIcon/>
                <div className="report-media__file-filename">{tofilename(elem.src)}</div>
                <RoundedIcon
                  SvgComponent={DownloadIcon}
                  iconColor="black"
                  hover border large
                  handler={() => {
                    window.open(elem.src, 'download');
                  }}
                />
              </div>
            )}
            {props.type !== 'pdf' && (
              <div className="report-media__player">
                <ReactPlayer
                  url={elem.src}
                  className="mediaplayer"
                  controls
                  fullscreen="false"
                />
              </div>
            )}
            {props.editMode && (
              <TextInput
                title={t('reportMediaDescription')}
                customWrapperClass="report-media__caption-input"
                placeholder=""
                value={elem.caption}
              />
            )}
            {!props.editMode && elem.caption && (
              <div className="report-media__caption">
                <TypeIcon/>
                <b className="report-media__caption-label">
                  {t('reportMediaDescription')}
                </b>
                <span className="report-media__caption-text">
                  {elem.caption}
                </span>
              </div>
            )}
          </div>
        ))
        }
      </div>
    </>
  );
}

ReportMedia.propTypes = {
  type: types.string.isRequired,
  medias: types.array.isRequired,
  editMode: types.bool.isRequired,
};

export default (ReportMedia);
