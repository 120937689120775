import Cookies from 'js-cookie';
import {
  USER_LOGIN_SUCCESS,
  USER_LOGIN_TWOFACTOR,
  USER_TWO_FACTOR_SUCCESS,
  SAVE_USER_DATA,
  USER_LOGOUT,
  USER_SET_PASSWORD_ERROR,
  UPDATE_USER_PROFILE,
  SET_TOKEN,
} from '../types';

import { decrypt, encrypt } from '../utils';
import { readToken, TOKEN_KEY } from '../actions/CommonActions';

const user = localStorage.getItem('user');

// loggedIn: { error: '', success: Cookies.get(TOKEN_KEY) || null },
const initialState = {
  userInfo: user ? JSON.parse(decrypt(user)) : {},
  tempUser: '',
  loggedIn: { error: '', success: localStorage.getItem(TOKEN_KEY) || null },
  twoFactor: localStorage.getItem('twoFactor') || false,
  token: user ? readToken().access_token : null,
  passwordErrors: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_SUCCESS: {
      const { userInfo, twoFactor, defaultOrganization } = action.payload;

      if (userInfo.password) {
        userInfo.password = encrypt(userInfo.password);
      }

      localStorage.setItem('user', encrypt(JSON.stringify(userInfo)));
      localStorage.setItem('selectedOrganization', encrypt(JSON.stringify(defaultOrganization.id)));
      return {
        ...state,
        userInfo,
        twoFactor,
        ...action.payload,
      };
    }
    case USER_LOGIN_TWOFACTOR: {
      const { userInfo, twoFactor } = action.payload;
      if (userInfo.password) {
        userInfo.password = encrypt(userInfo.password);
      }
      return {
        ...state,
        userInfo,
        twoFactor,
        ...action.payload,
      };
    }
    case SET_TOKEN: {
      localStorage.removeItem('hold');
      return {
        ...state,
        token: action.payload.token,
      };
    }
    case USER_TWO_FACTOR_SUCCESS:
      let newUserInfo = action.payload.userInfo;
      let defaultOrganization = action.payload.defaultOrganization;
      if (newUserInfo.password) {
        delete newUserInfo.password;
      }

      newUserInfo.needs2FA = false;
      localStorage.setItem('twoFactor', true);
      localStorage.setItem('user', encrypt(JSON.stringify(newUserInfo)));
      localStorage.setItem('selectedOrganization', encrypt(JSON.stringify(defaultOrganization.id)));
      return {
        ...state,
        userInfo: newUserInfo,
        token: action.payload.token,
        twoFactor: action.payload.twoFactor,
      };

    case USER_LOGOUT:
      localStorage.removeItem('user');
      localStorage.removeItem('twoFactor');
      localStorage.removeItem('hold');
      localStorage.removeItem(TOKEN_KEY);
      // Cookies.remove(TOKEN_KEY);
      return {
        ...state,
        token: null,
      };

    case USER_SET_PASSWORD_ERROR:
      return {
        ...state,
        ...action.payload
      };
    case SAVE_USER_DATA:
      // Save on LocalStorage if needed
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_USER_PROFILE:
      let updatedUserInfo = action.payload.userInfo;
      localStorage.setItem('user', encrypt(JSON.stringify(updatedUserInfo)));
      return {
        ...state,
        userInfo: updatedUserInfo,
      };
    default:
      return state;
  }
};

export default userReducer;
