import '../../../styles/index.scss';

function FullHeader({ title, subtitle, children }) {
  return (
    <div className="full-header__wrapper">
      <div className="full-header__title">
        {title}
      </div>
      <div className="full-header__subtitle">
        {subtitle}
      </div>
      <div className="full-header__button">
        {children}
      </div>
    </div>
  );
}

export default FullHeader;
