/* eslint-disable import/prefer-default-export */

const fullUri = (endpoint) => `${process.env.REACT_APP_BACKEND_URL || ''}${endpoint}`;

export const endpoints = {
  handshake: fullUri('/handshake'),
  login: fullUri('/user/login'),
  twoFactor: fullUri('/user/login/verify'),
  resetPassword: (username) => fullUri(`/user/${username}/reset`),
  setPasswordRecovery: (uid) => fullUri(`/user/${uid}/password`),
  keepAlive: (uid) => fullUri(`/user/${uid}/token`),
  options: fullUri('/options'),
  getUsers: fullUri(`/users`),
  getUser: (uid) => fullUri(`/user/${uid}`),
  createUser: fullUri(`/user`),
  editUser: (uid) => fullUri(`/user/${uid}`),
  getOrganizations: fullUri('/organizations'),
  getOrganizationUsers: (orgId) => fullUri(`/organization/${orgId}/users`),
  createOrganization: fullUri('/organization'),
  organization: (orgId) => fullUri(`/organization/${orgId}`),
  getRoles: fullUri('/roles'),
  getTaxonomyGroups: fullUri(`/taxonomy/groups`),
  getTaxonomies: fullUri(`/taxonomies`),
  getOrganizationTaxonomies: (orgId) => fullUri(`/organization/${orgId}/taxonomies`),
  createTaxonomyGroup: fullUri(`/taxonomy/group`),
  alterTaxonomyGroupElement: (groupId) => fullUri(`/taxonomy/group/${groupId}`),
  createTaxonomyChildElement: (orgId, groupId) => fullUri(`/organization/${orgId}/taxonomy/group/${groupId}`),
  alterTaxonomyChildElement: (orgId, tagId) => fullUri(`/organization/${orgId}/taxonomy/${tagId}`),
  getLayers: (orgId) => fullUri(`/organization/${orgId}/layers`),
  getReports: fullUri(`/reports`),
  getReport: (repId) => fullUri(`/report/${repId}`),
  getReportActivity: (reportId) => fullUri(`/report/${reportId}/activity`),
  getReportComments: (reportId) => fullUri(`/report/${reportId}/comments`),
  createComment: (reportId) => fullUri(`/report/${reportId}/comment`),
  editComment: (commentId) => fullUri(`/comment/${commentId}`),
  createCommentMedia: (commentId) => fullUri(`/comment/${commentId}/media`),
  addReportToFolder: (orgId, folderId) => fullUri(`/organization/${orgId}/folder/${folderId}/add`),
  removeReportFromFolder: (orgId, folderId) => fullUri(`/organization/${orgId}/folder/${folderId}/del`),
  createReport: (orgId) => fullUri(`/organization/${orgId}/report`),
  createReportMedia: (reportId) => fullUri(`/report/${reportId}/media`),
  alterReport: (orgId, reportId) => fullUri(`/organization/${orgId}/report/${reportId}`),
  closeReport: (orgId, reportId) => fullUri(`/organization/${orgId}/report/${reportId}/close`),
  getFolders: (orgId) => fullUri(`/organization/${orgId}/folders`),
  createFolder: (orgId) => fullUri(`/organization/${orgId}/folder`),
  updateFolder: (orgId, folderId) => fullUri(`/organization/${orgId}/folder/${folderId}`),
  createLayer: (orgId) => fullUri(`/organization/${orgId}/layer`),
  alterLayer: (orgId, layerId) => fullUri(`/organization/${orgId}/layer/${layerId}`),
  getBroadcastedMessages: (orgId) => fullUri(`/organization/${orgId}/broadcasts`),
  sendBroadcastMessage: (orgId) => fullUri(`/organization/${orgId}/broadcast`),
};
