import React from 'react';
import { Modal } from 'semantic-ui-react';
import Button from '../../shared/Button/Button';


function AdminModal({ actionName, header, subtitle, children, open, onClose, onOpen, customModal, hasTrigger = true, scrolling = true }) {

  return (
    <Modal
      className={`admin-modal__wrapper ${customModal}`}
      closeIcon
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      trigger={hasTrigger && <Button text={actionName} primary medium handler={onOpen}/>}
    >
      <div className="admin-modal__header">
        {header}
      </div>
      {subtitle &&
        <div className="admin-modal__subtitle">
          {subtitle}
        </div>
      }
      <Modal.Content scrolling={scrolling}>
        {children}
      </Modal.Content>
    </Modal>
  );
}

export default AdminModal
