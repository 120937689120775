import '../../../../styles/index.scss';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import t from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactMapboxGl, { ZoomControl, Marker } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import TextInput from '../../../shared/TextInput/TextInput';
import SelectBox from '../../../ui/SelectBox/SelectBox';
import Button from '../../../shared/Button/Button';
import mapDispatchToProps from '../../../../actions/index';
// import Timezones from '../../../../shared/timezones';
// import { getTimeZones } from "@vvo/tzdb";
import { MAPBOX_SECRET_KEY } from '../../../../shared/consts';
import { formatLatLng, isEmpty } from '../../../../utils/index';

const Map = ReactMapboxGl({
  attributionControl: false,
  accessToken:
  MAPBOX_SECRET_KEY,
});

const AdminMap = (props) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [latitude, setLatitude] = useState(props?.options?.latitude?.value || -0.2416815);
  const [longitude, setLongitude] = useState(props?.options?.longitude?.value || 51.5285582);
  const [zoomLevel, setZoomLevel] = useState(props?.options?.zoom?.value || '8');
  const zoomOptions = Array.from(Array(23).keys()).map((elem) => (
      { key: `${elem}`, value: `${elem}`, text: `${elem}` }
  ));

  /*
  // Commenting out timezone setting for now.
  const [timezone, setTimezone] = useState(props?.options?.timezone?.value || 'UTC');
  const timezones = getTimeZones();
  const timezoneOptions = timezones.map((elem) => (
    { key: elem.name, value: elem.name, text: elem.currentTimeFormat }
  ));
  */

  useEffect(() => {
    document.title = 'Timby - Map Settings';
    if (isEmpty(props?.options)) props.getOptions();
  }, []);


  // Save Values
  const handleUpdateOptions = () => {
    const data = {
      options: {
        latitude: {
          ...props.options.latitude,
          value: String(latitude)
        },
        longitude: {
          ...props.options.longitude,
          value: String(longitude)
        },
        zoom: {
          ...props.options.zoom,
          value: zoomLevel
        },
        /*
        timezone: {
          ...props.options.timezone,
          scope: 'global',
          value: timezone
        },
        */
      },
    };
    props.updateOptions(data);
    //props.changeTimezone(data.timezone);
  };

  // Change Values using map or fields
  const onClickMap = (map, e) => {
    setLatitude(formatLatLng(e.lngLat.lat));
    setLongitude(formatLatLng(e.lngLat.lng));
  };

  const setNewLatitude = (value) => {
    const parsedValue = parseFloat(value);
    const newErrors = errors;
    if (parsedValue < -90 || parsedValue > 90) {
      newErrors.latitude = t('validations.latitudeInvalidNumber');
      setErrors(newErrors);
      setLatitude('');
    }else{
      setLatitude(value);
      newErrors.latitude = null;
      setErrors(newErrors);
    }
  };

  const setNewLongitude = (value) => {
    const parsedValue = parseFloat(value);
    const newErrors = errors;
    if (parsedValue < -180 || parsedValue > 180) {
      newErrors.longitude = t('validations.longitudeInvalidNumber');
      setErrors(newErrors);
      setLongitude('');
    }else{
      setLongitude(value);
      newErrors.longitude = null;
      setErrors(newErrors);
    }
  };

  return (
      <div className="admin-settings-section">
        <div className="admin-settings-section__header">
          <div className="admin-settings-section__header-title">
            {t('mapParameters')}
          </div>
        </div>

        <div className="admin-settings__box">
          <div className="admin-map__map-wrapper">
            <Map
                style="mapbox://styles/mapbox/basic-v9"
                onStyleLoad={(map) => {
                  map.resize();
                }}
                center={[longitude, latitude]}
                zoom={[zoomLevel]}
                onClick={(map, e) => onClickMap(map, e)}
                className="admin-map__map"
            >
              <>
                {(longitude && latitude) && (
                  <Marker
                    className="map__marker"
                    coordinates={[longitude, latitude]} />
                    )}
                <ZoomControl className="reports-map__zoomControl" position="bottom-right" />
              </>
            </Map>
          </div>
          <div className="admin-map__parameters">
            <TextInput
              title={t('latitude')}
              value={latitude}
              type="number"
              placeholder={t('latitudePlaceholder')}
              handleChange={(event) => setNewLatitude(event.target.value)}
              hasError={!!errors.latitude}
              errorDescription={errors.latitude}
            />
            <TextInput
              title={t('longitude')}
              value={longitude}
              placeholder={t('longitudePlaceholder')}
              type="number"
              handleChange={(event) => { setNewLongitude(event.target.value); }}
              hasError={!!errors.longitude}
              errorDescription={errors.longitude}
            />
            <SelectBox
                placeholder={t('enterZoom')}
                title={t('zoomLevel')}
                value={zoomLevel}
                options={zoomOptions}
                handleChange={(e, { value }) => setZoomLevel(value)}
            />
          </div>
        </div>

        {/*
        <div className="admin-settings-section__header">
          <div className="admin-settings-section__header-title">
            {t('localization')}
          </div>
        </div>
        <div className="admin-settings__box">
          <div className="admin-settings__element admin-settings__element__timezone">
            <SelectBox
                title={t('timeZone')}
                hasTitle
                placeholder={t('timeZoneSelect')}
                value={timezone}
                options={timezoneOptions}
                search={true}
                handleChange={(e, { value }) => setTimezone(value)}
            />
          </div>
        </div>
        */}

        <div className='admin-settings-save'>
          <Button text={t('save')} primary medium handler={() => handleUpdateOptions()} />
        </div>

      </div>
  );
};

const mapStateToProps = ({ admin }) => ({
  selectedOrganizationId: admin.selectedOrganizationId,
  options: admin.options,
});

AdminMap.propTypes = {
  selectedOrganizationId: t.string.isRequired,
  options: t.array,
  getOptions: t.func,
  updateOptions: t.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminMap);
