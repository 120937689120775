import React from 'react';
import AES from 'crypto-js/aes';
import CryptoJS from 'crypto-js';
import { SECRET_KEY } from '../shared/consts';

export const hideEmailText = (string) => {
  const MIN_LETTERS_VISIBLE = 3;
  const MAX_LETTERS_VISIBLE = 7;

  const splittedString = string.split('@');
  const numberToRepeat = (splittedString[0].length - MIN_LETTERS_VISIBLE);
  splittedString[0] = splittedString[0].substr(0, MIN_LETTERS_VISIBLE)
    + '*'.repeat(numberToRepeat > MAX_LETTERS_VISIBLE ? MAX_LETTERS_VISIBLE : numberToRepeat);
  return splittedString.join('@');
};

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function encrypt(string) {
  return AES.encrypt(string, SECRET_KEY).toString();
}

export function decrypt(string) {
  return AES.decrypt(string, SECRET_KEY).toString(CryptoJS.enc.Utf8);
}

export function hexToRgb(hex, opacity = 0.2) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) {
    return `rgba(0, 0, 0, ${opacity})`;
  }
  return `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt([3], 16)}, ${opacity})`;
}

export function getRandomColor(colors) {
  // List of colors
  const c = colors || [
    '#F2C94C', '#EA9B3F', '#D34718', '#0B5BAE', '#BAD098',
    '#DCCEBF', '#DBCD75', '#F5B27B', '#52B367', '#16675D', '#1B876D',
  ];
  const lastRandomColor = Math.floor(Math.random() * c.length);

  return c[lastRandomColor];
}

export function getRandomTint() {
  const color = getRandomColor(['#F2C94C', '#EA9B3F', '#D34718', '#0B5BAE', '#1B876D']);
  const opacity = 0.25; //Math.random() * 30 / 100 + 0.2;

  return hexToRgb(color, opacity);
}

export function addParagraphs(text) {
  return text.split('\n').map((str) => <p>{str}</p>);
}

export const getDegreesMinutesAndSeconds = (coordinate, type = 'lat') => {
  const absolute = Math.abs(coordinate);
  const degrees = Math.floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * 60;
  const minutes = Math.floor(minutesNotTruncated);
  const seconds = Math.floor((minutesNotTruncated - minutes) * 60);
  let cardinal = coordinate >= 0 ? 'N' : 'S';
  if (type === 'long') {
    cardinal = coordinate >= 0 ? 'E' : 'W';
  }

  return {
    degrees,
    minutes,
    seconds,
    cardinal,
  };
};

export function toDegreesMinutesAndSeconds(coordinate, coordType) {
  const { degrees, minutes, seconds } = getDegreesMinutesAndSeconds(coordinate, coordType);

  return `${degrees}°${minutes}'${seconds}″`;
}

export function convertDMS(lat, lng) {
  const latitude = toDegreesMinutesAndSeconds(lat, 'lat');
  const latitudeCardinal = lat >= 0 ? 'N' : 'S';

  const longitude = toDegreesMinutesAndSeconds(lng, 'long');
  const longitudeCardinal = lng >= 0 ? 'E' : 'W';

  return `${latitude}${latitudeCardinal} ${longitude}${longitudeCardinal}`;
}

// export function DmsTo

function ConvertDMSToDD(degrees, minutes, seconds, direction) {
  let dd = Number(degrees) + Number(minutes) / 60 + Number(seconds) / (60 * 60);

  if (direction === 'S' || direction === 'W') {
    dd *= -1;
  } // Don't do anything for N or E
  return dd;
}

export function parseDMS(degress, minutes, seconds, direction) {
  return ConvertDMSToDD(degress, minutes, seconds, direction);
}

export function formatLatLng(val) {
  const formatted = parseFloat(val, 10).toFixed(6);
  if(formatted === NaN) {
    return '';
  } else {
    return formatted;
  }
}

export const isUserMobile = (user) => (user?.role === 'Mobile');

export const isUserReader = (user) => (user?.role === 'Reader');

export const isUserOrganizationAdmin = (user) => (user?.role === 'Organization Admin');

export const isUserEditor = (user) => (user?.role === 'Editor');

export const isUserAdmin = (user) => (user?.role === 'Administrator');

export const isDemo = () => ( window.location.host.includes('demo.timby.dev') );
// export const isDemo = () => true;

export function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  const result = keys1.map((key) => {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2))
      || (!areObjects && val1 !== val2)
    ) {
      return false;
    }
    return true;
  });

  return !result.includes(false);
}

export const findOption = function(key, assets){
  if(!assets) return '';

  // object
  // if(typeof assets === 'object') return assets[key];

  // array
  let o = assets.find((asset) => {
    if (key in asset) {
      return asset[key];
    }
  });
  return (o) ? o[key] : '';
};

export const isEmpty = function(object) {
  return (
    object
    && Object.keys(object).length === 0
    && Object.getPrototypeOf(object) === Object.prototype
  );
};

export function isObject(object) {
  return object != null && typeof object === 'object';
}
