import {useTranslation} from "react-i18next";
import {Menu} from "semantic-ui-react";
import {isDemo, isUserAdmin, isUserOrganizationAdmin} from "../../../utils";
import React, { useEffect, useState } from 'react';
import {connect} from "react-redux";
import mapDispatchToProps from "../../../actions";

const AdminTabs = (props) => {
    const { t } = useTranslation();
    const [organizationsEnabled, setOrganizationsEnabled] = useState(Number(props?.options?.organizations_shown?.value) || false);

    useEffect(() => {
        setOrganizationsEnabled(Number(props?.options?.organizations_shown?.value));
    }, [props?.options?.organizations_shown?.value]);

    return(
        <Menu className="admin_tabbar">
            {(isUserAdmin(props.userInfo) || isUserOrganizationAdmin(props.userInfo)) && (
                <Menu.Item
                    name={t('tags')}
                    active={props.activeTab === 'tags' || props.activeTab === undefined}
                    href="/admin/tags"
                />
            )}
            {(isUserAdmin(props.userInfo) || isUserOrganizationAdmin(props.userInfo)) && (
                <Menu.Item
                    name={t('users')}
                    active={props.activeTab === 'users'}
                    href="/admin/users"
                />
            )}
            {(!!organizationsEnabled && isUserAdmin(props.userInfo)) && (
                <Menu.Item
                    name={t('organizations')}
                    active={props.activeTab === 'organizations'}
                    href="/admin/organizations"
                />
            )}
            {(isUserAdmin(props.userInfo) || isUserOrganizationAdmin(props.userInfo)) && (
                <Menu.Item
                    name={t('layers')}
                    active={props.activeTab === 'layers'}
                    href="/admin/layers"
                />
            )}
            {(isDemo() && (isUserAdmin(props.userInfo) || isUserOrganizationAdmin(props.userInfo))) && (
                <Menu.Item
                    name={t('exportreport')}
                    active={props.activeTab === 'export-reports'}
                    href="/admin/export-reports"
                />
            )}
            {(isUserAdmin(props.userInfo)) && (
                <Menu.Item
                    name={t('additionalSettings')}
                    active={props.activeTab === 'settings'}
                    href="/admin/settings"
                />
            )}
        </Menu>
    );
};

const mapStateToProps = ({ user, admin }) => ({
    userInfo: user.userInfo,
    options: admin.options,
});
export default connect(mapStateToProps, mapDispatchToProps)(AdminTabs);
