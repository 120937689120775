import '../../../../styles/index.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import types from 'prop-types';
import { connect } from 'react-redux';
import ImageGallery from 'react-image-gallery';
import { ReactComponent as ChevronLeft } from '../../../../assets/images/chevron-24-left.svg';
import { ReactComponent as ChevronRight } from '../../../../assets/images/chevron-24.svg';
import { ReactComponent as ImageIcon } from '../../../../assets/images/image_popup.svg';
import TextInput from '../../../shared/TextInput/TextInput';
import ImageModal from '../../ImageModal/ImageModal';
import clsx from 'clsx';

function ReportImageSlider(props) {
  const { t } = useTranslation();
  const [imagesToRender, setImagesToRender] = useState([]);
  const [expandedImageSource, setExpandedImageSource] = useState(<></>);
  const [expandedImageModal, setExpandedImageModal] = useState(false);
  const imageGalleryRef = useRef(null);

  const onClickImage = (source) => {
    setExpandedImageSource(<img src={source} className="image-modal__image" />);
    setExpandedImageModal(true);
  };

  const labDescription = (label) => {
    return label ? (<span className="custom-image-description">
      <ImageIcon className="custom-image-description-svg" />
      <b className="custom-image-description-title">
        {t('reportMediaDescription')}
        &nbsp;
      </b>
        {label}
    </span>
    ) : null;
  };

  const descriptionInput = (description) => (
    <TextInput
      title={t('reportMediaDescription')}
      customWrapperClass="image-gallery-custom-description-input"
      value={description}
      handleChange={(e) => changeDescription(e.target.value)}
      placeholder=""
    />
  );

  const changeDescription = (value) => {
    if (!imageGalleryRef.current) {
      return;
    }
    const currentImage = imageGalleryRef.current.getCurrentIndex();

    props.onChangeDescription(value, currentImage);
  };

  useEffect(() => {
    const imagesFromParent = [...props.images];
    const newImages = [];
    imagesFromParent.forEach((elem) => {
      if (props.editMode) {
        newImages.push({
          ...elem,
          description: descriptionInput(elem.description)
        });
      } else {
        newImages.push({
          ...elem,
          description: labDescription(elem.description)
        });
      }
    });

    setImagesToRender(newImages);
  }, [props.images, props.editMode, props.defaultLanguage]);

  const onClickCurrentImage = (e) => {
    if(e.target.nodeName === 'IMG') {
      const currentImage = imageGalleryRef.current.getCurrentIndex();
      const currentImageInfo = imagesToRender[currentImage];
      onClickImage(currentImageInfo.large);
    }
  };

  return (
    <>
      <div className="report-view__container-body-info-section-title">
        {t('reportImageEvidence')}
      </div>
      <ImageGallery
        ref={(node) => {
          imageGalleryRef.current = node;
        }}
        showThumbnails={imagesToRender.length>1}
        showPlayButton={false}
        items={imagesToRender}
        showFullscreenButton={false}
        onClick={(e) => {onClickCurrentImage(e);}}
        additionalClass={clsx('custom-image-gallery', {
          'edit-mode': props.editMode,
        })}
        renderRightNav={(onClick) => (
          <div
            className="image-gallery-custom-right-nav-container"
            onClick={onClick}
            role="button"
            onKeyDown={onClick}
          >
            <ChevronRight className="image-gallery-custom-right-nav" />
          </div>
        )}
        renderLeftNav={(onClick) => (
          <div
            className="image-gallery-custom-left-nav-container"
            onClick={onClick}
            role="button"
            onKeyDown={onClick}
          >
            <ChevronLeft
              className="image-gallery-custom-left-nav"
            />
          </div>
        )}
      />
      <ImageModal
        open={expandedImageModal}
        onClose={() => setExpandedImageModal(false)}
        onOpen={() => setExpandedImageModal(true)}
        isImageOnly
      >
        {expandedImageSource}
      </ImageModal>
    </>
  );
}

const mapStateToProps = ({ common }) => ({
  defaultLanguage: common.defaultLanguage,
});

ReportImageSlider.propsTypes = {
  images: types.array,
  onChangeDescription: types.func.isRequired,
};

ReportImageSlider.defaultProps = {
  images: [],
};
export default connect(mapStateToProps, null)(ReportImageSlider);
