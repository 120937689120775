import React, { useEffect, useState } from 'react';
import types from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../../../styles/index.scss';
import { connect } from 'react-redux';
import dashboardHoc from '../DashboardHoc/DashboardHoc';
import FullHeader from '../../ui/FullHeader/FullHeader';
import Button from '../../shared/Button/Button';
import StoryElement from './StoryElement/StoryElement';
import SearchBar from '../../shared/SearchBar/SearchBar';
import mapDispatchToProps from '../../../actions/index';
import { isUserReader } from '../../../utils';

import DeleteModal from '../DeleteModal/DeleteModal';

function StoriesContainer(props) {
  const { t } = useTranslation();
  const [filteredStories, setFilteredStories] = useState(props.stories);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedStoryToDelete, setSelectedStoryToDelete] = useState(null);

  useEffect(() => {
    if (props.stories.length === 0) props.getStories();
    document.title = 'Timby - Stories';
  }, []);

  useEffect(() => {
    setFilteredStories(props.stories);
  }, [props.stories]);

  const openDeleteModal = (id) => {
    setDeleteModal(true);
    setSelectedStoryToDelete(id);
  };

  const onDeleteStory = async () => {
    const response = await props.deleteStory(selectedStoryToDelete);
    if (response) {
      setDeleteModal(false);
      setSelectedStoryToDelete(null);
    }
  };

  const searchReports = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    const searchResults = inputLength === 0 ? props.stories : props.stories.filter(el =>
        el.title.toLowerCase().indexOf(inputValue) !== -1
        || el.subtitle.toLowerCase().indexOf(inputValue) !== -1
    );

    setFilteredStories([...searchResults]);
  };

  return (
    <div className="story-container">
      <FullHeader title={t('storyTitle')}>
        <div className="story-header-createbutton">
          {!isUserReader(props.userInfo) && (
            <Button text={t('storyCreate')} primary medium handler={() => { props.history.push({ pathname: '/stories/create', from: props.history.location.pathname }); }} />
          )}
        </div>
      </FullHeader>
      <div className="story-container__wrapper whitepagescrollbar">
        <div className="story-container__wrapper-search">
          <SearchBar label={t('storySearch')} handler={searchReports} />
        </div>
        <div className="story-container__wrapper-elements">
          {(filteredStories.map((elem) => (
            <StoryElement
              onClick={() => { props.history.push({ pathname: `/stories/${elem.id}`, from: props.history.location.pathname }); }}
              editAction={() => { props.history.push({ pathname: `/stories/${elem.id}`, from: props.history.location.pathname, edit: true }); }}
              deleteAction={() => { openDeleteModal(); }}
              title={elem.title}
              desc={elem.subtitle}
              status={elem.status}
              date={elem.date}
              color={elem.color}
              key={elem.id}
            />
          )))}
        </div>
      </div>
      <DeleteModal
        onClose={() => setDeleteModal(false)}
        open={deleteModal}
        onOpen={() => setDeleteModal(true)}
        hasTrigger={false}
        header={t('deleteStory')}
        subtitle={t('deleteStoryDescription')}
        deleteAction={() => onDeleteStory()}
      />
    </div>
  );
}
const mapStateToProps = ({ user, stories }) => ({
  userInfo: user.userInfo,
  stories: stories.stories,
});

StoriesContainer.propTypes = {
  userInfo: types.object.isRequired,
  history: types.object.isRequired,
  deleteStory: types.func.isRequired,
  stories: types.array.isRequired,
  getStories: types.func.isRequired,

};
export default dashboardHoc(connect(mapStateToProps, mapDispatchToProps)(StoriesContainer));
