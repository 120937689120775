import { addMinutes, parse } from 'date-fns';
import i18n from '../translations/i18n';
import { REPORT_EVENT_DATE_PARSER } from '../shared/consts';

// Saturday, Aug 3, 2013, 4:33:00 PM GMT+10
export const date_long_timezone = {
  weekday: 'long',
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
  timeZoneName: 'short',
};

// Aug 3, 2013
export const date_standard = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
};

// Aug 3
export const date_standard_sameyear = {
  day: 'numeric',
  month: 'short',
};

// Aug 3, 2013 10:55
export const date_standard_datetime = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
};

export const getUTCDatenow = () => {
  const date = new Date();
  return addMinutes(date, date.getTimezoneOffset());
};

export const formatDate = (val, toUTC = false) => {
  let date = new Date(val);
  if(toUTC) date = addMinutes(date, date.getTimezoneOffset());

  return date.toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' });
};

export const isValidTimeZone = (tz) => {
  if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
    throw new Error('Time zones are not available in this environment');
  }

  try {
    Intl.DateTimeFormat(undefined, {timeZone: tz});
    return true;
  }
  catch (ex) {
    return false;
  }
};

export function localizeDate(date) {
  let year = date.getFullYear();
  let month = date.getMonth();
  let day = date.getDate();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();

  return new Date(Date.UTC(year, month, day, hours, minutes, seconds));
  //return new Date(year, month, day, hours, minutes, seconds);
}

export function timeSince(date) {
  if(typeof date === 'string') {
    date = parse(date, REPORT_EVENT_DATE_PARSER, new Date());
  }
  const seconds = Math.floor((getUTCDatenow() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval >= 1) {
    return i18n.t('dateTrans.years', { count: Math.floor(interval) });
  }
  interval = seconds / 2592000;
  if (interval >= 1) {
    return i18n.t('dateTrans.months', { count: Math.floor(interval) });
  }
  interval = seconds / 86400;
  if (interval >= 1) {
    return i18n.t('dateTrans.days', { count: Math.floor(interval) });
  }
  interval = seconds / 3600;
  if (interval >= 1) {
    return i18n.t('dateTrans.hours', { count: Math.floor(interval) });
  }
  interval = seconds / 60;
  if (interval >= 1) {
    return i18n.t('dateTrans.minutes', { count: Math.floor(interval) });
  }
  interval = seconds % 60;
  return i18n.t('dateTrans.seconds', { count: Math.floor(interval) });
}
