import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Toggle from '../../../ui/Toggle/Toggle';
import { isDemo, isEmpty } from '../../../../utils';
import Button from '../../../shared/Button/Button';
import t from 'prop-types';
import { connect } from 'react-redux';
import mapDispatchToProps from '../../../../actions';

const AdminDashboardSettings = (props) => {
    const { t } = useTranslation();

    // updates & updates-dependent
    const [updates, setUpdates] = useState(Number(props?.options?.updates_enabled?.value) || false);
    const [assignment, setAssignment] = useState(Number(props?.options?.assignment_enabled?.value) || false);
    const [urgency, setUrgency] = useState(Number(props?.options?.urgency_enabled?.value) || false);
    const [lifecycle, setLifecycle] = useState(Number(props?.options?.lifecycle_enabled?.value) || false);
    const [reportUpdates, setReportUpdates] = useState(Number(props?.options?.mobile_updates_enabled?.value) || false);

    // others
    const [broadcasts, setBroadcasts] = useState(Number(props?.options?.broadcasts_enabled?.value) || false);
    const [analytics, setAnalytics] = useState(Number(props?.options?.analytics_enabled?.value) || false);
    const [stories, setStories] = useState(Number(props?.options?.stories_enabled?.value) || false);
    const [organizationsEnabled, setOrganizationsEnabled] = useState(Number(props?.options?.organizations_shown?.value) || false);

    useEffect(() => {
        document.title = 'Timby - Dashboard Settings';
    }, []);

    useEffect(() => {
        if (isEmpty(props?.options)) props.getOptions();
    }, [props]);

    // If updates aren't enabled, turn off all the related settings
    useEffect(() => {
        if(!Number(updates)) {
            setAssignment(false);
            setUrgency(false);
            setLifecycle(false);
            setReportUpdates(false);
        }
    }, [updates]);

    // Save Values
    const handleUpdateOptions = () => {
        let data = { options: {} };

        if(getOptionValue('updates_enabled') !== Number(updates)) {
            data.options.updates_enabled = {
                ...getOption('updates_enabled'),
                value: Number(updates).toString(),
            };
        }

        if(getOptionValue('assignment_enabled') !== Number(assignment)) {
            data.options.assignment_enabled = {
                ...getOption('assignment_enabled'),
                value: Number(assignment).toString(),
            };
        }

        if(getOptionValue('urgency_enabled') !== Number(urgency)) {
            data.options.urgency_enabled = {
                ...getOption('urgency_enabled'),
                value: Number(urgency).toString(),
            };
        }

        if(getOptionValue('lifecycle_enabled') !== Number(lifecycle)) {
            data.options.lifecycle_enabled = {
                ...getOption('lifecycle_enabled'),
                value: Number(lifecycle).toString(),
            };
        }

        if(getOptionValue('mobile_updates_enabled') !== Number(reportUpdates)) {
            data.options.mobile_updates_enabled = {
                ...getOption('mobile_updates_enabled'),
                value: Number(reportUpdates).toString(),
            };
        }

        /* Not 2.0
        if(getOptionValue('analytics_enabled') !== Number(analytics)) {
            data.options.analytics_enabled = {
                ...getOption('analytics_enabled'),
                value: Number(analytics).toString(),
            };
        }

        if(getOptionValue('stories_enabled') !== Number(stories)) {
            data.options.stories_enabled = {
                ...getOption('stories_enabled'),
                value: Number(stories).toString(),
            };
        }
        */

        if(getOptionValue('broadcasts_enabled') !== Number(broadcasts)) {
            data.options.broadcasts_enabled = {
                ...getOption('broadcasts_enabled'),
                value: Number(broadcasts).toString(),
            };
        }

        if(getOptionValue('organizations_shown') !== Number(organizationsEnabled)) {
            data.options.organizations_shown = {
                ...getOption('organizations_shown'),
                value: Number(organizationsEnabled).toString(),
            };
        }

        props.updateOptions(data);
        props.getOptions();
    };

    const getOption = (key) => {
        return props?.options[key];
    };

    const getOptionValue = (k) => {
        const i = getOption(k);
        return (i)? Number(i.value) : null;
    };

    return (
      <div className="admin-settings-section">
          <div className="admin-settings-section__header">
              <h1 className="admin-settings-section__header-title">
                  {t('dashboardConfiguration')}
              </h1>
          </div>
          <div className="admin-settings__box">
              <div className="admin-settings__element admin-settings__element-open">
                  <Toggle
                    label={t('reportUpdates')}
                    value={updates}
                    handleChange={() => setUpdates(!updates)}
                  />
                  {updates && (
                    <div className="admin-settings__element__container">
                        <Toggle
                          label={t('reportAssignment')}
                          value={assignment}
                          handleChange={() => setAssignment(!assignment)}
                        />
                        <Toggle
                          label={t('urgency')}
                          value={urgency}
                          handleChange={() => setUrgency(!urgency)}
                        />
                        <Toggle
                          label={t('reportLifecycle')}
                          value={lifecycle}
                          handleChange={() => setLifecycle(!lifecycle)}
                        />
                    </div>
                  )}
              </div>
              {isDemo() && (
                <div className="admin-settings__element">
                    <Toggle
                      value={analytics}
                      handleChange={() => setAnalytics(!analytics)}
                      label={t('analyticsPage')}
                    />
                </div>
              )}
              {isDemo() && (
                <div className="admin-settings__element">
                    <Toggle
                      value={stories}
                      handleChange={() => setStories(!stories)}
                      label={t('storiesPage')}
                    />
                </div>
              )}
              <div className="admin-settings__element">
                  <Toggle
                    value={broadcasts}
                    handleChange={() => setBroadcasts(!broadcasts)}
                    label={t('broadcastsPage')}
                  />
              </div>
              <div className="admin-settings__element">
                  <Toggle
                    value={organizationsEnabled}
                    handleChange={() => setOrganizationsEnabled(!organizationsEnabled)}
                    label={t('organizationsEnabled')}
                  />
              </div>
          </div>

          <div className='admin-settings-save'>
              <Button text={t('save')} primary medium handler={() => handleUpdateOptions()} />
          </div>
      </div>
    );
};

AdminDashboardSettings.displayName = 'AdminDashboardSettings';

const mapStateToProps = ({ admin }) => ({
    options: admin.options,
});

AdminDashboardSettings.propTypes = {
    options: t.object,
    getOptions: t.func,
    updateOptions: t.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboardSettings);
