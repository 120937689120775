import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Toggle from '../../../ui/Toggle/Toggle';
import clsx from "clsx";
import TextInput from "../../../shared/TextInput/TextInput";
import MessageCustomSelect from "../../MessageForm/MessageCustomSelect/MessageCustomSelect";
import SelectBox from "../../../ui/SelectBox/SelectBox";
import CallingNumbers from "../../../../shared/callingNumbers";
import Button from "../../../shared/Button/Button";
import types from "prop-types";
import {connect} from "react-redux";
import mapDispatchToProps from "../../../../actions";
import { isDemo, isEmpty } from '../../../../utils';
import t from 'prop-types';

const AdminAppSettings = (props) => {
    const {t} = useTranslation();

    const [anonymousSignIn, setAnonymousSignIn] = useState(Number(props?.options?.anonymous_enabled?.value) || false);
    const [anonymousSignInOrganization, setAnonymousSignInOrganization] = useState(props?.options?.anonymous_organization?.value || '');
    const [eventDate, setEventDate] = useState(Number(props?.options?.mobile_datepicker?.value) || false);

    const defaultPath = 'https://s3-eu-west-1.amazonaws.com/timbyinfo/download.zip';
    const [lessonsUrl, setLessonsUrl] = useState(props?.options?.lessons_url?.value || defaultPath);

    // If updates aren't enabled on the Dashboard, they can't be enabled on the Mobile App
    const areUpdatesEnabledOnDashboard = Number(props?.options?.updates_enabled?.value);
    const areUpdatesEnabledOnMobile = Number(props?.options?.mobile_updates_enabled?.value);
    const [reportUpdates, setReportUpdates] = useState( (areUpdatesEnabledOnDashboard && areUpdatesEnabledOnMobile) );

    // not 2.0
    const [mapsOnMobile, setMapsOnMobile] = useState(Number(props?.options?.mobile_maps_enabled?.value) || false);
    const [emergencyAlerts, setEmergencyAlerts] = useState(Number(props?.options?.emergency_enabled?.value) || false);
    const [emergencyAlertNumber, setEmergencyAlertNumber] = useState(Number(props?.options?.emergency_phones?.value) || '');
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectUsers, setSelectUsers] = useState([]);

    const organizationOptions = props?.userInfo?.organizations?.map(
      (elem) => ({
          key: elem.id,
          value: elem.id,
          text: elem.name
      })
    );

    useEffect(() => {
        document.title = 'Timby - Mobile Application Settings';
        // @fixme: think about how emergency contact will be chosen. same for all orgs? only admins? etc.
        props.getUsers();
    }, []);

    useEffect(() => {
        if (isEmpty(props?.options)) props.getOptions();
    }, [props]);

    useEffect(() => {
        const users = props.users.map((elem) => (
          { text: elem.username, id: elem.username, key: elem.username }
        ));
        setSelectUsers(users);
    }, [props.users]);

    // Save values
    const handleUpdateOptions = () => {
        let data = { options: {} };

        if(getOptionValue('anonymous_enabled') !== Number(anonymousSignIn)) {
            data.options.anonymous_enabled = {
                ...getOption('anonymous_enabled'),
                value: Number(anonymousSignIn).toString(),
            };
        }

        if(props.options.anonymous_organization.value !== anonymousSignInOrganization) {
            data.options.anonymous_organization = {
                ...getOption('anonymous_organization'),
                value: anonymousSignInOrganization,
            };
        }

        if(getOptionValue('mobile_updates_enabled') !== Number(reportUpdates)) {
            data.options.mobile_updates_enabled = {
                ...getOption('mobile_updates_enabled'),
                value: Number(reportUpdates).toString(),
            };
        }

        if(getOptionValue('mobile_datepicker') !== Number(reportUpdates)) {
            data.options.mobile_datepicker = {
                ...getOption('mobile_datepicker'),
                value: Number(eventDate).toString(),
            };
        }

        if(props.options.lessons_url.value !== lessonsUrl) {
            data.options.lessons_url = {
                ...getOption('lessons_url'),
                value: lessonsUrl,
            };
        }

        props.updateOptions(data);
        props.getOptions();
    };

    const getOption = (key) => {
        return props?.options[key];
    };

    const getOptionValue = (k) => {
        const i = getOption(k);
        return (i)? Number(i.value) : null;
    };

    return (
      <div className="admin-settings-section">
          <div className="admin-settings-section__header">
              <h1 className="admin-settings-section__header-title">
                  {t('appConfiguration')}
              </h1>
          </div>
          <div className="admin-settings__box">
              <div
                className={clsx('admin-settings__element admin-settings-organization', {
                    'admin-settings__element-open': anonymousSignIn,
                })}
              >
                  <Toggle
                    value={anonymousSignIn}
                    handleChange={() => setAnonymousSignIn(!anonymousSignIn)}
                    label={t('anonymousSignIn')}
                  />
                  {anonymousSignIn && (
                    <div className="admin-settings-subsection">
                        <div className="admin-settings-subsection__title">
                            {t('mobileOrganizationLabel')}
                        </div>
                        <div className="admin-settings-subsection__content">
                            <SelectBox
                              placeholder={t('mobileOrganizationPlaceholder')}
                              value={anonymousSignInOrganization}
                              options={organizationOptions}
                              handleChange={(e, { value }) => setAnonymousSignInOrganization(value)}
                            />
                            {(anonymousSignInOrganization !== props?.options?.anonymous_organization?.value) && (
                              <div className="message-notice" style={{marginTop: '-1rem'}}>
                                  {t('mobileOrganizationWarning')}
                              </div>
                            )}
                        </div>
                    </div>
                  )}
              </div>

              {isDemo() && (
                <div className="admin-settings__element">
                    <Toggle
                      value={mapsOnMobile}
                      handleChange={() => setMapsOnMobile(!mapsOnMobile)}
                      label={t('enableMobileMaps')}
                    />
                </div>
              )}

              <div className="admin-settings__element">
                  <Toggle
                    value={reportUpdates}
                    disabled={!areUpdatesEnabledOnDashboard}
                    handleChange={() => setReportUpdates(!reportUpdates)}
                    label={t('enableMobileUpdates')}
                  />
              </div>

              <div className="admin-settings__element">
                  <Toggle
                    value={eventDate}
                    handleChange={() => setEventDate(!eventDate)}
                    label={t('enableMobileDatepicker')}
                  />
              </div>

              <div className={'admin-settings__element admin-settings-lessons admin-settings__element-open'}>
                  <div className="admin-settings__element-title">{t('lessonPath')}</div>
                  <TextInput
                    placeholder={t('enterCustomLessonPath')}
                    value={lessonsUrl}
                    hasTitle={false}
                    handleChange={(event) => setLessonsUrl(event.target.value)}
                  />
              </div>
              {isDemo() && (
                <div
                  className={clsx('admin-settings__element admin-settings-emergency-contact', {
                      'admin-settings__element-open': emergencyAlerts,
                  })}
                >
                    <Toggle
                      value={emergencyAlerts}
                      handleChange={() => setEmergencyAlerts(!emergencyAlerts)}
                      label={t('emergencyAlerts')}
                    />
                    {emergencyAlerts && (
                      <div className="admin-settings-subsection">
                          <div className="admin-settings-subsection__title">
                              {t('emergencyContactUser')}
                          </div>
                          <div className="admin-settings-subsection__content">
                              <MessageCustomSelect
                                options={selectUsers}
                                selectedOptions={selectedOptions}
                                changeSelectedOptions={setSelectedOptions}
                              />
                              <div className="admin-settings-emergency-contact-elem">
                                  <div className="admin-settings-subsection__title">
                                      {t('emergencyContactNumber')}
                                  </div>
                                  <div className="admin-settings-emergency-contact__phone">
                                      <SelectBox
                                        placeholder="+44"
                                        value={null}
                                        options={CallingNumbers}
                                        search
                                        handleChange={() => {}}
                                      />
                                      <TextInput
                                        placeholder={t('contactNumber')}
                                        value={emergencyAlertNumber}
                                        hasTitle={false}
                                        handleChange={(event) => setEmergencyAlertNumber(event.target.value)}
                                      />
                                  </div>
                              </div>
                              <div className="admin-settings-emergency-contact-elem-copy">
                                  <div className="add-link">{t('addAdditionalEmergencyNumber')}</div>
                                  <Button className="add-button" primary medium text={t('save')} handler={() => {}} />
                              </div>
                          </div>
                      </div>
                    )}
                </div>
              )}
          </div>

          <div className='admin-settings-save'>
              <Button text={t('save')} primary medium handler={() => handleUpdateOptions()} />
          </div>
      </div>
    );
};

const mapStateToProps = ({ admin, user }) => ({
    users: admin.users,
    userInfo: user.userInfo,
    options: admin.options,
});

AdminAppSettings.displayName = 'AdminAppSettings';

AdminAppSettings.propTypes = {
    users: types.array.isRequired,
    userInfo: t.object,
    options: t.object,
    getOptions: t.func,
    updateOptions: t.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminAppSettings);
