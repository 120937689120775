import {
  UPDATE_SELECTED_ORGANIZATION,
  GET_ROLES,
  USER_LOGIN_SUCCESS,
  USER_TWO_FACTOR_SUCCESS,
  GET_OPTIONS, UPDATE_OPTIONS,
  GET_ORGANIZATION_LAYERS,
  GET_ORGANIZATIONS, CREATE_ORGANIZATION, UPDATE_ORGANIZATION, DELETE_ORGANIZATION,
  GET_USERS, ADD_USER, SELECT_USER, UNSELECT_USER, UPDATE_USER, DELETE_USER,
  GET_TAG_GROUPS, ADD_TAG_GROUP, UPDATE_TAG_GROUP, DELETE_TAG_GROUP,
  GET_ORGANIZATION_TAGS, ADD_TAG, UPDATE_TAG, DELETE_TAG,
} from '../types';
import { decrypt, encrypt } from '../utils';

const getUserOrganization = () => {
  if(localStorage.getItem('selectedOrganization')) {
    return JSON.parse(decrypt(localStorage.getItem('selectedOrganization')));
  } else if(localStorage.getItem('user')) {
    const userInfo = JSON.parse(decrypt(localStorage.getItem('user')));
    return userInfo?.organizations[0].id;
  } else {
    return null;
  }
};

const initialState = {
  groupTags: [],
  organizationTags: [],
  users: [],
  organizations: [],
  roles: [],
  layers: [],
  selectedOrganizationId: getUserOrganization(),
  selectedUser: null,
  options: {},
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TAG_GROUPS:
      return {
        ...state,
        groupTags: action.payload.tagGroups,
      };

    case GET_ORGANIZATION_TAGS:
      return {
        ...state,
        organizationTags: action.payload.organizationTags,
      };

    case ADD_TAG_GROUP: {
      const newGroupTag = action.payload;

      return {
        ...state,
        groupTags: [...state.groupTags, newGroupTag],
      };
    }

    case UPDATE_TAG_GROUP: {
      const updatedGroupTag = action.payload;
      const groupTags = state.groupTags.map((elem) => {
        if (elem.id === updatedGroupTag.id) {
          return updatedGroupTag;
        }
        return elem;
      });

      return {
        ...state,
        groupTags: [...groupTags],
      };
    }

    case DELETE_TAG_GROUP: {
      const groupTagID = action.payload;
      const filteredGroupTags = state.groupTags.filter((elem) => {
        return (elem.id !== groupTagID);
      });

      return {
        ...state,
        groupTags: [...filteredGroupTags],
      };
    }

    case DELETE_ORGANIZATION:
      return { ...state };

    case ADD_TAG: {
      const newTag = action.payload;

      return {
        ...state,
        organizationTags: [...state.organizationTags, newTag],
      };
    }

    case DELETE_TAG: {
      const tagID = action.payload;
      const filteredTags = state.organizationTags.filter((elem) => {
        return (elem.id !== tagID);
      });

      return {
        ...state,
        organizationTags: [...filteredTags],
      };
    }

    case UPDATE_TAG: {
      const updatedTag = action.payload;
      const updatedTags = state.organizationTags.map((elem) => {
        if (elem.id === updatedTag.id) {
          return updatedTag;
        }
        return elem;
      });

      return {
        ...state,
        organizationTags: [...updatedTags],
      };
    }

    case GET_USERS:
      return {
        ...state,
        users: action.payload.users,
      };

    case SELECT_USER: {
      return {
        ...state,
        selectedUser: action.payload,
      };
    }

    case UNSELECT_USER: {
      return {
        ...state,
        selectedUser: null,
      };
    }

    case ADD_USER: {
      const newUser = action.payload;

      return {
        ...state,
        users: [...state.users, newUser],
      };
    }

    case UPDATE_USER: {
      const updatedUser = action.payload;
      const updatedUsers = state.users.map((elem) => {
        if (elem.id === updatedUser.id) {
          return updatedUser;
        }
        return elem;
      });

      return {
        ...state,
        users: [...updatedUsers],
      };
    }

    case DELETE_USER: {
      const userId = action.payload;
      const filteredUsers = state.users.filter((elem) => {
        return (elem.id !== userId);
      });

      return {
        ...state,
        users: [...filteredUsers],
      };
    }

    case GET_ORGANIZATIONS:
      return {
        ...state,
        organizations: [...action.payload.organizations],
      };
    case GET_ORGANIZATION_LAYERS:
      return {
        ...state,
        layers: action.payload.layers,
      };
    case GET_ROLES:
      return {
        ...state,
        roles: action.payload.roles,
      };
    case UPDATE_SELECTED_ORGANIZATION:
      const newOrgId = action.payload.selectedOrganizationId;
      localStorage.setItem('selectedOrganization', encrypt(JSON.stringify(newOrgId)));
      return {
        ...state,
        selectedOrganizationId: newOrgId
      };
    case CREATE_ORGANIZATION:
      return {
        ...state,
        organizations: [...state.organizations, action.payload.organization],
      };

    case GET_OPTIONS:
      return {
        ...state,
        options: action.payload
      };

    case UPDATE_OPTIONS:
      const newOptions = {
        ...state.options,
        ...action.payload.options,
      };
      return {
        ...state,
        options: newOptions
      };

    case UPDATE_ORGANIZATION: {
      const updatedOrganization = action.payload.organization;
      const { organizations } = state;
      const filteredOrganization = organizations.map((elem) => {
        if (elem.id === updatedOrganization.id) {
          return updatedOrganization;
        }
        return elem;
      });
      return {
        ...state,
        organizations: [...filteredOrganization],
      };
    }
    case USER_TWO_FACTOR_SUCCESS:
    case USER_LOGIN_SUCCESS:
      if (action.payload.defaultOrganization === null) {
        return state;
      }
      return {
        ...state,
        selectedOrganizationId: action.payload.defaultOrganization?.id,
      };

    default:
      return state;
  }
};

export default adminReducer;
