import React from 'react';
import t from 'prop-types';
import '../../../styles/index.scss';
import clsx from 'clsx';

function TextInput({
  hasTitle = true,
  textarea,
  title,
  placeholder,
  type = 'text',
  value = '',
  handleChange,
  disabled,
  readonly,
  customInputClass,
  customWrapperClass,
  hasError,
  errorDescription = '',
  isSelectingPlaceholder = false,
}) {
  return (
    <div className={clsx('text-input__wrapper', {
      [`${customWrapperClass}`]: !!customWrapperClass,
    })}
    >
      {hasTitle && <div className="text-input__label">{title}</div>}
      {textarea
        ? <textarea
          className={clsx('text-input__input', {
            [`${customInputClass}`]: !!customInputClass,
            'text-input__input__error': hasError,
            'text-input__input__tagplaceholder': isSelectingPlaceholder,
          })}
          value={value}
          type={type}
          onChange={handleChange}
          disabled={disabled}
          readOnly={readonly}
          autoCorrect="none"
          autoComplete="none"
          spellCheck={false}
          placeholder={placeholder}
        />
        : <input
          className={clsx('text-input__input', {
            [`${customInputClass}`]: !!customInputClass,
            'text-input__input__error': hasError,
            'text-input__input__tagplaceholder': isSelectingPlaceholder,
          })}
          value={value}
          type={type}
          onChange={handleChange}
          disabled={disabled}
          readOnly={readonly}
          autoCorrect="none"
          autoComplete="none"
          spellCheck={false}
          placeholder={placeholder}
        />
      }

      {hasError && errorDescription && (
        <span className="text-input__error" dangerouslySetInnerHTML={{ __html: errorDescription }} />
      )}
    </div>
  );
}

TextInput.propTypes = {
  hasTitle: t.bool,
  title: t.string,
  placeholder: t.string,
  type: t.string,
  value: t.string,
  handleChange: t.func,
  disabled: t.bool,
  readonly: t.bool,
  customInputClass: t.string,
  hasError: t.bool,
  errorDescription: t.string,
  isSelectingPlaceholder: t.bool,
};

TextInput.defaultProps = {
  hasTitle: true,
  title: '',
  placeholder: '',
  type: 'text',
  value: '',
  handleChange: () => {},
  disabled: false,
  readonly: false,
  customInputClass: '',
  customWrapperClass: '',
  hasError: false,
  isSelectingPlaceholder: false,
  errorDescription: '',
};

export default TextInput;
