import {
  FETCHING_START, FETCHING_END, HANDSHAKE, SET_TOKEN, USER_LOGOUT,
  UPDATE_DEFAULT_LANGUAGE, UPDATE_CURRENT_LANGUAGE, UPDATE_CURRENT_TIMEZONE,
  ADD_NEW_MESSAGE, REMOVE_MESSAGES, HIDE_MESSAGE,
} from '../types';
import i18n from '../translations/i18n';
import Cookies from 'js-cookie';
import { history } from '../index';
import { endpoints } from './endpoints';
import { ALERT_ERROR, ALERT_SUCCESS } from '../shared/consts';
import { sleep } from '../utils';
import { isValidTimeZone } from '../utils/dateUtils';
import enLocale from "date-fns/locale/en-US";

export const TOKEN_KEY = 'token';

const convertSecondsToDate = (seconds) => {
  const verifiedSeconds = Number(seconds);
  if (Number.isNaN(verifiedSeconds)) {
    return (1000 * 60) * 5; // 5Minutes
  }

  return Math.floor(seconds / (3600 * 24));
};

export const doHandshake = () => async (dispatch) => {
  let endpoint = endpoints.handshake;

  let options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  };

  try {
    const response = await fetch(endpoint, options);
    const { data, status } = await response.json();
    if (status === 200) {
      const selectedLanguage = data.languages.find((elem) => (elem.locale === data.default_language));

      data.defaultLanguage = selectedLanguage;
      data.currentLanguage = selectedLanguage;
      if(!isValidTimeZone(data.timezone)) data.timezone = 'Europe/London';

      const userLanguage= Cookies.get('language')? JSON.parse(Cookies.get('language')) : null;
      if(userLanguage) {
        data.currentLanguage = data.languages.find((elem) => (elem.locale === userLanguage.language));
      }
      // console.log(data.currentLanguage);

      dispatch(changeLanguage(data.currentLanguage.locale));
      dispatch({ type: HANDSHAKE, payload: data });
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};


export const changeLanguage = (language) => async (dispatch) => {
  const htmlLang = language.replace('_', '-');
  const code = language.substring(0, 2);
  let locale = enLocale;

  // change strings
  i18n.changeLanguage(htmlLang);

  // try to load date locales (with or without region)
  try {
    locale = await import('date-fns/locale/' + htmlLang + '/index.js');
  } catch (e) {
    try {
      locale = await import('date-fns/locale/' + code + '/index.js');
    } catch (e) {
      console.warn(e);
    }
  }

  const languageSettings = {
    language: language,
    locale: locale.default,
  };

  // save a cookie
  Cookies.set('language', JSON.stringify(languageSettings));

  dispatch({
    type: UPDATE_CURRENT_LANGUAGE,
    payload: languageSettings
  });

  return true;
};

// Called when saving the site's language settings
export const updateDefaultLanguage = (language) => (dispatch) => {
  dispatch({ type: UPDATE_DEFAULT_LANGUAGE, payload: { language } });
};

export const changeTimezone = (timezone) => (dispatch) => {
  dispatch({ type: UPDATE_CURRENT_TIMEZONE, payload: { timezone } });
};

export const addNewAlert = (message, messageType, actionType = ADD_NEW_MESSAGE) => (dispatch) => {
  dispatch({
    type: actionType,
    payload: { type: messageType, message, timestamp: Date.now() },
  });
};

export const addSuccessAlert = (message) => (dispatch) => {
  dispatch(addNewAlert(message, ALERT_SUCCESS, ADD_NEW_MESSAGE));
};

export const hideAlert = (messageId) => (dispatch) => {
  dispatch({ type: HIDE_MESSAGE, payload: messageId });
};

export const removeAlert = (messageId) => (dispatch) => {
  dispatch({ type: REMOVE_MESSAGES, payload: messageId });
};

export const openLoader = () => (dispatch) => {
  dispatch({ type: FETCHING_START });
};
export const closeLoader = () => (dispatch) => {
  dispatch({ type: FETCHING_END });
};

export const setUpCookie = (token) => () => {
  const expire_at = Date.now() + (token.expires_in * 1000);
  const newToken = {
    'expire': expire_at,
    ...token
  };

  localStorage.setItem(TOKEN_KEY, JSON.stringify(newToken));
  // Cookies.set(TOKEN_KEY, JSON.stringify(newToken));
};

export const readToken = () => {
  //const cookie = Cookies.get(TOKEN_KEY);
  const cookie = localStorage.getItem(TOKEN_KEY);
  if(cookie) return JSON.parse(cookie);
  return false;
};

export const refreshToken = () => async (dispatch, getState) => {
  localStorage.setItem('hold', '1');
  const { user } = getState();
  const cookie = readToken();

  try {
    dispatch(openLoader());

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        refresh_token: cookie.refresh_token
      })
    };

    const response = await fetch(endpoints.keepAlive(user.userInfo.id), options);
    const { data, status } = await response.json();

    if (status === 200) {
      dispatch(setUpCookie(data));
      dispatch({ type: SET_TOKEN, payload: { token: data.access_token } });
      return data.access_token;
    } else {
      dispatch({ type: USER_LOGOUT });
      history.push('/');
      dispatch(addNewAlert(i18n.t('notifications.sessionExpired'), ALERT_ERROR));
      return false;
    }

  } catch(e) {
    console.log(e);
  } finally {
    dispatch(closeLoader());
  }

  return false;
};

export const getToken = () => async (dispatch, getState) => {

  while(localStorage.getItem('hold') === '1') {
    await sleep(1000);
  }

  const cookie = readToken();
  const { token } = getState().user;
  let returnValue;

  if (Date.now() > cookie.expire) {
    returnValue = await dispatch(refreshToken());
  } else {
    returnValue = token;
  }

  return returnValue;
};
