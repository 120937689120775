import '../../../styles/index.scss';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import types from 'prop-types';
import mapDispatchToProps from '../../../actions/index';
import ReportActivityFeedSubmit from './ReportActivityFeedSubmit/ReportActivityFeedSubmit';
import ReportActivityFeedInfo from './ReportActivityFeedInfo/ReportActivityFeedInfo';
import { timeSince } from '../../../utils/dateUtils';
//import formatActivityFeedList from './ReportActivityFeedFormatter';

/*
const getLabelByType = (activityFeedElement, translationFunc) => {
  const eventType = activityFeedElement.event;
  const { data } = activityFeedElement;

  switch (eventType) {
    case 'add_comment':
      return translationFunc('replyAction');
    case 'add_media':
      let mediaLabel = '';
      const mediaArray = [];
      if (data.image && data.image.length) mediaArray.push(translationFunc('mediaImagesLabel', { count: data.image.length }));
      if (data.video && data.video.length) mediaArray.push(translationFunc('mediaVideosLabel', { count: data.video.length }));
      if (data.pdf && data.pdf.length) mediaArray.push(translationFunc('mediaPdfsLabel', { count: data.pdf.length }));
      if (data.audio && data.audio.length) mediaArray.push(translationFunc('mediaAudiosLabel', { count: data.audio.length }));

      if (mediaArray.length > 1) {
        mediaLabel += mediaArray.slice(0, mediaArray.length - 2).join(', ');
        if (mediaArray.length > 2) {
          mediaLabel += ', ';
        }
        mediaLabel += mediaArray.slice(mediaArray.length - 2, mediaArray.length).join(' and ');
      } else {
        mediaLabel += mediaArray.join(', ');
      }
      return `${mediaLabel}.`;

    case 'edit_lifecycle':
      return (translationFunc('lifecycleAction', { x: data }));
    case 'edit_verified': {
      const trans = parseInt(data, 10) ? translationFunc('verifyAction') : translationFunc('unverifyAction');
      return trans;
    }
    case 'edit_visibility':
      return (translationFunc('visibilityAction', { x: data }));
    case 'set_taxonomy':
      return translationFunc('taxonomyAction');
    case 'edit_assignee':
      return (translationFunc('assigneeAction', { x: data }));
    case 'edit_urgency':
      return (translationFunc('urgencyAction', { x: data }));
    case 'edit_content':
      return translationFunc('contentAction');
    case 'edit_position':
      return translationFunc('positionAction');
    case 'edit_title':
      return translationFunc('titleAction');
    default:
      return '';
  }
};
*/

const ReportActivityFeed = (props) => {
  const { t } = useTranslation();
  const activityFeedRef = useRef(null);

  useEffect(() => {
    if (props.selectedReport) {
      props.getReportComments(props.selectedReport.id);
    }
  }, [props.selectedReport]);

  /*
  const onScroll = () => {
    if (activityFeedRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = activityFeedRef.current;
      if ((scrollTop + clientHeight === scrollHeight) && props.activityFeed.length !== 0) {
        props.getReportActivity(props.selectedReport.id);
      }
    }
  };
  ...
  <div
        className="report-activityfeed-body-container defaultscrollbar"
        ref={(ref) => { activityFeedRef.current = ref; }}
        onScroll={(event) => { onScroll(event); }}
      >
  */

  //const activityFeedInfo = formatActivityFeedList(props.activityFeed, props.selectedReport);
  return (
    <div className="report-activityfeed">
      <div className="report-activityfeed-title-container">
        <div className="report-activityfeed-title">
          {t('reportActivity')}
        </div>
      </div>
      <div
        className="report-activityfeed-body-container defaultscrollbar"
        ref={(ref) => { activityFeedRef.current = ref; }}
      >
        {!(Object.entries(props.comments).length) &&(
         <p className="report-activityfeed-empty">{t('reportNoActivity')}</p>
        )}
        {(props.comments.map((elem) => (
          <ReportActivityFeedInfo
            key={elem.id}
            author={elem.author}
            color={elem.author === props.userInfo.username ? '#2B8CBE' : elem.color}
            actionText={t('replyAction')}
            actionTime={timeSince(elem.posted_date)}
            comment={elem.content}
            imageFiles={elem.media.filter(e => e.type === 'image')}
            videoFiles={elem.media.filter(e => e.type === 'video')}
            pdfFiles={elem.media.filter(e => e.type === 'pdf')}
            audioFiles={elem.media.filter(e => e.type === 'audio')}
          />
        )))}
      </div>
      {!!props.editable &&(
        <ReportActivityFeedSubmit
          isReportClosed={props.isReportClosed}
          setIsReportClosed={props.setIsReportClosed}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ reports }) => ({
  reports: reports.reports,
  selectedReport: reports.selectedReport,
  comments: reports.comments
});

ReportActivityFeed.propTypes = {
  comments: types.array.isRequired,
  selectedReport: types.object.isRequired,
  getReportActivity: types.func.isRequired,
  userInfo: types.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportActivityFeed);
