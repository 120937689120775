
import '../../../../styles/index.scss';
import { ReactComponent as WarningIcon } from '../../../../assets/images/red_warning.svg'
import { ReactComponent as VerifiedIcon } from '../../../../assets/images/icon_verified.svg';
import Toggle from '../../../ui/Toggle/Toggle';
import { isDemo } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import LocalDate from '../../../shared/LocalDate/LocalDate';

function ReportBasicInfo({editable, verified, closed, date, title, editMode, author, reportPublic, onChangePublic}) {
  const { t } = useTranslation();
  if (editMode){
    return (
      <>
      <div className='report-basic-info-title'>
        {t('editReport')}
      </div>
      </>
    );
  }
  return (
    <>
      <div className='report-basic-info-header'>
        <div className='report-basic-info-date'>
           <LocalDate date={date} format='full' />
        </div>
        <div className='report-basic-info-status'>

          {!verified && (
            <>
              <WarningIcon className='report-basic-info-status-unverify' />
              <div className='report-basic-info-status-title'>
                {t('reportUnverified')}
              </div>
            </>
          )}
          {verified && (
            <>
              <VerifiedIcon className='report-basic-info-status-verify'/>
              <div className='report-basic-info-status-title'>
                {t('reportVerified')}
              </div>
            </>
          )}
          {closed && (
            <>
              <div className='report-basic-info-status-title'>
                {t('reportClosed')}
              </div>
            </>
          )}
          {isDemo() && (
            <div className='report-basic-info-status-title'>
              {editable ?
                <Toggle label={t('reportPublic')} customLabelClass='report-basic-info-status-title' value={reportPublic} handleChange={onChangePublic}/>
                :
                <div className='report-basic-info-status-title'>
                  {t('reportPublic')}
                </div>
              }
            </div>
          )}
        </div>
      </div>
      <div className='report-basic-info-title'>
        {title}
      </div>
      <div className='report-basic-info-author'>
      {t('reportedBy')} {author}
      </div>
    </>
  );
}

export default ReportBasicInfo;
