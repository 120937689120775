import '../../../styles/index.scss';
import React, { useEffect, useState } from 'react';
import ReactMapboxGl, {
  Layer, ZoomControl, Source, Cluster, Marker,
} from 'react-mapbox-gl';
import DrawControl from 'react-mapbox-gl-draw';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ReactComponent as LayersIcon } from '../../../assets/images/layers.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import CustomCheckbox from '../../ui/CustomCheckbox/CustomCheckbox';
import MapPopup from './MapPopup';
import mapDispatchToProps from '../../../actions/index';
import CustomRadio from '../../ui/CustomRadio/CustomRadio';
import { MAPBOX_SECRET_KEY } from '../../../shared/consts';
import { history } from "../../../index";
import { isEmpty, isDemo } from '../../../utils';
import t from 'prop-types';
import { findTranslation } from '../../../utils/translationsUtils';

const MAP = 'map';
const SATELLITE = 'satelitte';

const Map = ReactMapboxGl({
  attributionControl: false,
  trackResize: true,
  accessToken: MAPBOX_SECRET_KEY,
});

function ReportsMap(props) {
  const { t } = useTranslation();
  const [selectedReportPopup, setSelectedReportPopup] = useState(0);
  const [reportsToShow, setReportsToShow] = useState([]);
  const [searchMap, setSearchMap] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showLayers, setShowLayers] = useState(false);
  const [mapType, setMapType] = useState(MAP);
  const [layers, setLayers] = useState([]);
  const [alreadyCentered, setAlreadyCentered] = useState(false);
  const [latitudeMap, setLatitudeMap] = useState(-0.2416815);
  const [longitudeMap, setLongitudeMap] = useState(51.5285582);
  const [zoomMap, setZoomMap] = useState([11]);

  // Fetch map options
  useEffect(() => {
    if (isEmpty(props?.options)) props.getOptions();
  }, []);

  // Set map options
  useEffect(() => {

    if(props?.options?.latitude !== undefined) {
      const lat = parseFloat(props?.options?.latitude?.value);
      if(latitudeMap !== lat) setLatitudeMap(lat);
    }

    if(props?.options?.longitude !== undefined) {
      const lng = parseFloat(props?.options?.longitude?.value);
      if (longitudeMap !== lng) setLongitudeMap(lng);
    }

    if(props?.options?.zoom  !== undefined) {
      const z = [props?.options?.zoom?.value];
      if (zoomMap !== z) setZoomMap(z);
    }

    setAlreadyCentered(false);
  }, [props.options]);

  // Receive layers (but set them inactive)
  useEffect(() => {
    const newLayers = props.layers.map((elem) => ({ ...elem, active: false }));
    setLayers(newLayers);
  }, [props.layers]);

  // Receive reports
  useEffect(() => {
    const mapTakenCoordinates = [];
    const checkedReports = props.reports.map((feature) => {
      let finishLatitude = parseFloat(feature.latitude);
      let finishLongitude = parseFloat(feature.longitude);

      const noisedLatitude = parseFloat(feature.latitude).toFixed(4);
      const noisedLongitude = parseFloat(feature.longitude).toFixed(4);
      let prevCoordinate = `${noisedLatitude},${noisedLongitude}`;

      while (mapTakenCoordinates.includes(prevCoordinate)) {
        finishLatitude = (parseFloat(feature.latitude) + (Math.random() * 0.00006) - 0.00003);
        finishLongitude = (parseFloat(feature.longitude) + (Math.random() * 0.00006) - 0.00003);
        prevCoordinate = `${finishLatitude.toFixed(5)},${finishLongitude.toFixed(5)}`;
      }
      mapTakenCoordinates.push(prevCoordinate);

      return ({
        ...feature, longitude: finishLongitude, latitude: finishLatitude, originalLatitude: feature.latitude, originalLongitude: feature.longitude,
      });
    });
    setReportsToShow(checkedReports);
  }, [props.reports]);


  // Show Layers
  useEffect(() => {
    if (showLayers) setAlreadyCentered(true);
  }, [showLayers]);

  const styles = {
    clusterMarker: {
      width: 28,
      height: 28,
      borderRadius: '50%',
      backgroundColor: '#DB807F',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'black',
      fontSize: 16,
      fontFamily: 'SFPro-Text',
      fontWeight: 'bold',

    },
    marker: {
      width: 16,
      height: 16,
      borderRadius: '50%',
      backgroundColor: '#DB807F',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
  };

  const clusterMarker = (
    coordinates,
    pointCount,
  ) => (
    <Marker
      key={coordinates.toString()}
      coordinates={coordinates}
      className="report-map-cluster"
      style={styles.clusterMarker}
    >
      <div>{pointCount}</div>
    </Marker>
  );

  useEffect(() => {
    const showReportPopup = async () => {
      await props.getSelectedReport(selectedReportPopup);
      setAlreadyCentered(true);
      setShowPopUp(true);
    };

    if(selectedReportPopup !== 0) {
      showReportPopup();
    }
  }, [selectedReportPopup]);

  const setLayerActive = (elem, index) => {
    const selectedLayer = layers[index];
    selectedLayer.active = !selectedLayer.active;
    const newLayers = layers.map((layer) => {
      if (elem.id === layer.id) {
        return { ...selectedLayer };
      }
      return { ...layer };
    });
    setLayers([...newLayers]);
  };

  const onDrawCreate = ({ features }) => {
    console.log('onDrawCreate', features);
  };

  const onDrawUpdate = ({ features }) => {
    console.log('onDrawUpdate', features);
  };

  const controls = {
    polygon: true,
    trash: true,
  };

  const getStylesForVectors = (colour, geometry) => {

    const linePaint = {
      'line-color': colour,
      'line-width': 1,
    };

    const fillPaint = {
      'fill-color': colour,
      'fill-outline-color': colour,
      'fill-opacity': 0.3,
    };

    const circlePaint = {
      'circle-color': colour,
      'circle-stroke-color': colour,
    };

    // do we use that?
    /*
    const symbolLayout = {
      'text-field': '{place}',
      'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
      'text-offset': [0, 0.6],
      'text-anchor': 'top',
    };
    const symbolPaint = {
      'text-color': colour,
    };
    */

    switch (geometry) {
      case 'circle':
        return circlePaint;
      case 'fill':
        return fillPaint;
      default:
        return linePaint;
    }
  };

  const getLayerName = (layer) => {
    let layerName = findTranslation(layer?.translations, props.currentLanguage);
    if(!layerName) layerName = findTranslation(layer?.translations, props.defaultLanguage);
    return layerName;
  };

  return (
    <>
      <Map
        style={mapType === MAP ? 'mapbox://styles/mapbox/basic-v9' : 'mapbox://styles/mapbox/satellite-v9'}
        containerStyle={{
          height: '100%',
          width: '100%',
        }}
        className="reports-map"
        zoom={zoomMap}
        renderChildrenInPortal
        center={!alreadyCentered && [longitudeMap, latitudeMap]}
        onStyleLoad={(map) => {
          map?.resize();
        }}
      >
        <Cluster
          ClusterMarkerFactory={clusterMarker}
          zoomOnClick
          zoomOnClickPadding={50}
        >
          {reportsToShow.map((feature) => (
            <Marker
              key={feature.id}
              style={styles.marker}
              coordinates={[feature.latitude, feature.longitude]}
              data-feature={feature}
              onClick={() => {
                setSelectedReportPopup(feature.id);
                setShowPopUp(true);
              }}
            />
          ))}
        </Cluster>
        {layers.map((elem) => {
          if (elem.type === 'vector' && elem.active) {
            return (
              <div key={`vector_${elem.id}`}>
                <div key={`vector_${elem.id}`}>
                  <Source
                    id={elem.id}
                    geoJsonSource={{
                      url: elem.tileset,
                      type: "vector",
                    }}
                  />
                  <Layer
                    id={elem.layername}
                    sourceLayer={elem.layername}
                    sourceId={elem.id}
                    type={elem.geometry}
                    paint={getStylesForVectors(elem.colour, elem.geometry)}
                  />
                </div>
              </div>
            );
          }
          if (elem.type === 'raster' && elem.active) {
            return (
              <div key={`raster_${elem.id}`}>
                <Source
                  id={`${elem.id}_source`}
                  tileJsonSource={{
                    type: 'raster',
                    tiles: [
                      elem.location,
                    ],
                  }}
                />
                <Layer type="raster" id={`${elem.id}_layer`} sourceId={`${elem.id}_source`} />
              </div>
            );
          }
        })}
        {(showPopUp && props.selectedReport) && (
          <MapPopup
            coordinates={[
              parseFloat(props.selectedReport.latitude),
              parseFloat(props.selectedReport.longitude),
            ]}
            title={props.selectedReport.title}
            description={props.selectedReport.content}
            date={props.selectedReport.event_date}
            media={props.selectedReport.media}
            author={props.selectedReport.author}
            viewReport={() => { history.push({ pathname: `/report/${props.selectedReport.id}`, from: history.location.pathname }); }}
            closePopup={() => { setShowPopUp(false); }}
          />
        )}
        {isDemo() && (
          <>
            <div className="reports-map__searchFilter">
              <CustomCheckbox
                label={t('searchInTheMap')}
                checked={searchMap}
                onChange={() => setSearchMap(!searchMap)}
              />
            </div>
            {!searchMap
            && (
              <DrawControl
                style={searchMap ? [{ display: 'none !important' }] : []}
                controls={controls}
                onDrawCreate={onDrawCreate}
                onDrawUpdate={onDrawUpdate}
              />
            )}
          </>
        )}
        <ZoomControl className="reports-map__zoomControl" position="bottom-right" />
      </Map>
      <div className="reports-map__layers__box">
        {showLayers && (
          <div className="reports-map__layers__container">
            <CloseIcon className="reports-map__layers__container-close" onClick={() => setShowLayers(false)} />
            <div className="reports-map__layers__container-label">{t('basemaps')}</div>
            <div className="reports-map__layers__container-radios">
              <CustomRadio label={t('map')} value={MAP} name="group_type" onChange={(e, { value }) => setMapType(value)} checked={mapType === MAP} />
              <CustomRadio label={t('satelitte')} value={SATELLITE} name="group_type" onChange={(e, { value }) => setMapType(value)} checked={mapType === SATELLITE} />
            </div>
            {(layers.length > 0) && (
              <>
                <div className="reports-map__layers__container-label">{t('layers')}</div>
                <div className="reports-map__layers__list">
                  {(layers.map((elem, index) => (
                    <CustomCheckbox
                      onChange={() => setLayerActive(elem, index)}
                      key={`layercheck-${elem.id}`}
                      label={getLayerName(elem)}
                      checked={elem.active}
                      customCheckboxClass="reports-map__layers__list-element" />
                  )))}
                </div>
              </>
            )}
          </div>
        )}
        <div className="reports-map__layers__icon" onClick={() => setShowLayers(!showLayers)}>
          <LayersIcon />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ admin, reports, common }) => ({
  selectedReport: reports.selectedReport,
  options: admin.options,
  layers: admin.layers,
  reports: reports.reports,
  currentLanguage: common.currentLanguage,
  defaultLanguage: common.defaultLanguage,
});

ReportsMap.propTypes = {
  selectedReport: t.object,
  getSelectedReport: t.func.isRequired,
  options: t.object,
  getOptions: t.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsMap);
