import React from 'react';
import '../../../styles/index.scss';
import { isEqual } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import clsx from 'clsx';
import { connect } from 'react-redux';
import mapDispatchToProps from '../../../actions';

function CustomDatePicker({
  locale, placeholder, title, handleChange, value, selectsStart, selectsEnd, startDate, endDate, minDate, maxDate, hasErrors, errorDescription,
}) {
  return (
    <div>
      {title && <div className="text-input__label">{title}</div>}
      <DatePicker
        selected={value}
        onChange={handleChange}
        isClearable
        locale={locale}
        minDate={minDate}
        maxDate={maxDate}
        endDate={endDate}
        startDate={startDate}
        selectsEnd={selectsEnd}
        selectsStart={selectsStart}
        placeholderText={placeholder}
        dayClassName={(date) => {
          return (isEqual(date, value) ? 'custom-datepicker-date-selected' : 'custom-datepicker-date');
        }}
        className={clsx('text-input__input', {
          'text-input__input__error': hasErrors,
        })}
      />
      {hasErrors && errorDescription && (
        <span className="text-input__error" dangerouslySetInnerHTML={{ __html: errorDescription }} />
      )}
    </div>
  );
}

const mapStateToProps = ({ common }) => ({
  locale: common.currentLocale,
});

export default connect(mapStateToProps,  mapDispatchToProps)(CustomDatePicker);
