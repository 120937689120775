import '../../../styles/index.scss';

function TriangleButton({ handler }) {

  return (
    <div className="triangle-button__wrapper" onClick={handler} />
  );
}

export default TriangleButton;
