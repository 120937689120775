import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';

const TwoFactorRoute = ({ component: Component, isLoggedIn, needs2FA, ...rest }) => {
  const routeComponent = (props) =>
    (needs2FA) ? (
      <Component {...props}/>
    ) : (
      <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    );

  return <Route {...rest} render={routeComponent} />;
};

export default TwoFactorRoute;
