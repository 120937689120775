import React, { useEffect } from 'react';
import t from 'prop-types';
import dashboardHoc from '../../DashboardHoc/DashboardHoc';
import StoryForm from '../StoryForm/StoryForm';

function StoryCreate(props) {
  useEffect(() => {
    document.title = 'Timby - Create Story';
  });

  return (
    <StoryForm
      onCloseHandler={() => props.history.push({ pathname: '/stories', from: props.history.location.pathname })}
      onSubmitHandler={() => props.history.push({ pathname: '/stories', from: props.history.location.pathname })}
    />
  );
}

StoryCreate.propTypes = {
  history: t.object.isRequired,
};

export default dashboardHoc(StoryCreate);
