import '../../../styles/index.scss';
import clsx from 'clsx';

function Button({
  text,
  ghost,
  primary,
  medium,
  disabled,
  notClickable,
  secondary,
  warning,
  large,
  handler,
}) {
  return (
    <div className=
      {clsx("button__wrapper", {
        "button__wrapper--ghost": ghost && !disabled && !notClickable,
        "button__wrapper--primary": primary && !disabled && !notClickable,
        "button__wrapper--primary-disabled": primary && disabled,
        "button__wrapper--primary-static": primary && notClickable,
        "button__wrapper--secondary": secondary && !disabled && !notClickable,
        "button__wrapper--secondary-disabled": secondary && disabled,
        "button__wrapper--secondary-static": secondary && notClickable,
        "button__wrapper--warning": warning && !disabled && !notClickable,
        "button__wrapper--warning-disabled": warning && disabled,
        "button__wrapper--warning-static": warning && notClickable,
        "button__wrapper--medium": medium,
        "button__wrapper--large": large,
      })}
      onClick={disabled ? undefined : handler}>
      {text}
    </div>
  );
}

export default Button;
