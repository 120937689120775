import '../../../../styles/index.scss';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared/Button/Button';
import mapDispatchToProps from '../../../../actions/index.js';
import SelectBox from '../../../ui/SelectBox/SelectBox';

const FolderModalContent = (props) => {
  const { t } = useTranslation();
  const { onClose } = props;
  const [selectedFolders, setSelectedFolders] = useState([]);

  const addReportToFolders = async() => {
    const response = await props.addReportToFolder(selectedFolders, props.selectedReport);
    if (response){
      setSelectedFolders([]);
      onClose();
    }
  };

  const handleCloseModal = () => {
    setSelectedFolders([]);
    onClose();
  };

  return (
    <div>
      <SelectBox
        placeholder={t('reportAddFolderLabel')}
        title={t('reportFolders')}
        value={selectedFolders}
        multiple
        options={props.folders.map((folder) => { return { value: folder.id, text: folder.name, key: folder.id};})}
        handleChange={(e, {value}) => setSelectedFolders(value)}
      />
      <div className="admin-modal__actions">
        <Button text={t('cancel')} secondary medium handler={() => handleCloseModal()} />
        <Button text={t('save')} disabled={selectedFolders.length === 0} primary medium handler={() => addReportToFolders()} />
      </div>
    </div>
  );
};

const mapStateToProps = ({ folders, reports }) => {
  return {
    folders: folders.folders,
    selectedReport: reports.selectedReport,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FolderModalContent);
