import React, { useState } from 'react';
import t from 'prop-types';
import '../../../styles/index.scss';
import { ReactComponent as OpenEye } from '../../../assets/images/eyeOpen.svg';
import { ReactComponent as ClosedEye } from '../../../assets/images/eyeClosed.svg';
import clsx from 'clsx';

function PasswordInput({
  hasTitle = true,
  title,
  placeholder,
  value = '',
  handleChange,
  ref,
  disabled,
  customInputClass,
  hasError,
  errorDescription = '',
}) {
  const [inputType, setInputType] = useState('password');
  return (
    <div className="password-input__wrapper">
      {hasTitle && <div className="password-input__label">{title}</div>}
      <div className="password-input__input-wrapper">
        <input
          className={clsx('password-input__input', {
            [`${customInputClass}`]: !!customInputClass,
            'text-input__input__error': hasError,
          })}
          value={value}
          type={inputType}
          onChange={handleChange}
          disabled={disabled}
          ref={ref}
          autoCorrect="none"
          autoComplete="none"
          spellCheck={false}
          placeholder={placeholder}
        />
        <div className="password-input__icon">
          {(inputType === 'password') && (
            <OpenEye className="password-input__icon-svg" onClick={() => { setInputType('text'); }} />
          )}
          {(inputType === 'text') && (
            <ClosedEye className="password-input__icon-svg" onClick={() => { setInputType('password'); }} />
          )}
        </div>
      </div>
      {hasError && errorDescription && (
        <span className="text-input__error" dangerouslySetInnerHTML={{ __html: errorDescription }} />
      )}
    </div>
  );
}

PasswordInput.propTypes = {
  hasTitle: t.bool,
  title: t.string,
  placeholder: t.string,
  value: t.string,
  handleChange: t.func,
  ref: t.object,
  disabled: t.bool,
  customInputClass: t.string,
};

export default PasswordInput;
