import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import mapDispatchToProps from '../../../actions/index';
import FormContainer from '../FormContainer/FormContainer';
import TextInput from '../../shared/TextInput/TextInput';
import Button from '../../shared/Button/Button';
import loginHoc from '../LoginHoc/LoginHoc';
import LinkRef from '../LinkRef/LinkRef';

let TOKEN_INTERVAL = undefined;
const TOKEN_TIMER = 45;
const TwoFactorCode = (props) => {
  const [code, setCode] = useState(undefined);
  const [errors, setErrors] = useState({});
  const [remember2fa, setremember2fa] = useState(false);
  const [sendedTokenAt, setSendedTokenAt] = useState(0);

  useEffect(() => {
    if (TOKEN_INTERVAL !== undefined && sendedTokenAt === 0) {
      clearInterval(TOKEN_INTERVAL);
      TOKEN_INTERVAL = undefined;
    }
  }, [sendedTokenAt]);

  const { t } = useTranslation();

  const submitAction = async(code, remember2fa) => {
    const response = await props.twoFactorSubmit(code, remember2fa);

    if (!response.success) {
      setErrors(response.errors);
    } else {
      setErrors({});
    }
  };

  const resendToken = async () => {
    setSendedTokenAt(TOKEN_TIMER);
    TOKEN_INTERVAL = setInterval(() => {
      setSendedTokenAt((sendedTokenAt) => sendedTokenAt - 1);
    }, 1000);
    const response = await props.twoFactorSend();
    if (!response.success) {
      clearInterval(TOKEN_INTERVAL);
      setSendedTokenAt(0);
    }
  };

  const timer = sendedTokenAt > 0 ? ` (${sendedTokenAt})` : '';

  return (
    <FormContainer
      title={t('twoFactorCode')}
      subtitle={t('twoFactorCodeSubtitle')}
      inputs={(
        <>
          <TextInput
            value={code}
            handleChange={(event) => { setCode(event.target.value);}}
            placeholder={t('enterCodePlaceholder')}
            customWrapperClass="twofactor-numberinput"
            hasError={!!errors.code}
            errorDescription={errors.code}
            title={t('enterCode')}
            type="number"
          />
          {/*
            <div className='twofactor-checkbox'>
              <CustomCheckbox checked={remember2fa} onChange={() => setremember2fa(!remember2fa)} label={t("twoFactorCodeCheckbox")}/>
            </div>
          */}
        </>
      )}
      submit={(
        <>
          <Button text={t('twoFactorCodeSubmit')} primary large handler={() => submitAction(code, remember2fa)} />
          <LinkRef
            text={t('twoFactorCodeLink') + timer}
            disabled={sendedTokenAt > 0}
            handler={() => { resendToken(); }}
          />
        </>
      )}
    />
  );
};

export default loginHoc(connect(null, mapDispatchToProps)(TwoFactorCode));
