import React from 'react';
import t from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';
import Button from '../../shared/Button/Button';

function DeleteModal({
  actionName, header, subtitle, open, onClose, onOpen, customModal, hasTrigger, deleteAction,
}) {
  const { t } = useTranslation();
  return (
    <Modal
      className={`delete-modal__wrapper ${customModal}`}
      closeIcon
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      trigger={hasTrigger && <Button text={actionName} primary medium handler={onOpen} />}
    >
      <div className="delete-modal__container">
        <div className="delete-modal__container-header">
          <div className="delete-modal__container-header-title">
            {header}
          </div>
          {subtitle
            && (
            <div className="delete-modal__container-header-subtitle">
              {subtitle}
              <br />
              {t('deleteWarning')}
            </div>
            )}
        </div>
        <Modal.Content>
          <div className="delete-modal__container-actions">
            <Button text={t('cancel')} secondary medium handler={onClose} />
            <Button text={t('deleteSuccess')} warning medium handler={deleteAction} />
          </div>
        </Modal.Content>
      </div>
    </Modal>
  );
}

DeleteModal.propTypes = {
  actionName: t.string,
  header: t.string,
  subtitle: t.string,
  open: t.bool,
  onClose: t.func,
  onOpen: t.func,
  customModal: t.string,
  hasTrigger: t.bool,
  deleteAction: t.func,
};

DeleteModal.defaultProps = {
  actionName: '',
  header: '',
  subtitle: '',
  open: false,
  onClose: () => {},
  onOpen: () => {},
  customModal: '',
  hasTrigger: false,
  deleteAction: () => {},
};

export default DeleteModal;
