import {
  ADD_FOLDER, ADD_REPORT_TO_FOLDER, GET_FOLDERS, SELECT_FOLDER, UNSELECT_FOLDER,
} from '../types';

/* folders structure
  {
    id: ''
    name: '',
    color: '',
    reports: []?
  }
*/

const initialState = {
  folders: [],
  selectedFolder: null,
};

const foldersReducer = (state = initialState, action) => {
  switch (action.type) {
    case(GET_FOLDERS): {
      const folders = action.payload.folders;
      return {
        ...state,
        folders: folders,
      };
    }

    case (ADD_FOLDER): {
      const newFolder = action.payload.folder;
      return {
        ...state,
        folders: [...state.folders, newFolder],
      };
    }

    case (SELECT_FOLDER): {
      return {
        ...state,
        selectedFolder: action.payload,
      };
    }

    case (UNSELECT_FOLDER): {
      return {
        ...state,
        selectedFolder: null,
      };
    }

    case (ADD_REPORT_TO_FOLDER): {
      const { foldersId, selectedReport } = action.payload;
      const updatedFolders = state.folders.map((folder) => {
        if (foldersId.includes(folder.id)){
          const alreadyAdded = folder.reports.find((report) => {
            return (report.id === selectedReport.id);
          });
          if (!alreadyAdded){
            folder.reports = [...folder.reports, selectedReport];
          }
        }
        return folder;
      });

      return {
        ...state,
        folders: [...updatedFolders],
      };
    }

    default:
      return state;
  }
};

export default foldersReducer;
