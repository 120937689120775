import React, { useState } from 'react';
import { connect } from 'react-redux';
import t from 'prop-types';
import '../../../styles/index.scss';
import { useTranslation } from 'react-i18next';
import RoundedIcon from '../../shared/RoundedIcon/RoundedIcon';
import mapDispatchToProps from '../../../actions/index';
import { ReactComponent as EditIcon } from '../../../assets/images/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/delete.svg';
import AdminTagElement from './tagElement/AdminTagElement';
import AdminTagCreateForm from './forms/AdminTagCreateForm';
import AdminModal from '../AdminModal/AdminModal';
import DeleteModal from '../DeleteModal/DeleteModal';

const AdminTagsGroupContainer = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const deleteTagGroup = async () => {
    const result = await props.deleteTagGroup(props.id);
    if (result) {
      setOpenDelete(false);
    }
  };

  return (
    <div className="admin_tagsgroups_container">
      {/* Tags Groups */}
      <div className="admin_tagsgroups_title-box">
        <span className="admin_tagsgroups_title-box__text">
          { /* GroupTags Title */}
          {props.title}
        </span>
        <div className="admin_tagsgroups_title-box__icons">
          <RoundedIcon SvgComponent={EditIcon} hover border handler={() => props.onEdit()} />
          <RoundedIcon SvgComponent={DeleteIcon} hover border handler={() => setOpenDelete(true)} />
        </div>
      </div>
      <div className="admin_tagsgroups_content-box">
        {/* Tag View */}
        {props.childTags.map((tag) => (
          <AdminTagElement
            tag={tag}
            key={tag.id}
            groupTagId={props.id}
            groupType={props.type}
          />
        ))}
        {/* Tags Add */}
        {((props.type === 'text' && props.childTags.length === 0) || props.type !== 'text') && (
          <div
            onClick={() => setOpen(true)}
            role="button"
            tabIndex={-999}
            onKeyDown={() => setOpen(true)}
            className="admin_tagsgroups_content-box-addcontainer"
          >
            {props.type === 'text' ? (
              <span className="admin_tagsgroups_content-box-addtext">
                +&nbsp;
                {t('enterPromptText')}
              </span>
            ) : (
              <span className="admin_tagsgroups_content-box-addtext">
                +&nbsp;
                {t('addNew')}
              </span>
            )}
          </div>
        )}
      </div>
      <AdminModal
        hasTrigger={false}
        onClose={() => setOpen(false)}
        open={open}
        onOpen={() => setOpen(true)}
        header={`${t('createTag')} ${props.formTitle}`}
      >
        <AdminTagCreateForm
          onClose={() => setOpen(false)}
          groupType={props.type}
          groupId={props.id}
        />
      </AdminModal>
      <DeleteModal
        onClose={() => setOpenDelete(false)}
        open={openDelete}
        onOpen={() => setOpenDelete(true)}
        hasTrigger={false}
        header={t('deleteTagGroup')}
        subtitle={t('deleteTagGroupDescription')}
        deleteAction={() => deleteTagGroup()}
      />
    </div>
  );
};

AdminTagsGroupContainer.propTypes = {
  deleteTagGroup: t.func.isRequired,
  id: t.string.isRequired,
  formTitle: t.string.isRequired,
  onEdit: t.func.isRequired,
  childTags: t.array.isRequired,
  title: t.string.isRequired,
  type: t.string.isRequired,
};

export default connect(null, mapDispatchToProps)(AdminTagsGroupContainer);
