import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import mapDispatchToProps from '../../../actions/index';
import FormContainer from '../FormContainer/FormContainer';
import TextInput from '../../shared/TextInput/TextInput';
import Button from '../../shared/Button/Button';
import loginHoc from '../LoginHoc/LoginHoc';

const SetPassword = (props) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    props.checkToken();
    props.getPasswordErrors(newPassword, confirmationPassword);
  }, []);

  const passwordSecurityChecks = {
    minLength: { text: t('passwordReq1'), error: false },
    upperCaseRequired: { text: t('passwordReq2'), error: false },
    downCaseRequired: { text: t('passwordReq5'), error: false },
    numberRequired: { text: t('passwordReq3'), error: false },
    specialCharRequired: { text: t('passwordReq4'), error: false },
  };

  props.passwordErrors.forEach((passwordError) => {
    if (passwordSecurityChecks[passwordError]) {
      passwordSecurityChecks[passwordError].error = true;
    }
  });

  const changeNewPassword = (event) => {
    setNewPassword(event.target.value);
    props.getPasswordErrors(event.target.value, confirmationPassword);
  };

  const changeConfirmationPassword = (event) => {
    setConfirmationPassword(event.target.value);
  };

  return (
    <FormContainer
      title={t('createNewPassword')}
      validations={Object.values(passwordSecurityChecks)}
      inputs={(
        <>
          <TextInput
            value={newPassword}
            handleChange={changeNewPassword}
            placeholder={t('newPasswordPlaceholder')}
            title={t('newPassword')}
            type="password"
          />
          <TextInput
            value={confirmationPassword}
            handleChange={changeConfirmationPassword}
            placeholder={t('reEnterNewPasswordPlaceholder')}
            title={t('reEnterNewPassword')}
            type="password"
          />
        </>
      )}
      submit={
        <Button text={t('createNewPasswordSubmit')} primary large handler={() => props.resetPassword(newPassword, confirmationPassword)} />
      }
    />
  );
};

const mapStateToProps = ({ user }) => ({
  passwordErrors: user.passwordErrors,
});

export default loginHoc(connect(mapStateToProps, mapDispatchToProps)(SetPassword));
