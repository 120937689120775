import '../../../styles/index.scss';
import React, { useEffect, useRef, useState } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import CustomDatePicker from '../../shared/CustomDatePicker/CustomDatePicker';
// import { ReactComponent as CalendarIcon } from '../../../assets/images/calendar.svg';
import Button from '../../shared/Button/Button';
import mapDispatchToProps from '../../../actions/index';
import CustomCheckbox from '../../ui/CustomCheckbox/CustomCheckbox';
import SearchBar from '../../shared/SearchBar/SearchBar';
import ReportInfo from '../ReportInfo/ReportInfo';
import ExportReportDatePicker from '../../shared/ExportReportDatePicker/ExportReportDatePicker';

const AdminExportReport = (props) => {
  const { t } = useTranslation();
  const [selectedReportIds, setSelectedReportIds] = useState([]);
  const [filteredReports, setFilteredReports] = useState(props.reports);
  const reportList = useRef(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const onChange = (dates) => {
    if (dates === null) {
      setStartDate(null);
      setEndDate(null);
      return;
    }
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const onScroll = () => {
    if (reportList.current) {
      const { scrollTop, scrollHeight, clientHeight } = reportList.current;
      if ((scrollTop + clientHeight === scrollHeight) && props.reports.length !== 0) {
        props.getReports();
      }
    }
  };

  useEffect(() => {
    props.getReports();

    document.title = 'Timby - Export Reports';
  }, []);

  useEffect(() => {
    setFilteredReports(props.reports);
  }, [props.reports]);

  const addReportToSelectedList = (reportId) => {
    let updatedSelectedReports = selectedReportIds;
    if (selectedReportIds.find((elem) => (elem === reportId)) !== undefined) {
      updatedSelectedReports = selectedReportIds.filter((elem) => (elem !== reportId));
    } else {
      updatedSelectedReports.push(reportId);
    }
    setSelectedReportIds([...updatedSelectedReports]);
  };

  const checkReportIsSelected = (reportId) => (
    selectedReportIds.find((elem) => (elem === reportId)) !== undefined
  );

  const checkAllReportsAreSelected = () => (
    selectedReportIds.length === props.reports.length
  );

  const onChangeAllReport = () => {
    if (checkAllReportsAreSelected()) {
      return setSelectedReportIds([]);
    }
    const allIds = props.reports.map((elem) => (elem.id));
    return setSelectedReportIds([...allIds]);
  };

  const searchReports = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    const searchResults = inputLength === 0 ? props.reports : props.reports.filter(el =>
        el.title.toLowerCase().indexOf(inputValue) !== -1
        || el.content.toLowerCase().indexOf(inputValue) !== -1
    );

    setFilteredReports([...searchResults]);
  };

  return (
    <div className="admin-container__content whitepagescrollbar">
      <div className="max-body-width">
        <div className="admin-container-header">
          <h1 className="admin-container-header-title">
            {t('selectReportToExport')}
          </h1>
          <div className="admin-container-header-buttons">
            <Button primary medium text={t('exportAll')} handler={() => {}} />
          </div>
        </div>
        <div className="admin-container-body">
          <div className="admin-export-report-halfcontainer">
            <div className="admin-export-report-search">
              <div className="admin-export-report-search-bar">
                <CustomCheckbox checked={checkAllReportsAreSelected()} onChange={() => onChangeAllReport()} customCheckboxClass="admin-export-report-search-bar-checkbox" />
                <SearchBar label={t('reportSearch')} handler={searchReports} />
              </div>
              <div className="admin-export-report-search-date">
                <ExportReportDatePicker
                  handleChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  />
              </div>
            </div>
            <div className="admin-export-report-results-container defaultscrollbar" ref={(event) => { reportList.current = event; }} onScroll={() => { onScroll(); }}>
              {filteredReports.map((elem) => {
                return (
                  <div
                    className="admin-export-report-results"
                    key={`admin-export-report-${elem.id}`}
                    onClick={() => addReportToSelectedList(elem.id)}
                    onKeyDown={() => addReportToSelectedList(elem.id)}
                    role="button"
                    tabIndex={-9999}
                  >
                    <CustomCheckbox checked={checkReportIsSelected(elem.id)} onChange={() => {}} customCheckboxClass="admin-export-report-results-checkbox" />
                    <ReportInfo
                      verified={parseInt(elem.verified, 10) !== 0}
                      new={elem.new}
                      title={elem.title}
                      description={elem.content}
                      date={elem.event_date}
                    />
                  </div>
                );
              })}
            </div>
            <div className="admin-export-report-exportbutton">
              <Button primary medium disabled={selectedReportIds.length === 0} text={t('exportAction')} handler={() => {}} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AdminExportReport.propTypes = {
  getReports: t.func.isRequired,
  reports: t.array.isRequired,
};

const mapStateToProps = ({ reports }) => ({
  reports: reports.reports,
});

AdminExportReport.displayName = 'AdminExportReport';

export default connect(mapStateToProps, mapDispatchToProps)(AdminExportReport);
