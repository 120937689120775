import React from 'react'
import { Radio } from 'semantic-ui-react'
import clsx from 'clsx';

const Toggle = ({ label, value, handleChange, customLabelClass, inverse=false, disabled, vertical=false }) => (
  <div className={clsx('toggle__wrapper', {
      'toggle__wrapper--vertical': vertical,
    })}>
    {!inverse && (
      <div className={`toggle__label ${customLabelClass}`}>
        {label}
      </div>
    )}
    <Radio
      className="toggle__element"
      checked={value}
      onChange={handleChange}
      toggle
      disabled={disabled}
    />
    {inverse && (
      <div className={`toggle__label toggle__label-inverse ${customLabelClass}`}>
        {label}
      </div>
    )}
  </div>
);

export default Toggle
