import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import t from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import '../../../styles/index.scss';
import mapDispatchToProps from '../../../actions/index';
import Collapsible from '../../ui/Collapsible/Collapsible';
import SelectBox from '../../ui/SelectBox/SelectBox';
import { history } from '../../../index';
import Button from '../../shared/Button/Button';
import CustomDatePicker from '../../shared/CustomDatePicker/CustomDatePicker';
import DynamicTagSelect from '../../shared/DynamicTagSelect/DynamicTagSelect';
import { isUserReader, isDemo } from '../../../utils';
import { formatDate } from '../../../utils/dateUtils';
import { findTranslation } from '../../../utils/translationsUtils';
// #hide_for_2.0
// import AdminModal from '../AdminModal/AdminModal';
// import { ReactComponent as CreateFolder } from '../../../assets/images/create_folder.svg';
// import FolderElement from '../FolderElement/FolderElement';
// import CreateFolderContent from './CreateFolderContent';

// folders, getFolders, selectFolder, selectedFolder,
function FiltersPanel({
  options, showPanel, handleShow, createButton,
  userInfo, users, defaultLanguage, currentLanguage,
  groupTags, tags, selectedTags, getTags, getTagGroups, getUsers, filterTaxonomies,
  getReports, applyFilters, resetFilters,
}) {
  const { t } = useTranslation();

  // #hide_for_2.0
  // const [showFolders, setShowFolders] = useState(true);
  // const [showFolderMenu, setShowFolderMenu] = useState(false);
  // const [createFolder, setCreateFolder] = useState(false);
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const renderContent = (elem) => elem.content;
  const renderHeader = (elem) => elem.header;

  const [organizationsEnabled, setOrganizationsEnabled] = useState(Number(options?.organizations_shown?.value) || false);
  const [assignmentEnabled, setAssignmentEnabled] = useState(Number(options?.assignment_enabled?.value) || false);
  const [urgencyEnabled, setUrgencyEnabled] = useState(Number(options?.urgency_enabled?.value) || false);
  const [lifecycleEnabled, setLifecycleEnabled] = useState(Number(options?.lifecycle_enabled?.value) || false);

  useEffect(() => {
    setOrganizationsEnabled(Number(options?.organizations_shown?.value));
  }, [options?.organizations_shown?.value]);

  useEffect(() => {
    setAssignmentEnabled(Number(options?.assignment_enabled?.value));
  }, [options?.assignment_enabled?.value]);

  useEffect(() => {
    setUrgencyEnabled(Number(options?.urgency_enabled?.value));
  }, [options?.urgency_enabled?.value]);

  useEffect(() => {
    setLifecycleEnabled(Number(options?.lifecycle_enabled?.value));
  }, [options?.lifecycle_enabled?.value]);

  // filters count (excluding kwd search, but should we?)
  const nbFilters = Object.keys(selectedTags).filter(k => k !== 'search').length;

  const selectUsers = users.map((user) => ({
    text: user.username,
    key: user.username,
    value: user.id,
  }));

  const selectOrganizations = userInfo.organizations.map((org) => ({
    text: org.name,
    value: org.id,
    key: org.id,
  }));

  // filtered out to remove text type
  const selectGroupTags = groupTags.filter(g => g.type !== 'text');

  const LIFECYCLES = [
    { text: t('lifecycle_new'), key: 'new', value: 'new' },
    { text: t('lifecycle_started'), key: 'inprogress', value: 'inprogress' },
    { text: t('lifecycle_closed'), key: 'closed', value: 'closed' },
  ];

  const URGENCY_STATES = [
    { text: t('urgency_low'), value: 'minor', key: 'minor' },
    { text: t('urgency_medium'), value: 'moderate', key: 'moderate' },
    { text: t('urgency_high'), value: 'critical', key: 'critical' },
  ];

  const VISIBILITY = [
    { text: t('visibility_public'), value: 'public', key: 'public' },
    { text: t('visibility_private'), value: 'private', key: 'private' },
  ];

  const VERIFIED = [
    { text: t('status_verified'), value: '1', key: 'verified' },
    { text: t('status_unverified'), value: '0', key: 'unverified' },
  ];

  const handleApplyFilters = () => {
    applyFilters();
    getReports();
  };

  const handleResetFilters = () => {
    setDateStart(null);
    setDateEnd(null);
    resetFilters();
    getReports();
  };

  const handleChangeDateStart = (value) => {
    setDateStart(value);
    if(value !== null) {
      let date = formatDate(value);
      filterTaxonomies('date_start', date.toString());
    } else {
      filterTaxonomies('date_start', null);
    }
  };

  const handleChangeDateEnd = (value) => {
    setDateEnd(value);
    if(value !== null) {
      let date = formatDate(value);
      filterTaxonomies('date_end', date.toString());
    } else {
      filterTaxonomies('date_end', null);
    }
  };

  useEffect(() => {
    if (groupTags.length === 0) getTagGroups();
    if (tags.length === 0) getTags();
    if (users.length === 0) getUsers();
    // #hide_for_2.0
    // if (folders.length === 0) getFolders();
  }, []);

  const elements = [
    {
      header:
        <>
          <Icon name="chevron right" />
          {t('date')}
        </>,
      content:
        <div className="filters-panel__filters-date">
          <div className="filters-panel__filters-startdate">
            <CustomDatePicker
              value={dateStart}
              clearable
              handleChange={handleChangeDateStart}
              placeholder={t('startDate')}
              selectsStart
              startDate={dateStart}
              maxDate={dateEnd || new Date()}
              endDate={dateEnd || null }
            />
          </div>
          <div className="filters-panel__filters-enddate">
            <CustomDatePicker
              value={dateEnd}
              clearable
              handleChange={handleChangeDateEnd}
              placeholder={t('endDate')}
              minDate={dateStart}
              selectsEnd
              startDate={dateStart}
              maxDate={dateEnd || new Date()}
              endDate={dateEnd || null }
            />
          </div>
        </div>,
    },
    {
      header:
        <>
          <Icon name="chevron right" />
          {t('reportStatus')}
        </>,
      content:
        <>
          {!!lifecycleEnabled &&(
            <SelectBox
              title={t('reportLifecycle')}
              placeholder={t('reportLifecycle')}
              options={LIFECYCLES}
              clearable
              multiple
              value={selectedTags.lifecycle || []}
              handleChange={(e, { value }) => filterTaxonomies('lifecycle', value)}
            />
          )}
          {!!urgencyEnabled &&(
            <SelectBox
              title={t('urgency')}
              placeholder={t('urgency')}
              options={URGENCY_STATES}
              clearable
              multiple
              value={selectedTags.urgency || []}
              handleChange={(e, { value }) => filterTaxonomies('urgency', value)}
            />
          )}
          <SelectBox
            title={t('verificationStatus')}
            placeholder={t('verificationStatus')}
            options={VERIFIED}
            clearable
            multiple
            value={selectedTags.verified || []}
            handleChange={(e, { value }) => filterTaxonomies('verified', value)}
          />
          {isDemo() && (
            <SelectBox
              title={t('visibility')}
              placeholder={t('visibility')}
              options={VISIBILITY}
              clearable
              multiple
              value={selectedTags.visibility || []}
              handleChange={(e, { value }) => filterTaxonomies('visibility', value)}
            />
          )}
        </>,
    },
    {
      header:
        <>
          <Icon name="chevron right" />
          {t('reportAssignment')}
        </>,
      content:
        <>
          <SelectBox
            title={t('reportAuthor')}
            placeholder={t('reportAuthor')}
            options={selectUsers}
            clearable
            multiple
            value={selectedTags.authors || []}
            handleChange={(e, { value }) => filterTaxonomies('authors', value)}
          />
          {!!assignmentEnabled &&(
          <SelectBox
            title={t('reportAssignee')}
            placeholder={t('reportAssignee')}
            options={selectUsers}
            clearable
            multiple
            value={selectedTags.assignee || []}
            handleChange={(e, { value }) => filterTaxonomies('assignee', value)}
          />
          )}
          {!!organizationsEnabled &&(
            <SelectBox
              title={t('organization')}
              placeholder={t('organization')}
              options={selectOrganizations}
              clearable
              multiple
              value={selectedTags.organizations || []}
              handleChange={(e, { value }) => filterTaxonomies('organizations', value)}
            />
          )}
        </>,
    },
    selectGroupTags.length > 1 ? {
      header:
        <>
          <Icon name="chevron right" />
          {t('myTags')}
        </>,
      content:
        <>
          {
            selectGroupTags.map((taxonomy, i) => {
              const allTags = tags.filter(e => { return  e.group === taxonomy.id; });

              let groupTagName = findTranslation(taxonomy?.translations, currentLanguage);
              if(!groupTagName) groupTagName = findTranslation(taxonomy?.translations, defaultLanguage);

              return (
                <DynamicTagSelect
                  key={i}
                  title={groupTagName}
                  placeholder={groupTagName}
                  tagGroup={taxonomy}
                  tagOptions={allTags}
                  onChange={filterTaxonomies}
                  selectedValues={selectedTags[taxonomy.id]}
                  alwaysMulti
                />
              );
            })
          }
        </>,
    } : null,
  ];

  const filledElements = elements.filter((elem) => elem !== null);
  return (
    <div className={clsx('filters-panel__wrapper', {
      'filters-panel__wrapper--hidden': !showPanel,
    })}
    >
      <div className="filters-panel__container">
        <div className="filters-panel__navigation">
          <div className="filters-panel__title">
            {t('reportsTitle')}
          </div>
          <div
            className="filters-panel__toggle"
            onClick={handleShow}
            role="button"
            onKeyDown={() => handleShow}
            tabIndex={-1}
          >
            <Icon name="caret left" />
          </div>
        </div>
        <div className="filters-panel__filters-list defaultscrollbar">
          <div className="filters-panel__filters-list__header">
            <div className='filters-panel__filters-list__header--iconcontainer'>
              <div className="filters-panel__filters-list__header--icon" />
              {t('reportFilter')} ({nbFilters})
            </div>
            <div className='filters-panel__filters-list__header--buttoncontainer'>
              <Button
                primary
                small
                text={t('apply')}
                handler={handleApplyFilters}
              />
              <Button
                ghost
                small
                text={t('reset')}
                handler={handleResetFilters}
                className="filters-panel__filters-list__header--resetlink"
              />
            </div>
          </div>
          <Collapsible
            elements={filledElements}
            renderHeader={renderHeader}
            renderContent={renderContent}
          />
          {/*
          // #hide_for_2.0
          <div className="folders__container">
            <div className="folders__header">
              {t('reportFolders')}
              <div className="folders__header__icons">

                <Icon
                  className="folders__header__ellipsis"
                  name="ellipsis horizontal"
                  onClick={() => setShowFolderMenu(!showFolderMenu)}
                />

                <Icon
                  className="folders__header__arrow"
                  name={showFolders ? 'chevron down' : 'chevron right'}
                  onClick={() => setShowFolders(!showFolders)}
                />

              </div>
              {showFolderMenu && (
                <div className="folders__menu">
                  <div
                    role="button"
                    className="folders__menu__add-folder"
                    onClick={() => setCreateFolder(true)}
                    onKeyDown={() => setCreateFolder(true)}
                    tabIndex={-1}
                  >
                    <>
                      <span>{t('createFolder')}</span>
                      <CreateFolder />
                    </>
                  </div>
                </div>
              )}
            </div>
            {showFolders && (
              <div className={clsx('folders__body', {
                'folders__body__menu-open': showFolderMenu,
              })}
              >
                {folders.map((folder) => (
                  <FolderElement
                    title={folder.name}
                    color={folder.color}
                    selected={selectedFolder ? folder.id === selectedFolder?.id : false}
                    key={`folders-${folder.id}`}
                    onClick={() => { selectFolder(folder.id); }}
                  />
                ))}
              </div>
            )}
          </div>
          */}
        </div>
        {/*
        // #hide_for_2.0
        <div className="filters-panel__folders-list" />
        */}
      </div>
      {createButton && !isUserReader(userInfo) && (
        <div className="filters-panel__filter-options">
          <Button large primary text={t('newReport')} handler={() => history.push({ pathname: '/new-report', from: history.location.pathname })} />
        </div>
      )}
      {/*
        // #hide_for_2.0
        <AdminModal
          hasTrigger={false}
          header="Create folder"
          customModal="folder-create-modal"
          onClose={() => setCreateFolder(false)}
          open={createFolder}
          scrolling={false}
          onOpen={() => setCreateFolder(true)}
        >
          <CreateFolderContent
            onClose={() => { setCreateFolder(false); }}
          />
        </AdminModal>
      */}
    </div>
  );
}

/*
// #hide_for_2.0
  folders: t.array.isRequired,
  getFolders: t.func.isRequired,
  selectFolder: t.func.isRequired,
  selectedFolder: t.object,
*/
FiltersPanel.propTypes = {
  showPanel: t.bool.isRequired,
  handleShow: t.func.isRequired,
  createButton: t.bool,
  selectedOrganization: t.string.isRequired,
  userInfo: t.object.isRequired,
  users: t.array.isRequired,
  defaultLanguage: t.string.isRequired,
  currentLanguage: t.string.isRequired,
  groupTags: t.array.isRequired,
  tags: t.array.isRequired,
  selectedTags: t.object.isRequired,
  getTagGroups: t.func.isRequired,
  getTags: t.func.isRequired,
  getUsers: t.func.isRequired,
  filterTaxonomies: t.func.isRequired,
};

FiltersPanel.defaultProps = {
  createButton: true,
};

/*
// #hide_for_2.0
  selectedFolder: folders.selectedFolder,
  folders: folders.folders,
*/
const mapStateToProps = ({
  common, admin, user, tags
}) => ({
  defaultLanguage: common.defaultLanguage,
  currentLanguage: common.currentLanguage,
  selectedTags: tags.selectedTags,
  tags: tags.tags,
  groupTags: tags.groupTags,
  selectedOrganization: admin.selectedOrganizationId,
  users: admin.users,
  options: admin.options,
  userInfo: user.userInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(FiltersPanel);
