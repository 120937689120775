import '../../../styles/index.scss';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';


function FormContainer({ title, inputs, subtitle, validations, submit, handleKeyDown }) {
  const { t } = useTranslation();

  return (
    <div onKeyDown={handleKeyDown} className="form-container__wrapper">
      <div className="form-container__title">
        {title}
      </div>
      {subtitle &&
        <div className="form-container__subtitle">
          {subtitle}
        </div>
      }
      {validations?.length > 0 &&
        <div className="form-container__validation">
          <div className="form-container__validation--title">
            {t("requirements")}
          </div>
          <div className="form-container__validation--list">
            {validations.map((validation, item) => (
              <div className="form-container__validation--element" key={item}>
                <div className={clsx(
                  "form-container__validation--icon",
                  validation.error && "form-container__validation--icon-error"
                )}/>
                <div className="form-container__validation--value">
                  {validation.text}
                </div>
              </div>
            ))}
          </div>
        </div>
      }
      <div className="form-container__inputs">
        {inputs}
      </div>
      <div className="form-container__submit">
        {submit}
      </div>
    </div>
  );
}

export default FormContainer;
