/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from 'react';
import t from 'prop-types';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import ReportInfo from '../../ReportInfo/ReportInfo';

function StoryReportsList({ reports, getMoreReports }) {
  const reportList = useRef(null);
  const onScroll = () => {
    if (reportList.current) {
      const { scrollTop, scrollHeight, clientHeight } = reportList.current;
      if ((scrollTop + clientHeight === scrollHeight) && reports.length !== 0) {
        getMoreReports();
      }
    }
  };

  return (
    <Droppable droppableId="reports-fields">
      {(provided) => (
        <>
          <div
            {...provided.droppableProps}
            ref={(event) => { provided.innerRef(event); reportList.current = event; }}
            className="reports-container__report-list defaultscrollbar"
            onScroll={() => onScroll()}
          >
            {reports.map((elem, i) => {
              return (
                <Draggable draggableId={`reports-${elem.id}`} key={`reports-${elem.id}`} index={i}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <ReportInfo
                        verified={!!parseInt(elem.verified, 10)}
                        new={elem.new}
                        title={elem.title}
                        description={elem.content}
                        date={elem.event_date}
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}
          </div>
          {provided.placeholder}
        </>
      )}
    </Droppable>
  );
}

StoryReportsList.propTypes = {
  reports: t.array.isRequired,
  getMoreReports: t.func.isRequired,
};

export default StoryReportsList;
