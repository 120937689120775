import { GET_MESSAGES, ADD_MESSAGE } from '../types';

const initialState = {
  messages: [],
  offset: 0,
  hasReachedEnd: false,
};

const messagesReducer = (state = initialState, action) => {
  switch (action.type){
    case (GET_MESSAGES): {
      return {
        ...state,
        messages: action.payload.messages,
      };
    }

    case (ADD_MESSAGE): {
      const newMessage = action.payload;
      return {
        ...state,
        messages: [newMessage,...state.messages],
      };
    }

    default:
      return state;
  }
};

export default messagesReducer;
