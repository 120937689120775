import '../../../styles/index.scss';

import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import dashboardHoc from '../DashboardHoc/DashboardHoc';
import FullHeader from '../../ui/FullHeader/FullHeader';
import TextInput from "../../shared/TextInput/TextInput";
import SelectBox from "../../ui/SelectBox/SelectBox";
import Button from "../../shared/Button/Button";
import t from "prop-types";
import {connect} from "react-redux";
import mapDispatchToProps from "../../../actions";
import Toggle from '../../ui/Toggle/Toggle';
import { findOption } from '../../../utils';

function UserContainer(props) {
  const { t } = useTranslation();
  const { userInfo, languages, selectedUser } = props;

  const [errors, setErrors] = useState([]);
  const [username, setUsername] = useState(userInfo?.username || '');
  const [password, setPassword] = useState('');
  const [firstname, setFirstname] = useState(userInfo?.firstname || '');
  const [lastname, setLastname] = useState(userInfo?.lastname || '');
  const [role, setRole] = useState('');
  const [email, setEmail] = useState(userInfo?.email || '');
  const [enable2FA, setEnable2FA] = useState(userInfo?.secondfactor || false);
  const [language, setLanguage] = useState(props?.currentLanguage || '');
  const [initials, setInitials] = useState('');

  useEffect(() => {
    document.title = 'Timby - Profile';

    // better to get the user from the database in case it changed.
    props.getUser(userInfo?.id);
  }, [userInfo?.id]);

  useEffect(() => {
    setUsername(selectedUser?.username);
    setFirstname(findOption('firstname', selectedUser?.options));
    setLastname(findOption('lastname', selectedUser?.options));
    setRole(selectedUser?.role?.name);
    setEmail(findOption('email', selectedUser?.options));
    setEnable2FA(findOption('secondfactor', selectedUser?.options) === 'email');
    setInitials(firstname && lastname
      ? `${firstname.charAt(0)}${lastname.charAt(0)}`.toUpperCase()
      : selectedUser?.username.substring(0, 2).toUpperCase());
  }, [selectedUser]);

  const languagesOptions = languages.map((elem) => ({ text: elem.name, value: elem.locale }));

  const handleUpdateUser = async () => {
    const userData = {
      password: password || undefined
    };

    if(firstname !== userInfo?.firstname) {
      userData.firstname = firstname;
    }
    if(lastname !== userInfo?.lastname) {
      userData.lastname = lastname;
    }
    if(email !== userInfo?.email) {
      userData.email = email;
    }
    if(enable2FA !== userInfo?.secondfactor) {
      userData.secondfactor = enable2FA ? 'email' : 'none';
    }
    if(language !== userInfo?.language) {
      userData.language = language;
    }

    const result = await props.editUser(userInfo?.id, userData);
    if (result.errors) setErrors(result.errors);
  };

  return (
    <>
      <div className="user-container">
        <FullHeader title={t('profile')} />
        <div className="user-container__wrapper whitepagescrollbar">
          <div className="admin_userprofile__box-area whitepagescrollbar">
            <div className="max-body-width">
              <div className="admin_userprofile__container">
                <div className="admin_userprofile__userpic_box">
                  <p className="admin_userprofile__title">{t('mysettings')}</p>
                  <div className="admin_userprofile__userpic">
                    {initials}
                  </div>
                </div>
                <div className="admin_userprofile__form_box">
                  <TextInput
                    value={firstname}
                    handleChange={(event) => setFirstname(event.target.value)}
                    placeholder=""
                    title={t('firstname')}
                    hasError={!!errors.firstname}
                    errorDescription={errors.firstname}
                  />
                  <TextInput
                    value={lastname}
                    handleChange={(event) => setLastname(event.target.value)}
                    placeholder=""
                    title={t('lastname')}
                    hasError={!!errors.lastname}
                    errorDescription={errors.lastname}
                  />
                  <TextInput
                    value={email}
                    handleChange={(event) => setEmail(event.target.value)}
                    placeholder={t('enterEmailPlaceholder')}
                    title={t('email')}
                    type="email"
                    hasError={!!errors.email}
                    errorDescription={errors.email}
                  />
                  <TextInput
                    value={username}
                    placeholder=""
                    title={t('username')}
                    readonly
                  />
                  <TextInput
                    value={role || ''}
                    placeholder=""
                    title={t('role')}
                    readonly
                  />
                  <SelectBox
                    title={t('defaultLanguage')}
                    placeholder="Select a language..."
                    handleChange={(e, { value }) => {
                      setLanguage(value);
                      props.changeLanguage(value);
                    }}
                    options={languagesOptions}
                    value={language}
                    hasError={!!errors.language}
                    errorDescription={errors.language}
                  />
                  <TextInput
                    value={password}
                    handleChange={(event) => setPassword(event.target.value)}
                    placeholder="••••••••••••••••"
                    title={t('password')}
                    type="password"
                    hasError={!!errors.password}
                    errorDescription={errors.password}
                  />
                  <Toggle
                    label={t('enable2FA')}
                    handleChange={() => setEnable2FA(!enable2FA)}
                    value={enable2FA}
                    customLabelClass={'enable2FA'}
                    vertical
                  />
                  <div className="admin_userprofile__form_submit">
                    <Button text={t('saveChanges')} primary large handler={() => handleUpdateUser()} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ admin, user, common }) => ({
  userInfo: user.userInfo,
  languages: common.languages,
  currentLanguage: common.currentLanguage,
  selectedUser: admin.selectedUser,
});

UserContainer.propTypes = {
  editUser: t.func.isRequired,
  userInfo: t.object.isRequired,
  languages: t.array.isRequired,
  currentLanguage: t.string,
};

export default dashboardHoc(connect(mapStateToProps, mapDispatchToProps)(UserContainer));
