import '../../../styles/index.scss';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import clsx from 'clsx';
import Collapsible from '../../ui/Collapsible/Collapsible';
import AdminModal from '../AdminModal/AdminModal';
import mapDispatchToProps from '../../../actions/index';
import AdminOrganizationContent from './AdminOrganizationContent';
import { ReactComponent as EditIcon } from '../../../assets/images/edit.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import RoundedIcon from '../../shared/RoundedIcon/RoundedIcon';

const AdminOrganizationsList = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    document.title = 'Timby - Organizations';
  }, []);

  useEffect(() => {
    props.getOrganizations();
  }, [props.selectedOrganizationId]);

  const renderContent = (elem) => (
    <AdminOrganizationContent
      organization={elem}
      edit
      onClose={() => setOpen(false)}
    />
  );

  const renderHeader = (elem, editHandler, editableMode) => (
    <div className={clsx('collapsible__header', {
      'collapsible__header-active': editableMode,
    })}
    >
      <div
        role="button"
        tabIndex={-9999}
        onKeyDown={editHandler}
        onClick={editHandler}
        className="collapsible__header__text"
      >
        <Icon name="chevron right" />
        {elem.name}
      </div>
      <div className="collapsible__actions">
        <RoundedIcon
          SvgComponent={editableMode ? CloseIcon : EditIcon}
          hover
          border
          handler={editHandler}
        />
      </div>
    </div>
  );

  return (
    <div className="admin-container__content whitepagescrollbar">
      <div className="max-body-width">
        <div className="admin-container-header">
          <h1 className="admin-container-header-title">
            {t('organizations')}
          </h1>
          <div className="admin-container-header-buttons">
            <AdminModal
              actionName={t('addNewOrganization')}
              onClose={() => setOpen(false)}
              open={open}
              customModal="admin-modal__wrapper__mini"
              onOpen={() => setOpen(true)}
              header={t('createOrganization')}
            >
              <AdminOrganizationContent
                edit={false}
                onClose={() => setOpen(false)}
              />
            </AdminModal>
          </div>
        </div>
        <div className="admin-container-body">
          <Collapsible
            bordered
            editableMode
            elements={props?.organizations}
            renderHeader={renderHeader}
            renderContent={renderContent}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ admin }) => ({
  organizations: admin.organizations,
  users: admin.users,
  admin: admin.selectedOrganizationId,
});

AdminOrganizationsList.displayName = 'AdminOrganizationsList';

export default connect(mapStateToProps, mapDispatchToProps)(AdminOrganizationsList);
