/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
// import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import types from 'prop-types';
import clsx from 'clsx';
import '../../../../styles/index.scss';
import TextInput from '../../../shared/TextInput/TextInput';
import Button from '../../../shared/Button/Button';
import CustomRadio from '../../../ui/CustomRadio/CustomRadio';
import mapDispatchToProps from '../../../../actions/index';
import { findTranslation } from '../../../../utils/translationsUtils';

function AdminLayerContent({
  layer, edit = true, onClose, languages, defaultLanguage, editableMode, createLayer, updateLayer,
}) {
  const { t } = useTranslation();
  const [type, setType] = useState(layer?.type || 'vector');
  const [colour, setColour] = useState(layer?.colour || '#000000');
  const [rasterURL, setRasterURL] = useState(layer?.location || '');
  const [vectorURL, setVectorURL] = useState(layer?.tileset || '');
  const [vectorLayerName, setVectorLayerName] = useState(layer?.layername || '');
  const [geometry, setGeometry] = useState(layer?.geometry || null);
  // const [file, setFile] = useState([]);
  const [errors, setErrors] = useState({});

  const getDefaultTranslationState = () => (
    languages.map((elem) => (
      {
        value: layer ? findTranslation(layer.translations, elem.locale) || '' : '',
        name: elem.name,
        locale: elem.locale,
        key: `name_${elem.locale}`,
      }))
  );

  const getFilledTranslations = () => {
    const translationsHash = {};

    layerTranslation.map(elem => {
      translationsHash[elem.locale] = elem.value;
    });

    return translationsHash;
  };

  const [layerTranslation, setLayerTranslation] = useState(getDefaultTranslationState());

  useEffect(() => {
    setLayerTranslation(getDefaultTranslationState());
  }, [languages, defaultLanguage, layer]);

  useEffect(() => {
    if (!editableMode) {
      setType(layer ? layer.type : 'vector');
      setRasterURL(layer ? layer.location : '');
      setErrors({});
    }
  }, [editableMode, layer]);

  /*
  const dropzoneRef = createRef();
  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };
  */

  const handleLayerTranslation = (value, index) => {
    const updatedTranslation = [...layerTranslation];
    const selectedTranslation = updatedTranslation[index];
    selectedTranslation.value = value;
    setLayerTranslation(updatedTranslation);
  };

  const handleUpdateLayer = async () => {
    const layerData = {
      name: getFilledTranslations(),
      type,
    };
    if (type === 'raster') {
      layerData.url = rasterURL;
    }
    if (type === 'vector') {
      layerData.colour = colour;
      layerData.tileset = vectorURL;
      layerData.layername = vectorLayerName;
      layerData.geometry = geometry;
    }

    const result = await updateLayer(layer.id, layerData);
    if (result.success) {
      setErrors({});
      onClose();
    } else {
      setErrors(result.errors);
    }
  };

  const handleCreateLayer = async () => {
    const layerData = {
      name: getFilledTranslations(),
      type,
    };
    if (type === 'vector') {
      layerData.colour = colour;
      layerData.tileset = vectorURL;
      layerData.layername = vectorLayerName;
      layerData.geometry = geometry;
      //layerData.vector = file;
    }
    if (type === 'raster') {
      layerData.url = rasterURL;
    }

    const result = await createLayer(layerData);
    if (result.success) {
      setErrors({});
      onClose();
    } else {
      setErrors(result.errors);
    }
  };

  return (
    <div className={clsx('admin-layer-content__wrapper', {
      'admin-layer-content__wrapper--create': !edit,
    })}
    >
      <div className="admin-layer-content__container">
        { !edit && (
        <div className="admin-layer-content__radio-group">
          <CustomRadio
            label={t('vector')}
            checked={type === 'vector'}
            onChange={() => setType('vector')}
          />
          <CustomRadio
            label={t('raster')}
            checked={type === 'raster'}
            onChange={() => setType('raster')}
          />
        </div>
        )}
        <div className="admin-layer-content__content">
          {type === 'raster'
            && (
            <TextInput
              title={t('rasterHostedUrl')}
              placeholder={t('enterRasterHostedUrl')}
              hasError={!!errors.raster}
              errorDescription={errors.raster}
              value={rasterURL}
              handleChange={(event) => setRasterURL(event.target.value)}
            />
            )}
          {type === 'vector' && (
            <>
              <TextInput
                title={t('vectorTileset')}
                placeholder={t('enterVectorTileset')}
                value={vectorURL}
                hasError={!!errors.vectorTileset}
                errorDescription={errors.vectorTileset}
                handleChange={(event) => setVectorURL(event.target.value)}
              />
              <TextInput
                title={t('layerName')}
                placeholder={t('enterVectorLayerName')}
                hasError={!!errors.vectorLayerName}
                errorDescription={errors.vectorLayerName}
                value={vectorLayerName}
                handleChange={(event) => setVectorLayerName(event.target.value)}
              />
              <TextInput
                title={t('vectorLayerColor')}
                placeholder={t('enterColor')}
                type="color"
                value={colour}
                handleChange={(event) => setColour(event.target.value)}
              />
              <div>
                <div className="admin-layer-content__radio-group__title">
                  {t('vectorLayerGeometry')}
                </div>
                <div className="admin-layer-content__radio-group">
                  <CustomRadio
                    label={t('vectorLayerGeometry_Points')}
                    checked={geometry === 'circle'}
                    onChange={() => setGeometry('circle')}
                  />
                  <CustomRadio
                    label={t('vectorLayerGeometry_Line')}
                    checked={geometry === 'line'}
                    onChange={() => setGeometry('line')}
                  />
                  <CustomRadio
                    label={t('vectorLayerGeometry_Fill')}
                    checked={geometry === 'fill'}
                    onChange={() => setGeometry('fill')}
                  />
                </div>
                {errors.vectorGeometry && (
                  <p className="text-input__error">{errors.vectorGeometry}</p>
                )}
              </div>
            </>
          )}
        </div>
        {/*
        // Not for 2.0
        <div className="admin-layer-content__right">
          {type === 'vector' && !edit
          && (
          <Dropzone
            ref={dropzoneRef}
            noClick
            accept=".geojson, .json, .shp, .shx, .prj, .dbf, .cpg, .kml, .zip"
            onDrop={(acceptedFiles) => setFile(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                className={clsx(
                  'layer-drop__wrapper',
                  { 'layer-drop__wrapper-errors': !!errors.files },
                )}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <div className="layer-drop__header">
                  {t('dragdropLayers')}
                </div>
                <div className="layer-drop__subheader">
                  {t('layerShapeFiles')}
                </div>
                <Button text={t('reportBrowseMedia')} medium secondary handler={openDialog} />
              </div>
            )}
          </Dropzone>
          )}
          {file.length > 0 && (
            <div className="layer-drop__header admin-layer-content__right__status">
              {`Files Uploaded: ${file.length}` }
              <span />
            </div>
          )}
        </div>
        */}
      </div>
      <div className="admin-layer-content__translations">
        <div className="admin-layer-content__header">
          <div className="admin-layer-content__header-title">
            {t('translations')}
          </div>
        </div>
        {layerTranslation.map((elem, index) => (
          <TextInput
            title={`${t('layerName')} - ${elem.name}`}
            placeholder={`${t('enterLayerNameIn')} - ${elem.name}`}
            value={elem.value}
            hasError={!!errors[elem.locale]}
            errorDescription={errors[elem.locale]}
            key={`layer-${index}-${elem.locale}`}
            handleChange={(event) => handleLayerTranslation(event.target.value, index)}
          />
        ))}
      </div>
      <div className={clsx('admin-layer-content__actions', {
        'admin-layer-content__actions--create': !edit,
      })}
      >
        {edit
          && (
          <Button text={t('save')} primary medium handler={() => handleUpdateLayer()}/>
          )}
      </div>
      {!edit
        && (
        <div className="admin-modal__actions">
          <Button text={t('cancel')} secondary medium handler={onClose} />
          <Button text={t('save')} primary medium handler={() => handleCreateLayer()} />
        </div>
        )}
    </div>
  );
}

AdminLayerContent.propTypes = {
  layer: types.object,
  edit: types.bool,
  onClose: types.func.isRequired,
  languages: types.array.isRequired,
  createLayer: types.func.isRequired,
  updateLayer: types.func.isRequired,
  defaultLanguage: types.string.isRequired,
  editableMode: types.bool,
};

export default connect(null, mapDispatchToProps)(AdminLayerContent);
