import '../../../styles/index.scss';
import clsx from 'clsx';

function AnalyticsReportsInfo({ info }) {

  return (
    <div key={info.sector} className="analytics__reports-element">
      <div className="analytics__reports-element-sector">
        {info.sector}
      </div>
      <div className="analytics__reports-element-value">
        {info.value}
      </div>
      <div className={clsx("analytics__panel-element-percentage", {
        "analytics__panel-element-percentage--positive": info.positive,
      })}>
        {info.percentage}
      </div>
    </div>
  );
}

export default AnalyticsReportsInfo;
