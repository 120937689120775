import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import AlertMessage from '../AlertMessage/AlertMessage';
import mapDispatchToProps from '../../../actions/index';

const ALERT_TIMEOUT = 4000;
// Duration of the animation duration of an alert.
// This should be the same value defined as the animation duration in the alert css.
const TIMEOUT_DURATION = 500;

const AlertsContainer = ({ alerts, removeAlert }) => {
  const removeHiddenAlertsRef = useRef(undefined);

  useEffect(() => {
    if (alerts.filter((elem) => (elem.hide)).length > 3 && !removeHiddenAlertsRef.current){

      removeHiddenAlertsRef.current = setTimeout(() => {
        removeHiddenAlertsRef.current = undefined;
        removeAlert('');
      }, 7500);
    }
  }, [alerts]);

  return (
    <div className={'alert-container'}>
      {alerts.map((alert) => {
        const { message, timestamp, type, id } = alert;
        const timedOut = Date.now() - timestamp < ALERT_TIMEOUT + TIMEOUT_DURATION;
        return (
          <CSSTransition
            classNames='alert-message'
            in={timedOut}
            appear={timedOut}
            timeout={300}
            key={id}
          >
            <AlertMessage id={id} type={type}  timeout={ALERT_TIMEOUT + TIMEOUT_DURATION} message={message} hidden={!timedOut}/>
          </CSSTransition>
        );
      })
      }
    </div>
  );
};

const mapStateToProps = ({ alerts }) => ({
  alerts: alerts,
});

export default connect(mapStateToProps,  mapDispatchToProps)(AlertsContainer);
