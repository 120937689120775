import { bindActionCreators } from 'redux';
import * as UserActions from './UserActions';
import * as CommonActions from './CommonActions';
import * as AdminActions from './AdminActions';
import * as TagsActions from './TagsActions';
import * as ReportsActions from './ReportsActions';
import * as MessagesActions from './MessagesActions';
import * as StoriesActions from './StoriesActions';
import * as FoldersActions from './FoldersActions';

const allActions = dispatch => ({ dispatch, ...bindActionCreators(Object.assign({}, 
  UserActions, 
  CommonActions, 
  AdminActions, 
  TagsActions, 
  ReportsActions, 
  MessagesActions, 
  StoriesActions,
  FoldersActions
), dispatch) });

export default allActions
