import { ADD_NEW_MESSAGE, HIDE_MESSAGE, REMOVE_MESSAGES } from "../types";

const initialState = [];

// Message alert body
// { type: error|success|information, message: string, timestamp: timestamp, id: integer }
const alertsReducer = (state = initialState, action) => {
  switch (action.type){
    case ADD_NEW_MESSAGE:
      let newMessage = action.payload;

      // skip same messages sent too close to each others
      if(!state.find((elem) => (elem.message === newMessage.message && (newMessage.timestamp - elem.timestamp <= 1000)))) {
        newMessage.id = Math.floor(Math.random() * 1000);
        while (state.find((elem) => {return (elem.id === newMessage.id); })){
          newMessage.id = Math.floor(Math.random() * 1000);
        }
        newMessage.timestamp = Date.now();
        newMessage.hide = false;
        return [...state, newMessage];
      }
      return state;

    case HIDE_MESSAGE:
      const id = action.payload;
      const element = state.find((elem) => {
        return (elem.id === id);
      });
      const elementIndex = state.indexOf(element);
      if (elementIndex > -1) state[elementIndex].hide = true;

      return [...state];

    case REMOVE_MESSAGES:
      const filteredAlerts = state.filter((elem) => ((
        !elem.hide
      )));

      return [...filteredAlerts];

    default:
      return state;
  }
};

export default alertsReducer;
