import '../../../styles/index.scss';
import clsx from 'clsx';

function AnalyticsPanel({ header, elements }) {
  return (
    <div className="analytics__panel">
      <div className="analytics__panel-header">
        {header}
      </div>
      {elements.map((elem, index) => {
        return (
          <div className="analytics__panel-element" key={`analytics-panel-${index}`}>
            <div className="analytics__panel-element--left">
              <div className="analytics__panel-element-value">
                {elem.value}
              </div>
              <div className="analytics__panel-element-type">
                {elem.type}
              </div>
            </div>
            <div className="analytics__panel-element--right">
              <div className={clsx("analytics__panel-element-percentage", {
                "analytics__panel-element-percentage--positive": elem.positive,
              })}>
                {elem.percentage}
              </div>
            </div>
          </div>
        );}
      )}
    </div>
  );
}

export default AnalyticsPanel;
