import React from 'react';
import clsx from 'clsx';
import { ReactComponent as DeleteIcon } from '../../../assets/images/delete.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/images/download.svg';
import RoundedIcon from '../../shared/RoundedIcon/RoundedIcon';

const UploadedFile = ({ file, removeHandler, removable = true, downloadable = false, downloadHandler }) => (
  <div className="uploaded-file__wrapper">
    <div className={clsx('uploaded-file__icon', {
      'uploaded-file__icon--pdf': file.type.includes('pdf'),
      'uploaded-file__icon--csv': file.type.includes('csv'),
      'uploaded-file__icon--video': file.type.includes('video'),
      'uploaded-file__icon--image': file.type.includes('image'),
      'uploaded-file__icon--audio': file.type.includes('audio'),
    })}/>
    <div className="uploaded-file__title">
      {file.name}
    </div>
    {removable && (
      <div className="uploaded-file__close">
        <RoundedIcon
          SvgComponent={DeleteIcon}
          iconColor="black"
          hover
          border
          large
          handler={removeHandler}
        />
      </div>
    )}
    {downloadable && (
      <div className="uploaded-file__close">
        <RoundedIcon
          SvgComponent={DownloadIcon}
          iconColor="black"
          hover
          border
          large
          handler={downloadHandler}
        />
      </div>
    )}
  </div>
);

export default UploadedFile;
