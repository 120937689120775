import { combineReducers } from 'redux';
import userReducer from './userReducer';
import commonReducer from './commonReducer';
import reportsReducer from './reportsReducer';
import alertsReducer from './alertsReducer';
import loadingReducer from './loadingReducer';
import adminReducer from './adminReducer';
import tagsReducer from './tagsReducer';
import messagesReducer from './messagesReducer';
import foldersReducer from './foldersReducer';
import storiesReducer from './storiesReducer';

const appReducer = combineReducers({
  user: userReducer,
  reports: reportsReducer,
  common: commonReducer,
  admin: adminReducer,
  alerts: alertsReducer,
  loading: loadingReducer,
  tags: tagsReducer,
  messages: messagesReducer,
  folders: foldersReducer,
  stories: storiesReducer,
});

export default appReducer;
