import '../../../styles/index.scss';
import { ReactComponent as Location } from '../../../assets/images/location_marker.svg';
import { ReactComponent as Block } from '../../../assets/images/block_marker.svg';
import { ReactComponent as Person } from '../../../assets/images/person_marker.svg';
function AnalyticsTopMessage({ message }) {

  return (
      <div key={message.type} className="analytics__focuses-container" style={{ backgroundColor: message.color }}>
        <div className="analytics__focuses-icon">
          {message.type === 'location' ?
            <Location />
            : message.type === 'block' ?
              <Block />
              :
              <Person />
          }
        </div>
        <div className="analytics__focuses-message">
          {message.text}
        </div>
      </div>
  );
}

export default AnalyticsTopMessage;
