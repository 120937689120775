import { connect } from 'react-redux';
import * as React from "react";
import SplitView from '../SplitView/SplitView';
import Container from '../Container/Container';
import FullImage from '../FullImage/FullImage';
import login_background from '../../../assets/images/login_background.png';

function loginHoc(WrappedComponent) {
  class LoginHoc extends React.Component {

    render() {
      return (
        <SplitView
          leftContent={
            <FullImage
              image={this.props.backgroundImage || login_background}
              quote={this.props.quote}
              author={this.props.quote_author}
            />
          }
          rightContent={
            <Container>
              <WrappedComponent {...this.props} />
            </Container>
          }
        />
      );
    }
  }

  LoginHoc.displayName = `LoginHoc(${getDisplayName(WrappedComponent)})`;

  const mapStateToProps = ({ common }) => {
    return {
      backgroundImage: common.backgroundImage,
      quote: common.quote,
      quote_author: common.quote_author,
    };
  };

  return connect(mapStateToProps, null)(LoginHoc);
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default loginHoc;
