import '../../../styles/index.scss';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import t from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import TextInput from '../../shared/TextInput/TextInput';
import Button from '../../shared/Button/Button';
import Toggle from '../../ui/Toggle/Toggle';
import mapDispatchToProps from '../../../actions/index';
import SelectBox from '../../ui/SelectBox/SelectBox';
import { isUserAdmin, findOption } from '../../../utils';

const AdminUserListContent = (props) => {
  const { t } = useTranslation();
  const { selectedUser, edit = true, onClose } = props;

  // Initial state
  const [errors, setErrors] = useState([]);
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('');
  const [userOrganizations, setUserOrganizations] = useState([]);
  const [email, setEmail] = useState('');
  const [enable2FA, setEnable2FA] = useState(false);

  // Do we show organizations or not?
  const [organizationsEnabled, setOrganizationsEnabled] = useState(Number(props?.options?.organizations_shown?.value) || false);
  useEffect(() => {
    setOrganizationsEnabled(Number(props?.options?.organizations_shown?.value));
  }, [props?.options?.organizations_shown?.value]);

  // Fill roles & orgs
  useEffect(() => {
    if(!props.roles.length) props.getRoles();
    if(!props.organizations.length) props.getOrganizations();
  }, [props.roles, props.organizations]);

  const organizationOptions = props?.organizations?.map((elem) => (
    { key: elem.id, value: elem.id, text: elem.name }
  ));

  /*
  const rolesOptions = props.roles.filter((elem) => (elem.name !== 'Administrator')).map((elem) => (
    { key: elem.id, value: elem.id, text: elem.name }
  ));
  */
  const rolesOptions = props.roles.map((elem) => (
    { key: elem.id, value: elem.id, text: elem.name }
  ));

  // Fill data from selected user
  useEffect(() => {
    if(edit && selectedUser !== null) {
      setErrors([]); // clear errors
      setUsername(selectedUser?.username);
      setFirstName(findOption('firstname', selectedUser?.options));
      setLastName(findOption('lastname', selectedUser?.options));
      setRole(selectedUser?.role?.id);
      setEmail(findOption('email', selectedUser?.options));
      setEnable2FA(findOption('secondfactor', selectedUser?.options) === 'email');
      const userOrgs = selectedUser?.organizations.map((elem) => elem.id);
      setUserOrganizations(userOrgs || [props.selectedOrganization]);
    }
  }, [selectedUser]);

  // Pre-select organization (when creating a new user)
  useEffect(() => {
    if(!edit) setUserOrganizations([props.selectedOrganization]);
  }, [props.selectedOrganization]);

  // Functions
  const getRoleName = (roleId) => {
    const role = props.roles.find(elem => elem.id === roleId);
    return role?.name;
  };

  const handleUpdateUser = async () => {
    const userData = {
      firstname: firstName || undefined,
      lastname: lastName || undefined,
      password: password || undefined,
      email: email || undefined,
      organizations: userOrganizations || [],
      role_id: role || undefined,
      secondfactor: enable2FA ? 'email' : 'none',
    };

    const result = await props.updateUser(selectedUser, userData);
    if (result.errors) setErrors(result.errors);
    if (result.success) onClose();
  };

  const handleCreateUser = async () => {
    const userData = {
      firstname: firstName || '',
      lastname: lastName || '',
      username: username || '',
      password: password || '',
      email: email || '',
      role_id: role || '',
      secondfactor: enable2FA ? 'email' : 'none',
    };

    userData.organizations = (!!organizationsEnabled && isUserAdmin(props.userLoggedIn))
      ? userOrganizations || []
      : [props.selectedOrganization];
    const result = await props.createUser(userData);

    if (result.errors) setErrors(result.errors);
    if (result.success) onClose();
  };

  return (
    <div className={clsx('user-list-content__wrapper', {
      'user-list-content__wrapper--create': !edit,
    })}
    >
      <TextInput
        title={t('firstname')}
        placeholder={t('enterFirstName')}
        value={firstName}
        hasError={!!errors.firstname}
        errorDescription={errors.firstname}
        handleChange={(event) => setFirstName(event.target.value)}
      />
      <TextInput
        title={t('lastname')}
        placeholder={t('enterLastName')}
        value={lastName}
        hasError={!!errors.lastname}
        errorDescription={errors.lastname}
        handleChange={(event) => setLastName(event.target.value)}
      />
      <TextInput
        title={t('username')}
        disabled={edit}
        placeholder={t('usernamePlaceholder')}
        value={username}
        hasError={!!errors.username}
        errorDescription={errors.username}
        handleChange={(event) => setUsername(event.target.value)}
      />
      <TextInput
        title={t('password')}
        placeholder={!edit ? t('passwordPlaceholder') : '••••••••••••••••' }
        type="password"
        hasError={!!errors.password}
        errorDescription={errors.password}
        value={password}
        handleChange={(event) => setPassword(event.target.value)}
      />
      <TextInput
        title={t('email')}
        placeholder={t('enterEmailPlaceholder')}
        type="email"
        value={email}
        hasError={!!errors.email}
        errorDescription={errors.email}
        handleChange={(event) => setEmail(event.target.value)}
      />
      <Toggle
        label={t('enable2FA')}
        handleChange={() => setEnable2FA(!enable2FA)}
        value={enable2FA}
        customLabelClass={'enable2FA'}
        vertical
      />
      {getRoleName(role) === 'Administrator' && (
        <TextInput
          title={t('role')}
          placeholder={t('enterUserRole')}
          value='Administrator'
          readonly
        />
      )}
      {getRoleName(role) !== 'Administrator' && (
        <SelectBox
          title={t('role')}
          placeholder={t('enterUserRole')}
          value={role}
          hasError={!!errors.role}
          errorDescription={errors.role}
          handleChange={(e, { value }) => setRole(value)}
          options={rolesOptions}
        />
      )}
      {(!!organizationsEnabled && getRoleName(role) !== 'Administrator' && isUserAdmin(props.userLoggedIn)) && (
        <SelectBox
          title={t('organizations')}
          multiple
          placeholder={t('userOrganizationPlaceholder')}
          value={userOrganizations}
          hasError={!!errors.organizations}
          errorDescription={errors.organizations}
          handleChange={(e, { value }) => setUserOrganizations(value)}
          options={organizationOptions}
        />
      )}
      {edit && (
        <div className="user-list-content__actions">
          <Button
            text={t('save')}
            primary
            medium
            handler={() => handleUpdateUser()}
          />
        </div>
      )}
      {!edit
      && (
        <div className="admin-modal__actions">
          <Button text={t('cancel')} secondary medium handler={onClose} />
          <Button text={t('save')} primary medium handler={() => handleCreateUser()} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ user, admin }) => ({
  userLoggedIn: user.userInfo,
  selectedOrganization: admin.selectedOrganizationId,
  selectedUser: admin.selectedUser,
  organizations: admin.organizations,
  roles: admin.roles,
  options: admin.options,
});

AdminUserListContent.propTypes = {
  userInfo: t.object,
  edit: t.bool,
  selectedOrganization: t.string.isRequired,
  onClose: t.func.isRequired,
  updateUser: t.func.isRequired,
  createUser: t.func.isRequired,
  userLoggedIn: t.object.isRequired,
  organizations: t.array.isRequired,
  roles: t.array,
};

AdminUserListContent.defaultProps = {
  edit: true,
  roles: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserListContent);
