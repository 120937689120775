import '../../../../styles/index.scss';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../shared/TextInput/TextInput';
import mapDispatchToProps from '../../../../actions/index';
import Button from '../../../shared/Button/Button';

function AdminTagCreateForm({
  onClose, languages, createTag, groupId, groupType
}) {

  const getDefaultTranslationState = () => (
    languages.map((elem) => ({
      value: '',
      name: elem.name,
      locale: elem.locale,
      key: `name_${elem.locale}`,
    }))
  );

  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [tagTranslation, setTagTranslation] = useState(getDefaultTranslationState());

  const handleTagTranslation = (value, index) => {
    const updatedTranslation = [...tagTranslation];
    updatedTranslation[index].value = value;
    setTagTranslation(updatedTranslation);
  };

  useEffect(() => {
    setTagTranslation(getDefaultTranslationState());
  }, [languages]);

  const getFilledTranslations = () => {
    const translationsHash = {};

    tagTranslation.map(elem => {
      translationsHash[elem.locale] = elem.value;
    });

    return translationsHash;
  };

  const handleCreateTag = async () => {
    const tagData = {
      name: getFilledTranslations(),
      type: groupType
    };

    // Do action
    const result = await createTag(groupId, tagData, groupType);
    if (result.success) {
      setErrors({});
      onClose();
    } else {
      setErrors(result.errors);
    }
  };

  return (
    <div className="admin_tagsgroups-form__wrapper">
      {tagTranslation.map((elem, index) => (
        <TextInput
          title={`${t('name')} - ${elem.name}`}
          placeholder={`${t('enterNameIn')} - ${elem.name}`}
          value={elem.value}
          key={`newtagelement-${elem.locale}`}
          hasError={!!errors[elem.locale]}
          errorDescription={errors[elem.locale]}
          handleChange={(event) => handleTagTranslation(event.target.value, index)}
        />
      ))}
      <div className="admin_tagsgroups-form__buttons">
        <Button
          text={t('cancel')}
          secondary
          medium
          handler={onClose}
        />
        <Button
          text={t('save')}
          primary
          medium
          handler={handleCreateTag}
        />
      </div>
    </div>
  );
}
const mapStateToProps = ({ common }) => ({
  languages: common.languages,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminTagCreateForm);
