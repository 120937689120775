import '../../../styles/index.scss';

function AnalyticsTable({ header, elements, typeId }) {

  return (
    <div className="analytics__table">
      <div className="analytics__table-title">
        {header}
      </div>
      <div className="analytics__table-header">
        {elements.headers.map((elem, index) => (
          <div className="analytics__table-header-element" key={`table-${typeId}-header-${index}`}>
            {elem}
          </div>
        ))}
      </div>
      {elements.data.map((elem, index) => (
        <div className="analytics__table-element" key={`table-${typeId}-element-${index}`}>
          {Object.keys(elem).map((obj, textIndex) => (
            <div className="analytics__table-element-text"  key={`table-${typeId}-element-${index}-text-${textIndex}`}>
              {elem[obj]}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default AnalyticsTable;
