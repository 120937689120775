import { connect } from 'react-redux'
import * as React from "react";
import mapDispatchToProps from '../../../actions/index';
import NavigationBar from '../NavigationBar/NavigationBar';

function dashboardHoc(WrappedComponent) {
  class DashboardHoc extends React.Component {

    render() {
      // @fixme: what is this for?
      if (this.props.location.from && this.props.selectedReport &&  
        this.props.history.location.from.startsWith('/report') && 
        !this.props.history.location.pathname.startsWith('/report')
      ){
        this.props.unselectedReport();
      }
      return (
        <div className="dashboard-container__wrapper">
          <div className="dashboard-container__navigation">
            <NavigationBar
              userInfo={this.props.userInfo}
              languages={this.props.languages}
              defaultLanguage={this.props.defaultLanguage}
            />
          </div>
          <WrappedComponent {...this.props} />
        </div>
      );
    }
  }

  DashboardHoc.displayName = `DashboardHoc(${getDisplayName(WrappedComponent)})`;

  const mapStateToProps = ({ user, common, reports }) => {
    return {
      userInfo: user.userInfo,
      languages: common.languages,
      defaultLanguage: common.defaultLanguage,
      selectedReport: reports.selectedReport,
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(DashboardHoc);
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default dashboardHoc;
