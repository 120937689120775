import '../../../styles/index.scss';
import React, { useState, useEffect } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import ReactMapboxGl, { Layer, GeoJSONLayer, ZoomControl } from 'react-mapbox-gl';
import { useTranslation } from 'react-i18next';
import dashboardHoc from '../DashboardHoc/DashboardHoc';
import FullHeader from '../../ui/FullHeader/FullHeader';
import SelectBox from '../../ui/SelectBox/SelectBox';
import AnalyticsTopMessage from './AnalyticsTopMessage';
import AnalyticsReportsInfo from './AnalyticsReportinfo';
import AnalyticsPanel from './AnalyticsPanel';
import AnalyticsTable from './AnalyticsTable';
import earthquakes from './earthquakes.geojson';
import { MAPBOX_SECRET_KEY } from '../../../shared/consts';

const Map = ReactMapboxGl({
  attributionControl: false,
  // interactive: false,
  accessToken:
    MAPBOX_SECRET_KEY,
});

function AnalyticsContainer() {
  const { t } = useTranslation();
  const [dataPeriod, setDataPeriod] = useState('Last 7 days');

  useEffect(() => {
    document.title = 'Timby - Analytics';
  }, []);

  const messages = [
    { type: 'location', color: '#F5B27B50', text: 'The biera area has recently dropped in reporting' },
    { type: 'block', color: '#DCCEBF50', text: 'There were no reports added on the 23rd of January' },
    { type: 'person', color: '#DBCD7550', text: 'Thomas Parkes is on fire! He’s submitted 20 reports this week' },
  ];

  const topReportByCat = [
    { type: 'Farming', value: '125', positive: true, percentage: `+4.6% ${t('analyticsFromLastWeek')}`, },
    { type: 'Forest', value: '48', positive: false, percentage: `-2.6% ${t('analyticsFromLastWeek')}`, },
    { type: 'Water access', value: '24', positive: true, percentage: `+2.6% ${t('analyticsFromLastWeek')}`, },
  ];

  const dataPeriods = [
    'Last 7 days',
    'Last month',
    'Last 3 months',
  ];

  const thingsDone = [
    {
      sector: t('analyticsRepSubmited'), value: '52', positive: true, percentage: `+4.6% ${t('analyticsFromLastWeek')}`,
    },
    {
      sector: t('analyticsRepVerified'), value: '129', positive: false, percentage: `-1.6% ${t('analyticsFromLastWeek')}`,
    },
    {
      sector: t('analyticsIssuesResp'), value: '14', positive: false, percentage: `-2.6% ${t('analyticsFromLastWeek')}`,
    },
    {
      sector: t('analyticsStoriesCreated'), value: '9', positive: false, percentage: `-4.6% ${t('analyticsFromLastWeek')}`,
    },
  ];

  const popularKeywords = {
    headers: [t('analyticsKeyword'), t('analyticsMentions')],
    data: [
      { keyword: 'Suspicius', mentions: '24' },
      { keyword: 'Access', mentions: '19' },
      { keyword: 'Forestry', mentions: '7' },
      { keyword: 'Farming', mentions: '4' },
    ],
  };

  const topUsers = {
    headers: [t('analyticsUsers'), t('analyticsSubmissions'), t('analyticsActivity')],
    data: [
      { users: 'Demo User', submissions: 24, activity: 24 },
      { users: 'Isaac Wambugu', submissions: '19', activity: 19 },
      { users: 'James Wahome', submissions: '7', activity: 7 },
      { users: 'Jordan Imahori', submissions: '7', activity: 7 },
      { users: 'Rita Resende', submissions: '7', activity: 7 },
    ],
  };

  const submissionsData = {
    labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    datasets: [{
      label: '# of Reports',
      data: [40, 57, 43, 60, 78, 83, 40],
      backgroundColor: '#1B8760',
    }],
  };

  const pieData = {
    labels: ['Farming', 'Dashboard Users'],
    datasets: [{
      label: '% of User Types',
      data: [70, 30],
      backgroundColor: ['#1B8760', '#CCE1DA'],
    }],
  };

  const clusterPaint = {
    'circle-color': ['step', ['get', 'point_count'], '#1B8760', 100, '#1B8760', 750, '#1B8760'],
    'circle-opacity': ['step', ['get', 'point_count'], 0.7, 100, 0.7, 750, 0.7],
    'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
  };

  return (
    <div className="analytics__wrapper">
      <FullHeader title={t('analytics')} />
      <div className="analytics__content">
        <div className="analytics__content--scroll whitepagescrollbar">
          <div className="analytics__header">
            <div>{t('analyticsTitle')}</div>
            <SelectBox
              small
              value={dataPeriod}
              options={dataPeriods}
              handleChange={(e) => setDataPeriod(e.target.value)}
            />
          </div>
          <div className="analytics__focuses">
            {messages.map((elem, index) => <AnalyticsTopMessage message={elem} key={`analytics-message-${index}`} />)}
          </div>
          <div className="analytics__submissions">
            <div>
              <div className="analytics__map-header">
                {t('analyticsSubmissions')}
              </div>
              <div className="analytics__bar-chart">
                <Bar data={submissionsData} />
              </div>
            </div>
            <AnalyticsPanel header={t('analyticsReportByCat')} elements={topReportByCat} />
          </div>
          <div className="analytics__reports">
            {thingsDone.map((elem, index) => <AnalyticsReportsInfo info={elem} key={`analytics-reportinfo-${index}`} />)}
          </div>
          <div className="analytics__maps">
            <div className="analytics__map-container">
              <div className="analytics__map-header">
                {t('analyticsGeographicPrio')}
              </div>
              <Map
                style="mapbox://styles/mapbox/basic-v9"
                containerStyle={{ height: '320px', width: '100%' }}
                zoom={[1]}
                renderChildrenInPortal
                onStyleLoad={(map) => map?.resize()}
              >
                <GeoJSONLayer
                  sourceOptions={{ cluster: true }}
                  data={earthquakes}
                  id="source_id"
                  layerOptions={{ filter: ['has', 'point_count'] }}
                  circlePaint={clusterPaint}
                />
                <Layer
                  id="cluster_count"
                  type="symbol"
                  sourceId="source_id"
                  filter={['has', 'point_count']}
                  layout={{
                    'text-field': '{point_count}',
                    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                    'text-size': 12,
                  }}
                />
                <Layer
                  id="unclustered_layer"
                  sourceId="source_id"
                  layerOptions={{ filter: ['!has', 'point_count'] }}
                  type="symbol"
                  layout={{
                    'icon-image': 'sensor',
                    'icon-size': 1,
                  }}
                />
                <ZoomControl className="reports-map__zoomControl" position="bottom-right" />
              </Map>
            </div>
            <AnalyticsTable typeId="analytics-keywords" header={t('analyticsPopularKeywords')} elements={popularKeywords} />
          </div>
          <div className="analytics__maps">
            <AnalyticsTable typeId="analytics-topusers" header={t('analyticsTopUsers')} elements={topUsers} />
            <div className="analytics__pie-container">
              <div className="analytics__map-header">
                {t('analyticsUsertypes')}
              </div>
              <div className="analytics__pie-container--chart">
                <Pie data={pieData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default dashboardHoc(AnalyticsContainer);
