import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import t from 'prop-types';
import '../../../styles/index.scss';
import mapDispatchToProps from '../../../actions/index';
import dashboardHoc from '../DashboardHoc/DashboardHoc';
import FullHeader from '../../ui/FullHeader/FullHeader';
import MessageElement from '../MessageElement/MessageElement';
import MessageForm from '../MessageForm/MessageForm';
import OrganizationsMenu from '../OrganizationsMenu/OrganizationsMenu';

function MessagesContainer(props) {
  const { t } = useTranslation();
  const [organizationsEnabled, setOrganizationsEnabled] = useState(Number(props?.options?.organizations_shown?.value) || false);

  useEffect(() => {
    setOrganizationsEnabled(Number(props?.options?.organizations_shown?.value));
  }, [props?.options?.organizations_shown?.value]);

  useEffect(() => {
    const getMessages = async () => {
      await props.getMessages();
    };
    if (props.messages.length === 0) getMessages();

    document.title = 'Timby - Broadcasts';
  }, []);

  useEffect(() => {
    const onChangeOrganization = async () => {
      await props.getMessages();
      await props.getUsers();
    };
    onChangeOrganization();
  }, [props.selectedOrganizationId]);

  return (
    <div className="messages-container">
      <FullHeader title={t('contactUsers')}>
        {organizationsEnabled && (
          <OrganizationsMenu />
        )}
      </FullHeader>
      <div className="messages-container__wrapper whitepagescrollbar">
        <MessageForm />
        <div className="messages-container__wrapper__recents">
          <p className="messages-container__title">{t('contactRecent')}</p>
          <div className="messages-container__wrapper__recents-box defaultscrollbar">
            {props.messages.map((elem) => (
              <MessageElement
                id={`message-${elem.id}`}
                text={elem.message}
                key={`message-${elem.id}`}
                title={elem.subject}
                date={elem.created_at}
                //recipients={elem.recipient}
                author={elem.author}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = ({ messages, user, admin }) => ({
  messages: messages.messages,
  userInfo: user.userInfo,
  selectedOrganizationId: admin.selectedOrganizationId,
  options: admin.options,
});

MessagesContainer.propTypes = {
  messages: t.array,
  getMessages: t.func.isRequired,
  selectedOrganization: t.string.isRequired,
  userInfo: t.object.isRequired,
  options: t.object.isRequired,
};

MessagesContainer.defaultProps = {
  messages: [],
};

export default dashboardHoc(connect(mapStateToProps, mapDispatchToProps)(MessagesContainer));
