import '../../../styles/index.scss';
import React, { useState } from 'react';
import { Search } from 'semantic-ui-react';

function SearchBar({ label, handler, results=[], showResults=false}) {
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState('');

  const handleChange = (newValue) => {
    if (newValue.length > 250) {
      return;
    };

    setValue(newValue);
    handler && handler(newValue)
  };

  return (
    <Search
      className="search-bar__wrapper"
      input={{ icon: 'search', iconPosition: 'left' }}
      loading={isLoading}
      results={results}
      showNoResults={showResults}
      onSearchChange={(e) => { handleChange(e.target.value)}}
      value={value}
      placeholder={label}
    />
  );
}

export default SearchBar;
