import clsx from 'clsx'
import React from 'react'
import { Modal } from 'semantic-ui-react'


function ImageModal({ children, open, onClose, onOpen, customModal, isImageOnly=false }) {

  return (
    <Modal
      className={clsx(`image-modal__wrapper ${customModal}`, {
        'image-modal__wrapper-image__only': isImageOnly,
      })}
      closeIcon
      onClose={onClose}
      onOpen={onOpen}
      open={open}
    >
      <Modal.Content>
        {children}
      </Modal.Content>
    </Modal>
  );
}

export default ImageModal
